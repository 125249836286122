import { useNavigate } from "react-router-dom";
import {
  API_LOGIN_ROUTE,
  API_LOGOUT_ROUTE,
  API_REGISTRATION_ROUTE,
  API_USER_DATA_ROUTE,
  API_USER_FINDME_ROUTE,
} from "../../utils/consts";
import {
  removeLoading,
  setError,
  setErrors,
  setLoading,
  setMessage,
} from "../rootReducer";
import {
  USER,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  USER_UPDATE,
  USER_AVATAR_DELETE,
} from "../types";
import { headers } from "../config";
import axios from "axios";

const token = sessionStorage.getItem("access_token");

export const userLogin = (data) => {
  return {
    type: AUTH_LOGIN,
    payload: { data },
  };
};
export const userUpdateProfile = (data) => {
  return {
    type: USER_UPDATE,
    payload: { data },
  };
};

export const userDelete = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("core_api_token");
  return {
    type: AUTH_LOGOUT,
  };
};

export const userAvatarDelete = () => {
  return {
    type: USER_AVATAR_DELETE,
  };
};

const userLogout = () => {
  return {
    type: AUTH_LOGOUT,
  };
};

const oneUser = (data) => {
  return {
    type: USER,
    payload: { data },
  };
};

export const getUser = () => async (dispatch) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_USER_FINDME_ROUTE,
      {
        method: "GET",
        headers: headers,
      }
    );

    const data = await response.json();
    dispatch(oneUser(data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const loginUser = (user) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(user),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_LOGIN_ROUTE,
      options
    );
    const data = await response.json();

    if (data.access_token) {
      dispatch(userLogin(data));
      localStorage.setItem("access_token", data.access_token);
    } else {
      dispatch(setError(data.message));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch(setLoading("logout"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_LOGOUT_ROUTE,
      options
    );
    const data = await response.json();

    if (data) {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(userLogout());
      dispatch(removeLoading("logout"));
    } else {
      dispatch(setMessage(data.message, "error"));
      dispatch(removeLoading("logout"));
    }
  } catch (error) {
    setMessage(error, "error");
    dispatch(removeLoading("logout"));
  }
};

export const addNewUser = (item) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(item),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_REGISTRATION_ROUTE,
      options
    );
    const data = await response.json();

    if (data.access_token) {
      dispatch(userLogin(data));
      window.sessionStorage.setItem("access_token", data.access_token);
      useNavigate("/");
      window.location.href = "/";
    } else {
      dispatch(setErrors(data.errors));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const handleUserData = async (dispatch) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_USER_DATA_ROUTE,
      {},
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );

    if (res.data.data.access_token) {
      dispatch(userUpdateProfile(res.data.data.user));
      localStorage.setItem("access_token", res.data.data.access_token);
      localStorage.setItem("session_id", res.data.data.session_id);
    } else {
      dispatch(setMessage(res.data.message ?? res.data.errors[0].text));
    }
  } catch (error) {
    console.error(error);
  }
};
