export const palette = {
  primary: {
    light: "#DA0011",
    main: "#DA0011",
    dark: "#DA0011",
    contrastText: "#FFF",
  },
  secondary: {
    light: "#1E0640",
    main: "#1E0640",
    dark: "#0d011d",
    contrastText: "#FFF",
  },
  third: {
    light: "#FFF",
    main: "#FFF",
    dark: "#000",
    contrastText: "#FFF",
  },
  fourth: {
    light: "#2DFD95",
    main: "#2DFD95",
    dark: "#2DFD95",
    contrastText: "black",
  },
  error: {
    light: "#d32f2f",
    main: "#d32f2f",
    dark: "#d32f2f",
    contrastText: "#fff",
  },
  warning: {
    light: "#ed6c02",
    main: "#ed6c02",
    dark: "#ed6c02",
    contrastText: "#fff",
  },
  info: {
    light: "#0288d1",
    main: "#0288d1",
    dark: "#0288d1",
    contrastText: "#fff",
  },
  success: {
    light: "#2e7d32",
    main: "#2e7d32",
    dark: "#2e7d32",
    contrastText: "#fff",
  },
  grey: {
    light: "#F5F5F5",
    main: "#F5F5F5",
    dark: "#F5F5F5",
    contrastText: "#000000",
  },
  white: {
    light: "#FFF",
    main: "#FFF",
    dark: "#FFF",
    contrastText: "#000000",
  },
  text: {
    light: "#000",
    main: "#000",
    dark: "#e7e7e7",
    contrastText: "#000",
  },
  label: {
    light: "#A7A7A7",
    main: "#A7A7A7",
    dark: "#A7A7A7",
    contrastText: "#000",
  },
  divider: {
    light: "#EDEDED",
    main: "#EDEDED",
    dark: "#202020",
    contrastText: "#EDEDED",
  },
  action: {
    active: "#000000",
    hover: "#d5d5d5",
    hoverOpacity: 0.1,
    disabled: "#000000",
    disabledBackground: "#000000",
  },
  paper: {
    light: "#FFF",
    main: "#000",
    dark: "#000",
  },
  contrastThreshold: 2,
};
