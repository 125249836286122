export const API = "/api/v1/";
export const FRONTEND_URL = process.env.REACT_APP_APP_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

//frontend

export const HOME_ROUTE = "/";
export const PROFILE_ROUTE = "/account";

// core api
export const CORE_API_LOGIN_ROUTE = "/user/auth/login";
export const CORE_API_CHAT_ROUTE = "/chat/";
export const CORE_API_GET_MESSAGES_ROUTE = "/messages";
export const CORE_API_SEND_MESSAGE_ROUTE = "send-message";
export const CORE_API_ANONYMOUS_WHX_MAIN_USER_LOGIN = "kewbMainUser";
export const CORE_API_ANONYMOUS_WHX_MAIN_USER_PASSWORD = "pass";

//api
export const API_CHAT_TOKEN_REFRESH_ROUTE = API + "environment/chat-token";
export const API_REGISTRATION_ROUTE = API + "auth/register";
export const API_LOGIN_ROUTE = API + "auth/login";
export const API_LOGIN_ATTEMPT_ROUTE = API + "auth/login-attempt";
export const API_LOGOUT_ROUTE = API + "auth/logout";
export const API_EMAIL_VERIFICATION_ROUTE = API + "email-verification";
export const API_GET_PAYMENT_PACKAGES_ROUTE = API + "purchase/packages";

export const API_USER_PROFILE_ROUTE = API + "profile";
export const API_USER_DELETE_AVATAR_ROUTE = API + "profile/avatar/delete";

export const API_USER_FINDME_ROUTE = API + "findMe";

export const API_FETCH_ENVIRONMENT_ROUTE = API + "environment";

export const API_FETCH_USER = API + "user/";
export const API_USER_UPDATE = API + "user/";

export const API_FETCH_POSTS = API + "posts";
export const API_POST = API + "post";
export const API_FETCH_POST = API + "post/";

export const API_FETCH_COMMENT = API + "comment/";
export const API_COMMENT_UPDATE = API + "comment/";
export const API_COMMENT_DELETE = API + "comment/";

export const API_FETCH_TAGS = API + "tags";
export const API_TAG_DELETE = API + "tag/";
export const API_FETCH_TAG_POSTS = API + "tag/";

export const API_CREATE_STREAM_SESSION = API + "stream-session";

export const API_GET_SESSION_PARTICIPANTS = API + "stream/";
export const API_PARTICIPANT = API + "stream-session/";
export const API_STREAM_SESSION = API + "stream-session";
export const API_STREAM = API + "stream/";
export const API_USER_DATA_ROUTE = API + "user-info";

export const API_CHECK_STREAM_EXISTS_ROTUE = API + "stream/";

export const timeoutTime = 700;
