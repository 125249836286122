import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, useTheme, Box, SvgIcon } from '@mui/material';
import { styled } from '@mui/system';
import { withTheme } from '@mui/styles';

const StyledFormControl = styled(withTheme(FormControl))(props => ({
    backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey.light : props.theme.palette.grey.dark,
    color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark,
    borderRadius: '10px',
    width: '100%',
    'input': {
        color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark
    },
    'textarea': {
        color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark
    }
}));

const StyledSelect = styled(withTheme(Select))(props => ({
    backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey.light : props.theme.palette.grey.dark,
    color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark,
    borderRadius: '10px',
    border: 0,
    width: '100%',
    'input': {
        color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark
    },
    'textarea': {
        color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark
    },
    'fieldset': {
        border: 0
    }
}));

const StyledMenuItem = styled(withTheme(MenuItem))(props => ({
    color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark,
    border: 0,
    width: '100%'
}));

export default function SelectUI({ title, onChange, value, icon, items, sx, style, multiple }) {
    const theme = useTheme();
    if (!items) {
        return <></>;
    }
    if (items === [] && multiple) {
        return <></>;
    }

    let randomId = Math.random(1, 1000 * 1000);
    return (
        <StyledFormControl className="SelectUI" fullWidth sx={sx} style={style}>
            <InputLabel id="demo-simple-select-label">{title}</InputLabel>
            <StyledSelect
                labelId={"select-label-" + randomId}
                id={"select-" + randomId}
                label={title}
                onChange={onChange}
                value={value}
                multiple={multiple}
                displayEmpty
                className="select"
                renderValue={(value) => {
                    return (
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <SvgIcon color="primary">
                                {icon}
                            </SvgIcon>
                            {value}
                        </Box>
                    );
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                padding: "10px 20px",
                                borderBottom: '5px solid' + (theme.palette.mode === 'light' ? theme.palette.third.light : theme.palette.third.dark)
                            },
                            '& .MuiButtonBase-root:hover': {
                                bgcolor: theme.palette.action.hover,
                            }
                        },
                    },
                }}
            >
                {items.map((item) => {
                    return <StyledMenuItem value={item.value}>{item.title}</StyledMenuItem>
                })}
            </StyledSelect>
        </StyledFormControl>
    )
}
