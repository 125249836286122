import { ERRORS, ERRORS_REMOVE } from '../types'

const initialState = [];
export const errorsReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {

    case ERRORS: {
        const { data } = payload
        return data
    }
    case ERRORS_REMOVE: {
      return initialState;
    }
    default: {
      return state
    }
  }
}
