import { Box, Container, Slide, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

import { ReactComponent as MaintanceIcon } from './maintance.svg';
import { useTranslation } from 'react-i18next';
export default function Maintance() {
    const { t } = useTranslation();
    const maintance = useSelector(state => state.maintance);

    if(!maintance){
        return;
    }
    
    return (
        <Slide in={maintance} direction='up'>
            <Box className="maintance">
                <Container maxWidth="xl">
                    <Box className="flex items-center">
                        <MaintanceIcon />
                        <Box className="flex column" sx={{marginLeft: '15px'}}>
                            <Typography variant="h4">{t("Something’s up…")}</Typography>
                            <Typography>{t("And we are working on it already. So don’t run away bro!")}</Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Slide>
    )
}
