import { Box, Typography } from "@mui/material";
import React from "react";
import Header from "../../components/Header/Header";
import { useSelector } from "react-redux";

function Payment() {
  const stream_session = useSelector((state) => state.stream_session);

  React.useEffect(() => {
    const dots = document.getElementById("dots");
    let count = 0;

    setInterval(() => {
      switch (count % 4) {
        case 0:
          dots.textContent = "";
          break;
        case 1:
          dots.textContent = ".";
          break;
        case 2:
          dots.textContent = "..";
          break;
        case 3:
          dots.textContent = "...";
          break;
      }
      count++;
    }, 300);
  }, []);
  return (
    <>
      {stream_session?.current_participant ? (
        stream_session?.current_participant?.is_host ? (
          <Header />
        ) : (
          <></>
        )
      ) : (
        <Header />
      )}
      <Box className="flex center" sx={{height: '92vh'}}>
        <Typography variant="h2" className="typography" sx={{ color: "#fff" }}>
          Payment screen in progress<span id="dots"></span>
        </Typography>
      </Box>
    </>
  );
}

export default React.memo(Payment);
