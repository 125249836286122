import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  CircularProgress,
} from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import Maintance from "./components/Maintance/Maintance";
import SnackbarUI from "./components/UI/SnackbarUI";
import Router from "./router/Router";
import { SnackbarProvider } from "notistack";
import { ColorContext } from "./themes/ColorContext";
import { darkTheme } from "./themes/dark";
import { lightTheme } from "./themes/light";
import Modals from "./components/Modals/Modals";
import { useSelector } from "react-redux";
import { ContextProvider } from "./contexts/Socket";
import AxiosNavigation from "./utils/AxiosNavigation";
import SnackMessage from "./components/UI/AntSnackBar";
function App() {
  const [mode, setMode] = React.useState("light");
  const loading = useSelector((state) => state.loading);
  const token = localStorage.getItem("access_token");
  const [accessToken, setAccessToken] = React.useState(
    localStorage.getItem("access_token")
  );

  React.useEffect(() => {
    let token = localStorage.getItem("access_token");
    setAccessToken(token);
  }, [token]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );
  const theme = React.useMemo(
    () => createTheme(mode === "light" ? lightTheme : darkTheme),
    [mode]
  );

  return (
    <ColorContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {loading?.name === "logout" ? (
          <CircularProgress color="primary" />
        ) : (
          <BrowserRouter>
            <AxiosNavigation />
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              maxSnack={3}
              content={(key, notificationData) => (
                <SnackMessage id={key} notificationData={notificationData} />
              )}
            >
              <ContextProvider>
                <Router accessToken={accessToken} />
              </ContextProvider>
            </SnackbarProvider>
          </BrowserRouter>
        )}
        <Modals />
        <SnackbarUI />
        <Maintance />
      </ThemeProvider>
    </ColorContext.Provider>
  );
}

export default App;
