import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Logo from "../../assets/images/Logo.svg";
import LogoDark from "../../assets/images/Logo-dark.svg";
import { useDispatch, useSelector } from "react-redux";
import { SwitchModeButton } from "../../components/UI/SwitchModeButton";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { ReactComponent as ShareIcon } from "../../assets/images/icons/svg/share2.svg";

import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import twitch from "../../assets/images/icons/svg/twitch.svg";
import facebook from "../../assets/images/icons/svg/facebook.svg";
import instagram from "../../assets/images/icons/svg/instagram.svg";
import youtube from "../../assets/images/icons/svg/youtube.svg";
import Channels from "../Channels/Channels";
import StreamSettings from "../StreamSettings/StreamSettings";
import ShareLink from "../ShareLink/ShareLink";
import {
  SessionDelete,
  removeAwaiters,
} from "../../redux/actions/sessionActions";
import { isMobile } from "react-device-detect";
import MobileView from "./MobileView";

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElSession, setAnchorElSession] = React.useState(null);
  const [anchorElShare, setAnchorElShare] = React.useState(null);
  const session = useSelector((state) => state.session);
  const navigate = useNavigate();
  const [openChannels, setOpenChannels] = React.useState(false);

  const { t } = useTranslation();

  const settings = React.useMemo(
    () => [
      {
        icon: <ManageAccountsOutlinedIcon color="primary" />,
        name: t("Account Settings"),
        link: "/settings",
      },
      {
        icon: <ContactSupportOutlinedIcon color="primary" />,
        name: t("Support"),
        link: "/settings/support",
      },
      {
        icon: <LogoutOutlinedIcon color="primary" />,
        name: t("Logout"),
        link: "/logout",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleChannels = () => {
    setOpenChannels(true);
  };
  const handleStartSession = (event) => {
    setAnchorElSession(event.currentTarget);
  };
  const handleCloseStartSession = () => {
    setAnchorElSession(null);
  };
  const handleStopSession = (event) => {
    dispatch(removeAwaiters());
    dispatch(SessionDelete());
    window.location.reload();
  };
  const handleShareLinkOpen = (event) => {
    setAnchorElShare(event.currentTarget);
  };
  const handleShareLinkClose = () => {
    setAnchorElShare(null);
  };

  const stream_session = useSelector((state) => state.stream_session);

  if (isMobile) {
    return <MobileView />;
  }
  if (session.user)
    return (
      <AppBar
        position="static"
        enableColorOnDark
        sx={{
          backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
        }}
      >
        <Container
          maxWidth="false"
          style={{ paddingLeft: "52px", paddingRight: "34px" }}
        >
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Box className="flex center">
              {/* Desktop */}
              <Link to="/">
                {/* LOGO */}
                <Avatar
                  sx={{
                    mr: 1,
                    display: { xs: "none", md: "flex" },
                    width: "5.6vw",
                    height: "1.5vw",
                  }}
                  variant="square"
                  src={theme.palette.mode === "light" ? LogoDark : Logo}
                />
              </Link>

              {/* Mobile */}
              {/* Burger Menu */}
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {settings.map((page) => (
                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                      <Link to={page.link}>
                        <Button
                          key={page.name}
                          onClick={handleCloseNavMenu}
                          sx={{
                            my: 2,
                            mx: 1,
                            fontSize: "13px",
                            color: "white",
                            display: "block",
                            fontWeight: "bold",
                          }}
                        >
                          {page.name}
                        </Button>
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box sx={{ flexGrow: 0, marginLeft: "85px" }}>
                <Box
                  className="flex items-center"
                  id="channels"
                  sx={{ gap: "17px" }}
                >
                  {/* <Typography
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.light
                        : theme.palette.text.dark,
                  }}
                  className="typography bold"
                >
                  {t("Channels")}
                </Typography> */}

                  {/* <AvatarGroup max={4}>
                  <Avatar
                    className="borderless"
                    alt="Cindy Baker"
                    src={twitch}
                  />
                  <Avatar
                    className="borderless"
                    alt="Cindy Baker"
                    src={youtube}
                  />
                  <Avatar
                    className="borderless"
                    alt="Cindy Baker"
                    src={facebook}
                  />
                  <Avatar
                    className="borderless"
                    alt="Cindy Baker"
                    src={instagram}
                  />
                </AvatarGroup> */}

                  {/* <Box
                  className="flex center"
                  sx={{ width: "40px", height: "40px", marginLeft: "-6px" }}
                >
                  <IconButton
                    onClick={handleChannels}
                    color="primary"
                    sx={{
                      width: "fit-content",
                      height: "fit-content",
                      padding: 0,
                    }}
                  >
                    <Circle
                      sx={{ width: "23px", height: "23px" }}
                      color="primary"
                    />
                  </IconButton>
                </Box> */}

                  {/* <Channels open={openChannels} setOpen={setOpenChannels} /> */}
                  {!stream_session?.current_participant?.is_host && (
                    <Button
                      onClick={
                        stream_session?.current_participant?.is_host
                          ? handleStopSession
                          : handleStartSession
                      }
                      color="primary"
                      variant="contained"
                      className="btn short"
                      sx={{ ml: 2 }}
                    >
                      {stream_session?.current_participant?.is_host
                        ? t("Stop Session")
                        : t("Start Session")}
                    </Button>
                  )}

                  <Menu
                    sx={{ mt: "65px" }}
                    id="menu-appbar"
                    anchorEl={anchorElSession}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    className="menu"
                    open={Boolean(anchorElSession)}
                    onClose={handleCloseStartSession}
                  >
                    <StreamSettings altOnClick={handleCloseStartSession} />
                  </Menu>

                  {stream_session?.current_participant?.is_host && (
                    <>
                      <Button
                        variant="text"
                        disableElevation
                        disableRipple
                        onClick={handleShareLinkOpen}
                        startIcon={
                          <ShareIcon
                            style={{ width: "1.2vw", height: "1.2vw" }}
                          />
                        }
                        className="btn no-hover"
                      >
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === "light"
                                ? theme.palette.text.light
                                : theme.palette.text.dark,
                          }}
                          className="typography bold capitalize"
                        >
                          {t("Share Stream Link")}
                        </Typography>
                      </Button>
                      <Menu
                        sx={{ mt: "65px" }}
                        id="menu-appbar"
                        anchorEl={anchorElShare}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        className="menu"
                        open={Boolean(anchorElShare)}
                        onClose={handleShareLinkClose}
                      >
                        <ShareLink altOnClick={handleShareLinkClose} />
                      </Menu>
                    </>
                  )}
                </Box>
              </Box>
              {/* Actions */}
            </Box>

            <Box className="flex center" sx={{ gap: "20px" }}>
              {/* PRO */}
              <Button
                onClick={() => {
                  navigate("/settings/membership");
                }}
                className="btn btn-third long"
                sx={{ width: "14vw" }}
              >
                {t("Upgrade to PRO for $12mo")}
              </Button>

              {/* User */}
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip disableHoverListener>
                  <Stack direction="row" spacing={2}>
                    <Box
                      className="flex column content-center"
                      sx={{ position: "relative" }}
                    >
                      <Typography
                        color="primary"
                        className="typography bold"
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "135px",
                          height: "1.2em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {session?.user?.username ?? session?.user?.email}
                      </Typography>
                      <Typography
                        className="typography uppercase badge"
                        sx={{ fontSize: "0.575vw !important" }}
                      >
                        {session?.user?.package ?? "Basic"}
                      </Typography>
                    </Box>
                    <Avatar
                      className="cursor"
                      sx={{ width: "2.1vw", height: "2.1vw" }}
                      onClick={handleOpenUserMenu}
                      alt={session?.user?.username + " avatar"}
                      src={session?.user?.avatar?.imageSmall}
                    />
                  </Stack>
                </Tooltip>
                <Menu
                  sx={{
                    mt: "65px",
                    "& .MuiMenuItem-root": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#F8F8F8" : "#121212",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? "#d5d5d5"
                            : "#2b2b2b",
                      },
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className="menu"
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting, i) => (
                    <div key={"s" + i}>
                      <Link to={setting.link}>
                        <MenuItem
                          onClick={handleCloseUserMenu}
                          className="typography bold capitalize"
                          sx={{
                            color:
                              theme.palette.mode === "light"
                                ? theme.palette.text.light
                                : theme.palette.text.dark,
                            minWidth: "231px",
                            padding: "17px 20px",
                          }}
                        >
                          {setting.icon}
                          &emsp;{setting.name}
                        </MenuItem>
                      </Link>
                      {settings.length !== i + 1 && (
                        <Divider
                          sx={{
                            color:
                              theme.palette.mode === "light"
                                ? theme.palette.divider.dark
                                : theme.palette.divider.light,
                          }}
                        />
                      )}
                    </div>
                  ))}
                  <MenuItem
                    sx={{
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? "#dddddd !important"
                          : "#1c1c1c !important",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? "#cccccc !important"
                            : "#4b4b4b !important",
                      },
                    }}
                  >
                    <SwitchModeButton />
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
};
export default Header;
