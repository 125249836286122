import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/system';
import { withTheme } from '@mui/styles';

const StyledFormControlLabel = styled(withTheme(FormControlLabel))(props => ({
    color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark,
    padding: '0 10px',
    display: 'flex',
    gap: '8px',
    ".MuiCheckbox-root:not(.Mui-checked):hover": {
        background: props.theme.palette.mode === 'light' ? props.theme.palette.grey.light : props.theme.palette.grey.dark,
    },
    ".Mui-checked":{ 
        background: props.theme.palette.mode === 'light' ? props.theme.palette.primary.light : props.theme.palette.primary.dark,
    }
}));
const StyledCheckbox = styled(withTheme(Checkbox))(props => ({
    background: props.theme.palette.mode === 'light' ? props.theme.palette.grey.light : props.theme.palette.grey.dark,
    border: 0,
    borderRadius: '5px',
    padding: '0px',
    'svg': {
        color: props.theme.palette.mode === 'light' ? props.theme.palette.grey.light : props.theme.palette.grey.dark
    }
}));

export default function CheckboxUI({ title, onChange, checked, sx, style }) {
    if (!title) {
        return <></>;
    }
    return (
        <StyledFormControlLabel sx={sx} style={style} className="checkboxUI font medium" control={<StyledCheckbox onChange={onChange} checked={checked} />} label={title} />
    )
}
