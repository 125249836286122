import {
  Container,
  Grow,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Card from "../../components/Card/Card";
import { KeyboardArrowUpRounded } from "@mui/icons-material";
import BackButton from "../../components/UI/BackButton";

const scrollTop = 1000;

function Terms() {
  const { t } = useTranslation();

  const [showScrollToBottom, setShowScrollToBottom] = React.useState(false);

  const handleScrollButtonClick = (event) => {
    scrollOnce();
    setTimeout(() => {
      setShowScrollToBottom(false);
    }, 1);
  };

  const handleScroll = (event) => {
    if (document.documentElement.scrollTop > scrollTop) {
      setShowScrollToBottom(true);
    } else {
      setShowScrollToBottom(false);
    }
  };

  const scrollOnce = React.useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const theme = useTheme();

  return (
    <>
      <Header />
      <Container
        className="flex column"
        maxWidth="lg"
        sx={{ mt: { xs: 4, sm: 8, md: 12 }, pb: 7 }}
        style={{ display: "flex" }}
      >
        <Grow in={showScrollToBottom}>
          <IconButton
            onClick={handleScrollButtonClick}
            sx={{
              position: "fixed",
              bottom: 30,
              right: 50,
              color: "#000",
              backgroundColor: theme.palette.primary.main,
              zIndex: 5,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <KeyboardArrowUpRounded />
          </IconButton>
        </Grow>
        <BackButton sx={{ mb: 4 }} />
        <Card
          sx={{
            h1: { fontSize: { xs: "1.8rem", sm: "2.5rem" }, mt: 2 },
            h2: {
              fontSize: { xs: "1.4rem", sm: "1.8rem" },
              fontWeight: "bold",
              mt: 5,
            },
            h3: {
              fontSize: { xs: "1.2rem", sm: "1.3rem" },
              fontWeight: "bold",
              mt: 3,
            },
            "& p": {
              mt: 2,
              lineHeight: { xs: "1.5 !important", sm: "1.4 !important" },
              overflowWrap: "break-word",
            },
            ol: {
              listStyleType: "lower-alpha",
              ml: 2,
            },
            p: { xs: "30px 30px", sm: "45px 60px" },
          }}
        >
          <Typography variant="h1" className="font bold">
            KEWB TERMS OF SERVICE
          </Typography>
          <Typography variant="h2" className="font medium">
            Introduction
          </Typography>
          <Typography variant="h3" className="font medium">
            1.1. Overview of Kewb Labs OÜ
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ is a company registered in Estonia with the registered
            address at Narva mnt. 7B, Tallinn, Estonia 10117. Kewb Labs OÜ
            operates a multichannel live streaming web application and mobile
            app called Kewb, designed to provide users with an interactive and
            engaging platform for sharing and consuming live content. Our
            mission is to create an accessible and diverse environment for users
            to connect, learn, and explore through live streaming experiences.
          </Typography>
          <Typography variant="h3" className="font medium">
            1.2. Scope and Acceptance of Terms of Service
          </Typography>
          <Typography className="typography">
            By accessing or using the Kewb web app, available at go.kewb.io, or
            the Kewb mobile app, you acknowledge that you have read, understood,
            and agree to be bound by these Terms of Service ("Terms") and any
            future amendments. These Terms govern your access to and use of the
            Kewb web app, Kewb mobile app, and any other services, features, or
            content provided by Kewb Labs OÜ (collectively, the "Services").
          </Typography>
          <Typography className="typography">
            If you do not agree to these Terms, please do not access or use the
            Services. By creating an account, you represent and warrant that you
            are at least 18 years of age, or have the legal capacity to enter
            into a binding agreement. If you are using the Services on behalf of
            an organization, you represent that you have the authority to bind
            the organization to these Terms, and in such cases, "you" and "your"
            refer to the organization.
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ reserves the right to modify or replace these Terms at
            any time, at its sole discretion. Your continued use of the Services
            following any changes to the Terms indicates your acceptance of
            those changes. If you do not agree to any changes, you should
            discontinue your use of the Services.
          </Typography>

          <Typography variant="h2" className="font medium">
            Definitions
          </Typography>

          <Typography variant="h3" className="font medium">
            2.1. Kewb Web App
          </Typography>
          <Typography className="typography">
            The Kewb Web App refers to the browser-based version of the Kewb
            live streaming platform, which is accessible through the website
            go.kewb.io. Users can access the Kewb Web App on compatible devices
            with internet access to view, create, and interact with live
            content.
          </Typography>
          <Typography variant="h3" className="font medium">
            2.2. Kewb Mobile App
          </Typography>
          <Typography className="typography">
            The Kewb Mobile App is the mobile application version of the Kewb
            live streaming platform, designed for compatible smartphones and
            tablets. Users can download the Kewb Mobile App through their
            device's app store to access the platform and its features on the
            go.
          </Typography>
          <Typography variant="h3" className="font medium">
            2.3. User Account
          </Typography>
          <Typography className="typography">
            A User Account is a unique account created by an individual user on
            the Kewb platform, either through the Kewb Web App or the Kewb
            Mobile App. User Accounts require a valid email address and involve
            a sign-up process as described in Section 3. User Accounts allow
            individuals to access the platform, create and view content, and
            interact with other users.
          </Typography>
          <Typography variant="h3" className="font medium">
            2.4. Subscription Packages
          </Typography>
          <Typography className="typography">
            Subscription Packages refer to the various pricing options and
            service levels offered by Kewb Labs OÜ for accessing and using the
            Kewb platform. These include the limited free Basic Package, the 12
            USD per month Premium Package, and the Pay Per Minute Package, where
            1 second costs 1 cent. The features and limitations of each
            Subscription Package are outlined in Section 4.
          </Typography>
          <Typography variant="h3" className="font medium">
            2.5. Content
          </Typography>
          <Typography className="typography">
            Content refers to any text, graphics, images, audio, video, live
            streams, or other forms of data or communication that users create,
            upload, transmit, or display while using the Kewb platform. This
            includes, but is not limited to, live streaming content, chat
            messages, user profile information, and user-generated materials
            shared within the platform.
          </Typography>
          <Typography variant="h2" className="font medium">
            Account Creation and Access
          </Typography>
          <Typography variant="h3" className="font medium">
            3.1. Sign Up Process
          </Typography>
          <Typography className="typography">
            To create a User Account on the Kewb platform, individuals must
            provide a valid email address. Upon submission, users will receive a
            login or signup link to the provided email address. Users must
            follow the link to complete the account creation process, which
            includes uploading an avatar image, setting a username, and filling
            out the bio section (optional). By creating a User Account,
            individuals agree to abide by the Terms of Service and any
            applicable community guidelines.
          </Typography>
          <Typography variant="h3" className="font medium">
            3.2. Account Security
          </Typography>
          <Typography className="typography">
            Users are responsible for maintaining the confidentiality and
            security of their account credentials, including their email address
            and any associated passwords. Users must notify Kewb Labs OÜ
            immediately of any unauthorized use of their account or any
            suspected security breaches. Kewb Labs OÜ will not be liable for any
            loss or damage resulting from a user's failure to protect their
            account information. Kewb Labs OÜ reserves the right to suspend or
            terminate any User Account that it believes, in its sole discretion,
            has been compromised or is being used in violation of these Terms.
          </Typography>
          <Typography variant="h3" className="font medium">
            3.3. Account Termination
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ reserves the right to terminate or suspend any User
            Account without prior notice or liability, for any reason,
            including, but not limited to, violation of these Terms or any
            applicable community guidelines. Users may also voluntarily close
            their account at any time by contacting Kewb Labs OÜ customer
            support. Upon account termination, users will lose access to all
            features and content associated with their account. Any content
            created or uploaded by the user may be removed from the platform at
            Kewb Labs OÜ's discretion.
          </Typography>

          <Typography variant="h2" className="font medium">
            Subscription Packages and Payment
          </Typography>
          <Typography variant="h3" className="font medium">
            4.1. Basic Package
          </Typography>
          <Typography className="typography">
            The Basic Package is a limited, free-of-charge subscription plan
            offered by Kewb Labs OÜ. Users with the Basic Package can access
            select features and content on the Kewb platform. The specific
            limitations and restrictions of the Basic Package are subject to
            change and may be updated by Kewb Labs OÜ at its discretion.
          </Typography>

          <Typography variant="h3" className="font medium">
            4.2. Premium Package
          </Typography>
          <Typography className="typography">
            The Premium Package is a subscription plan priced at 12 USD per
            month. Users with the Premium Package gain access to additional
            features and content on the Kewb platform not available to Basic
            Package users. The specific features and benefits of the Premium
            Package are subject to change and may be updated by Kewb Labs OÜ at
            its discretion.
          </Typography>

          <Typography variant="h3" className="font medium">
            4.3. Pay Per Minute Package
          </Typography>
          <Typography className="typography">
            The Pay Per Minute Package is a flexible, usage-based subscription
            plan where users are charged 1 cent for every second of access to
            specific features or content on the Kewb platform. The Pay Per
            Minute Package is designed for users who require intermittent access
            or prefer to pay for usage as needed. The specific features and
            content available through the Pay Per Minute Package are subject to
            change and may be updated by Kewb Labs OÜ at its discretion.
          </Typography>

          <Typography variant="h3" className="font medium">
            4.4. Payment Methods
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ accepts credit card payments for its subscription
            services. Users must provide valid credit card information to
            purchase a Premium Package or access the Pay Per Minute Package
            features. Kewb Labs OÜ uses third-party payment processors to handle
            credit card transactions and does not store credit card information
            on its servers. By providing credit card information, users
            represent that they have the legal right to use the provided payment
            method.
          </Typography>

          <Typography variant="h3" className="font medium">
            4.5. Refund Policy
          </Typography>
          <Typography className="typography">
            All payments for subscription services are non-refundable, except as
            required by law or at the sole discretion of Kewb Labs OÜ. Users are
            responsible for any applicable taxes, fees, or charges related to
            their subscription. Kewb Labs OÜ reserves the right to modify its
            pricing and refund policies at any time, with or without notice.
            Users should review these policies periodically to stay informed of
            any changes.
          </Typography>

          <Typography variant="h2" className="font medium">
            Content and User Conduct
          </Typography>

          <Typography variant="h3" className="font medium">
            5.1. User-Generated Content
          </Typography>
          <Typography className="typography">
            Users are solely responsible for any content they create, upload,
            transmit, or display while using the Kewb platform. Kewb Labs OÜ
            does not endorse, support, or guarantee the accuracy, quality, or
            reliability of any user-generated content. Users understand that by
            using the platform, they may be exposed to content they find
            offensive, harmful, or objectionable.
          </Typography>

          <Typography variant="h3" className="font medium">
            5.2. Prohibited Content
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ prohibits the creation, upload, transmission, or
            display of content that:
          </Typography>
          <ol>
            <li>
              <Typography className="typography">
                Violates any local, national, or international laws and
                regulations.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Infringes on any intellectual property rights, including but not
                limited to copyrights, trademarks, and patents.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Contains explicit, obscene, or pornographic material.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Promotes or incites violence, hatred, discrimination, or
                bigotry.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Harasses, threatens, or defames any individual or group.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Contains false or misleading information.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Impersonates any person or entity, or falsely represents an
                affiliation with any person or entity.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Violates the privacy rights of any individual, including but not
                limited to the disclosure of personal information without
                consent.
              </Typography>
            </li>
          </ol>

          <Typography variant="h3" className="font medium">
            5.3. Copyright and Intellectual Property
          </Typography>
          <Typography className="typography">
            Users retain all ownership rights to the content they create and
            upload on the Kewb platform. By creating, uploading, transmitting,
            or displaying content, users grant Kewb Labs OÜ a non-exclusive,
            worldwide, royalty-free license to use, reproduce, modify,
            distribute, display, and perform the content in connection with the
            platform and its associated services.
          </Typography>

          <Typography variant="h3" className="font medium">
            5.4. User Conduct and Community Guidelines
          </Typography>
          <Typography className="typography">
            Users are expected to abide by the Kewb platform's community
            guidelines and engage in respectful, constructive behavior. Users
            are prohibited from engaging in any activities that:
          </Typography>
          <ol>
            <li>
              <Typography className="typography">
                Disrupt, interfere with, or negatively affect the platform or
                its services.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Attempt to gain unauthorized access to other users' accounts or
                personal information.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Harvest or collect any information about other users without
                their consent.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Use the platform for any commercial purpose, including
                advertising, solicitation, or spam.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Create or transmit any unsolicited or unauthorized promotional
                materials, including spam, chain letters, or pyramid schemes.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Use any automated means, including bots or scripts, to access,
                collect, or manipulate the platform or its services.
              </Typography>
            </li>
          </ol>
          <Typography className="typography">
            Kewb Labs OÜ reserves the right to take appropriate action against
            any user who violates these Terms or the community guidelines,
            including but not limited to suspending or terminating the user's
            account, removing content, or reporting the user to law enforcement
            authorities.
          </Typography>

          <Typography variant="h2" className="font medium">
            6.1. Privacy and Data Protection
          </Typography>

          <Typography variant="h3" className="font medium">
            Collection of Personal Information
          </Typography>

          <Typography variant="body1" className="typography">
            Kewb Labs OÜ collects personal information from users in order to
            provide the platform's services and improve the overall user
            experience. Personal information collected may include, but is not
            limited to, email addresses, usernames, avatar images,
            user-generated content, and any other information users choose to
            provide.
          </Typography>

          <Typography variant="h3" className="font medium">
            6.2. Use and Disclosure of Personal Information
          </Typography>

          <Typography variant="body1" className="typography">
            Kewb Labs OÜ uses the collected personal information for purposes
            such as:
          </Typography>

          <ol>
            <li>
              <Typography variant="body1" className="typography">
                Providing and maintaining the platform and its services.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" className="typography">
                Personalizing the user experience.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" className="typography">
                Communicating with users about their accounts, platform updates,
                and promotional materials.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" className="typography">
                Ensuring the platform's security and integrity.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" className="typography">
                Complying with applicable legal requirements and responding to
                lawful requests from law enforcement or government authorities.
              </Typography>
            </li>
          </ol>
          <Typography variant="body1" className="typography">
            Kewb Labs OÜ may share users' personal information with trusted
            third parties, such as service providers, for purposes consistent
            with this Privacy Policy. Kewb Labs OÜ will not sell or rent users'
            personal information to third parties without their explicit
            consent.
          </Typography>

          <Typography variant="h3" className="font medium">
            6.3. Data Security and Retention
          </Typography>

          <Typography variant="body1" className="typography">
            Kewb Labs OÜ implements appropriate technical and organizational
            measures to protect users' personal information from unauthorized
            access, disclosure, alteration, or destruction. However, users
            acknowledge that no method of data transmission or storage can
            guarantee complete security. Kewb Labs OÜ retains users' personal
            information for as long as necessary to fulfill the purposes
            outlined in this Privacy Policy, comply with legal obligations,
            resolve disputes, and enforce agreements.
          </Typography>

          <Typography variant="h3" className="font medium">
            6.4. User Rights and Control
          </Typography>

          <Typography variant="body1" className="typography">
            Users have the right to access, modify, or delete their personal
            information held by Kewb Labs OÜ, subject to applicable laws and
            regulations. Users can exercise these rights by contacting Kewb Labs
            OÜ's customer support or accessing their account settings. Users may
            also have the right to object to or restrict the processing of their
            personal information, or to withdraw their consent to the processing
            of their personal information at any time, subject to applicable
            laws and regulations. Users should be aware that withdrawing their
            consent or requesting the deletion of their personal information may
            affect their ability to use the platform and its services.
          </Typography>

          <Typography variant="h2" className="font medium">
            Third-Party Services and Links
          </Typography>
          <Typography variant="h3" className="font medium">
            7.1. Integration of Third-Party Services
          </Typography>
          <Typography className="typography">
            The Kewb platform may integrate or interact with third-party
            services, such as social media platforms, payment processors, or
            other external services, to enhance the user experience and provide
            additional functionality. These third-party services are not owned
            or controlled by Kewb Labs OÜ, and users are responsible for
            reviewing and understanding the terms of service, privacy policies,
            and other applicable agreements governing their use of these
            services.
          </Typography>

          <Typography variant="h3" className="font medium">
            7.2. Links to External Websites
          </Typography>
          <Typography className="typography">
            The Kewb platform may contain links to external websites that are
            not owned or controlled by Kewb Labs OÜ. Kewb Labs OÜ does not
            endorse, support, or guarantee the accuracy, quality, or reliability
            of any content, products, or services available on these external
            websites. Users access and use these external websites at their own
            risk and are responsible for complying with any applicable terms and
            conditions or privacy policies.
          </Typography>

          <Typography variant="h3" className="font medium">
            7.3. Liability for Third-Party Services and Links
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ is not responsible or liable for any loss or damage
            resulting from users' access to or use of third-party services or
            external websites, including any issues related to the availability,
            security, or content of these services or websites. Users are
            encouraged to exercise caution and discretion when accessing or
            using any third-party services or external websites linked to or
            integrated with the Kewb platform.
          </Typography>

          <Typography variant="h2" className="font medium">
            Dispute Resolution and Governing Law
          </Typography>

          <Typography variant="h3" className="font medium">
            8.1. Governing Law
          </Typography>
          <Typography className="typography">
            These Terms of Service and any disputes arising out of or relating
            to the use of the Kewb platform or its services shall be governed by
            and construed in accordance with the laws of Estonia, without regard
            to its conflict of law provisions.
          </Typography>

          <Typography variant="h3" className="font medium">
            8.2. Dispute Resolution
          </Typography>
          <Typography className="typography">
            In the event of any dispute, claim, or controversy arising out of or
            relating to these Terms, the use of the Kewb platform, or its
            services, users agree to first attempt to resolve the dispute
            amicably through negotiation with Kewb Labs OÜ. If the parties are
            unable to resolve the dispute through negotiation, users agree to
            submit the dispute to the exclusive jurisdiction of the Harju County
            Court of Estonia.
          </Typography>

          <Typography variant="h3" className="font medium">
            8.3. Legal Remedies and Limitation of Liability
          </Typography>
          <Typography className="typography">
            To the extent permitted by law, users agree that Kewb Labs OÜ's
            total liability for any claim arising out of or relating to these
            Terms, the Kewb platform, or its services shall not exceed the
            amount paid by the user to Kewb Labs OÜ for the service in question
            during the twelve (12) months preceding the date of the claim. Kewb
            Labs OÜ shall not be liable for any indirect, incidental, special,
            consequential, or punitive damages, including but not limited to
            loss of profits, data, or goodwill, arising out of or relating to
            these Terms, the Kewb platform, or its services, even if Kewb Labs
            OÜ has been advised of the possibility of such damages.
          </Typography>

          <Typography variant="h3" className="font medium">
            8.4. Indemnification
          </Typography>
          <Typography className="typography">
            Users agree to indemnify, defend, and hold harmless Kewb Labs OÜ,
            its affiliates, officers, directors, employees, and agents from and
            against any and all claims, liabilities, damages, losses, or
            expenses, including reasonable attorneys' fees and costs, arising
            out of or in any way connected with their access to or use of the
            Kewb platform, their content, or their violation of these Terms.
          </Typography>

          <Typography variant="h2" className="font medium">
            Changes to the Terms of Service
          </Typography>
          <Typography variant="h3" className="font medium">
            9.1. Updates and Modifications
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ reserves the right to update, modify, or amend these
            Terms of Service at any time and at its sole discretion. Changes to
            the Terms will be effective immediately upon their posting on the
            Kewb platform, and users are encouraged to review the Terms
            periodically to ensure their understanding of the current version.
          </Typography>
          <Typography variant="h3" className="font medium">
            9.2. Continued Use of the Platform
          </Typography>
          <Typography className="typography">
            Users' continued access to or use of the Kewb platform after any
            changes to the Terms become effective constitutes their acceptance
            of the revised Terms. If users do not agree to the changes, they
            should discontinue their use of the platform and its services.
          </Typography>
          <Typography variant="h3" className="font medium">
            9.3. Notification of Changes
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ may, but is not obligated to, notify users of any
            significant changes to the Terms by email or through an announcement
            on the platform. Users are responsible for providing and maintaining
            a valid email address associated with their User Account and for
            ensuring that their email settings allow them to receive
            notifications from Kewb Labs OÜ.
          </Typography>
          <Typography variant="h2" className="font medium">
            Contact Information and Support
          </Typography>
          <Typography variant="h3" className="font medium">
            10.1. Customer Support
          </Typography>
          <Typography className="typography">
            For any questions, concerns, or issues related to the Kewb platform
            or its services, users may contact Kewb Labs OÜ's customer support
            team. Kewb Labs OÜ strives to provide prompt and effective
            assistance to users and address their concerns as efficiently as
            possible.
          </Typography>

          <Typography variant="h3" className="font medium">
            10.2. Feedback and Suggestions
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ welcomes and appreciates users' feedback and
            suggestions regarding the platform and its services. By providing
            feedback or suggestions, users grant Kewb Labs OÜ an unrestricted,
            perpetual, irrevocable, non-exclusive, royalty-free, worldwide
            license to use, reproduce, modify, adapt, publish, translate,
            distribute, sublicense, and display the feedback or suggestions in
            any form and for any purpose.
          </Typography>

          <Typography variant="h3" className="font medium">
            10.3. Contact Information
          </Typography>
          <Typography className="typography">
            Users can contact Kewb Labs OÜ by email hi@kewb.io. Please include
            the user's account information and a detailed description of their
            inquiry or concern when contacting Kewb Labs OÜ for assistance.
          </Typography>

          <Typography variant="h2" className="font medium">
            Termination and Suspension
          </Typography>
          <Typography variant="h3" className="font medium">
            11.1. User Termination
          </Typography>
          <Typography className="typography">
            Users may terminate their account and discontinue their use of the
            Kewb platform and its services at any time. To terminate an account,
            users should contact Kewb Labs OÜ's customer support or follow the
            account termination process provided within the platform. Upon
            termination, users will lose access to any content, features, or
            services associated with their account.
          </Typography>
          <Typography variant="h3" className="font medium">
            11.2. Suspension or Termination by Kewb Labs OÜ
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ reserves the right to suspend or terminate users'
            accounts, access to the platform, or use of its services at any
            time, with or without notice, for any reason, including but not
            limited to:
          </Typography>

          <ol>
            <li>
              <Typography className="typography">
                Violation of these Terms of Service, the community guidelines,
                or any applicable laws and regulations.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Non-payment of fees or charges related to subscription packages.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Engaging in activities that may harm the platform, its services,
                or other users.
              </Typography>
            </li>
            <li>
              <Typography className="typography">
                Providing false or misleading information during account
                creation or while using the platform.
              </Typography>
            </li>
          </ol>
          <Typography variant="h3" className="font medium">
            11.3. Effect of Termination or Suspension
          </Typography>
          <Typography className="typography">
            Upon suspension or termination of a user's account, the user will
            lose access to their account, content, and any features or services
            associated with their account. Kewb Labs OÜ is not responsible for
            any loss or damage resulting from account suspension or termination
            and has no obligation to provide a refund for any prepaid fees or
            charges.
          </Typography>

          <Typography variant="h3" className="font medium">
            11.4. Survival of Terms
          </Typography>
          <Typography className="typography">
            The provisions of these Terms that by their nature should survive
            termination or suspension shall remain in effect, including but not
            limited to the sections regarding intellectual property, dispute
            resolution, liability limitations, and indemnification.
          </Typography>
          <Typography variant="h2" className="font medium">
            General Provisions
          </Typography>
          <Typography variant="h3" className="font medium">
            12.1. Entire Agreement
          </Typography>
          <Typography className="typography">
            These Terms of Service, along with any applicable privacy policies,
            community guidelines, or other agreements referenced herein,
            constitute the entire agreement between users and Kewb Labs OÜ
            concerning the use of the Kewb platform and its services. These
            Terms supersede any prior agreements or understandings between users
            and Kewb Labs OÜ with respect to the subject matter hereof.
          </Typography>

          <Typography variant="h3" className="font medium">
            12.2. Severability
          </Typography>
          <Typography className="typography">
            If any provision of these Terms is deemed invalid, unlawful, void,
            or unenforceable by a court of competent jurisdiction, that
            provision shall be severed from the Terms, and the remaining
            provisions shall remain in full force and effect.
          </Typography>

          <Typography variant="h3" className="font medium">
            12.3. Waiver
          </Typography>
          <Typography className="typography">
            No waiver by Kewb Labs OÜ of any term or condition set forth in
            these Terms shall be deemed a further or continuing waiver of such
            term or condition, and any failure by Kewb Labs OÜ to assert a right
            or provision under these Terms shall not constitute a waiver of such
            right or provision.
          </Typography>

          <Typography variant="h3" className="font medium">
            12.4. Assignment
          </Typography>
          <Typography className="typography">
            Users may not assign or transfer any of their rights or obligations
            under these Terms without the prior written consent of Kewb Labs OÜ.
            Kewb Labs OÜ may freely assign or transfer its rights and
            obligations under these Terms without restriction or notice to
            users.
          </Typography>

          <Typography variant="h3" className="font medium">
            12.5. Force Majeure
          </Typography>
          <Typography className="typography">
            Kewb Labs OÜ shall not be liable for any delay or failure to perform
            its obligations under these Terms if such delay or failure results
            from events or circumstances beyond its reasonable control,
            including but not limited to acts of God, labor disputes, government
            actions, war, terrorism, natural disasters, or pandemics. In the
            event of such a delay or failure, Kewb Labs OÜ shall use reasonable
            efforts to resume performance as soon as possible.
          </Typography>
          <Typography variant="h2" className="font medium">
            Language and Interpretation
          </Typography>
          <Typography variant="h3" className="font medium">
            13.1. Language
          </Typography>
          <Typography className="typography">
            These Terms of Service are written in English. In the event that a
            translated version of these Terms conflicts with the English
            version, the English version shall prevail.
          </Typography>

          <Typography variant="h3" className="font medium">
            13.2. Interpretation
          </Typography>
          <Typography className="typography">
            In these Terms, unless the context requires otherwise, words in the
            singular include the plural and vice versa, and references to one
            gender include all genders. The headings in these Terms are for
            convenience only and shall not affect their interpretation. Any
            reference to a statute or regulation shall include any amendments or
            replacements to that statute or regulation.
          </Typography>

          <Typography variant="h3" className="font medium">
            13.3. No Third-Party Beneficiaries
          </Typography>
          <Typography className="typography">
            These Terms are for the benefit of, and may be enforced only by, the
            parties hereto, and nothing in these Terms shall be deemed to create
            any rights in favor of any third parties, except as explicitly
            provided otherwise in these Terms.
          </Typography>

          <Typography variant="h3" className="font medium">
            13.4. Relationship between the Parties
          </Typography>
          <Typography className="typography">
            Nothing in these Terms shall be construed to create a partnership,
            joint venture, agency, or employment relationship between the
            parties. Users are not authorized to act on behalf of, or bind, Kewb
            Labs OÜ in any manner whatsoever.
          </Typography>
        </Card>
      </Container>
    </>
  );
}

export default Terms;
