import { SET_LOADINGS, REMOVE_LOADINGS } from '../types'

const initialState = null;

export const loadingsReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case SET_LOADINGS: {
      const { data } = payload
      return [...state,
        {
          name: data,
          isLoading: true
        }
      ];
    }

    case REMOVE_LOADINGS: {
      const { data } = payload
      const newState = state;
      const obj = newState.find(loading => loading.name === data);
      const idx = newState.indexOf(obj);
      newState.splice(idx, 1);
      return newState;
    }
    
    default: {
      return state
    }
  }
}
