import {
  API_CHECK_STREAM_EXISTS_ROTUE,
  API_CREATE_STREAM_SESSION,
  API_GET_SESSION_PARTICIPANTS,
  API_PARTICIPANT,
  API_STREAM,
  API_STREAM_SESSION,
} from "../../utils/consts";
import { setMessage } from "../rootReducer";
import {
  AWAITERS,
  AWAITERS_ADD_ONE,
  AWAITERS_REMOVE,
  AWAITERS_REMOVE_ONE,
  PARTICIPANTS,
  PARTICIPANTS_REMOVE,
  SESSION_ACCEPTED,
  SESSION_CREATE,
  SESSION_DELETE,
} from "../types";
import axios from "axios";

export const SessionCreate = (data) => {
  return {
    type: SESSION_CREATE,
    payload: { data },
  };
};
export const SessionDelete = () => {
  return {
    type: SESSION_DELETE,
  };
};
export const sessionAccepted = (data) => {
  return {
    type: SESSION_ACCEPTED,
    payload: { data },
  };
};
export const setParticipants = (data) => {
  return {
    type: PARTICIPANTS,
    payload: { data },
  };
};
export const removeParticipants = () => {
  return {
    type: PARTICIPANTS_REMOVE,
  };
};
export const setAwaiters = (data) => {
  return {
    type: AWAITERS,
    payload: { data },
  };
};
export const addAwaiter = (data) => {
  return {
    type: AWAITERS_ADD_ONE,
    payload: { data },
  };
};
export const removeAwaiters = () => {
  console.log("removeAwaiters");
  return {
    type: AWAITERS_REMOVE,
  };
};
export const removeOneAwaiter = (data) => {
  return {
    type: AWAITERS_REMOVE_ONE,
    payload: { data },
  };
};

export const streamExists = (streamHash) => async (dispatch) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        API_CHECK_STREAM_EXISTS_ROTUE +
        streamHash,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );

    if (res.data) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response.data?.errors[0])
      dispatch(setMessage(error.response.data.errors[0].text, "error"));
    return false;
  }
};

export const createNewSession = (item) => async (dispatch) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_CREATE_STREAM_SESSION,
      JSON.stringify(item),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );

    if (res.data) {
      dispatch(SessionCreate(res.data.data));
      dispatch(setMessage(res.data.message));
    } else {
      if (res.data?.errors[0]) dispatch(setMessage(res.data.errors[0].text));
    }
  } catch (error) {
    if (error.response.data?.errors[0])
      dispatch(setMessage(error.response.data.errors[0].text, "error"));
  }
};

export const getSession = (item) => async (dispatch) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_STREAM + item.slug,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );

    if (res.data) {
      if (res.data.errors) {
        dispatch(setMessage(res.data.errors[0].text, "error"));
      } else {
        dispatch(SessionCreate(res.data.data));
      }
      //dispatch(setMessage(data.message));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const getParticipants = (streamHash) => async (dispatch) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        API_GET_SESSION_PARTICIPANTS +
        streamHash +
        "/participants",
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.data) {
      dispatch(setParticipants(res.data.data.accepted_participants));
      dispatch(setAwaiters(res.data.data.idle_participants));
    }
  } catch (error) {
    if (error.response.data?.errors[0])
      dispatch(setMessage(error.response.data.errors[0].text, "error"));
  }
};

export const participant = (action, streamHash, user) => async (dispatch) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_BACKEND_URL +
        API_PARTICIPANT +
        streamHash +
        "/participant/" +
        user.hash +
        "/" +
        action,
      {},
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.data) {
      dispatch(setMessage(res.data.message));
      dispatch(removeOneAwaiter(user));
    }
  } catch (error) {
    if (error.response.data?.errors[0])
      dispatch(setMessage(error.response.data.errors[0].text, "error"));
  }
};

export const joinSession = (item, slug) => async (dispatch) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_STREAM + slug + "/join",
      JSON.stringify(item),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.data?.type === "success") {
      dispatch(SessionCreate(res.data.data));

      //Save socket token for future connections to the given room.
      localStorage.setItem("core_api_token", res.data.data.chat_token);

      //dispatch(setMessage(data.message));
      //window.location.href = `/session/${slug}`;
    }

    if (res.data?.type === "error") {
      dispatch(setMessage(res.data.errors[0].text, "error"));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const endSession = (stream_session) => async (dispatch) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_BACKEND_URL +
        API_STREAM_SESSION +
        "/" +
        stream_session.stream_session.hash,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );

    if (res.data?.type === "success") {
      dispatch(removeAwaiters());
      dispatch(SessionDelete());
      window.location.reload();
    }
    if (res.data?.type === "error") {
      dispatch(setMessage(res.data.errors[0].text, "error"));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};
