import { Badge, IconButton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { isMobile } from "react-device-detect";

export default function IconButtonUI({
  icon,
  description,
  onClick,
  value,
  badge,
}) {
  const theme = useTheme();

  if (badge) {
    return (
      <Badge
        badgeContent={badge}
        color="primary"
        sx={{
          ".MuiBadge-badge": {
            top: 5,
            right: isMobile ? 0 : 5,
          },
        }}
      >
        <Box
          className="flex column items-center relative"
          sx={{
            width: isMobile ? "22px" : "3vw",
            height: isMobile ? "22px" : "3vw",
            "& svg": isMobile && {
              width: 22,
              height: 22,
            },
            bottom: "4px",
          }}
        >
          <IconButton
            onClick={onClick}
            sx={{ boxShadow: "0px 3px 6px #00000014" }}
          >
            {icon}
          </IconButton>
          {!isMobile && (
            <Typography
              sx={{
                color: theme.palette.text.dark,
                fontSize: "14px",
                position: "absolute",
                bottom: "-12px",
                width: "190%",
                textAlign: "center",
              }}
              className="typography"
            >
              {description}
            </Typography>
          )}
        </Box>
      </Badge>
    );
  }
  return (
    <Box
      className="flex column items-center relative"
      sx={{
        width: isMobile ? "22px" : "3vw",
        height: isMobile ? "22px" : "3vw",
        "& svg": isMobile && {
          width: 22,
          height: 22,
        },
        bottom: "4px",
      }}
    >
      <IconButton onClick={onClick} sx={{ boxShadow: "0px 3px 6px #00000014" }}>
        {icon}
      </IconButton>
      {!isMobile && (
        <Typography
          sx={{
            color: theme.palette.text.dark,
            fontSize: "0.75vw",
            position: "absolute",
            bottom: "-0.6vw",
            width: "190%",
            textAlign: "center",
          }}
          className="typography"
        >
          {description}
        </Typography>
      )}
    </Box>
  );
}
