import * as React from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/userAction";
import Header from "../Header/Header";

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

function Logout({ logoutUser }) {
  React.useEffect(() => {
    logoutUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
