import { SESSION_ACCEPTED, SESSION_CREATE, SESSION_DELETE } from "../types";

const initialState = {};

export const streamSessionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SESSION_CREATE: {
      let { data } = payload;
      return data;
    }
    case SESSION_ACCEPTED: {
      let { data } = payload;
      if (
        data.current_participant.hash === state.current_participant.hash &&
        data.stream_session.id === state.stream_session.id
      ) {
        let newState = Object.assign({}, state);
        newState.current_participant.status = "accepted";
        return newState;
      }
      return state;
    }
    case SESSION_DELETE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
