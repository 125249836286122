import { Box, Button, Dialog, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../Card/Card";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as FacebookIcon } from "../../assets/images/icons/svg/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/icons/svg/youtube.svg";
import { ReactComponent as TwitchIcon } from "../../assets/images/icons/svg/twitch.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/icons/svg/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/icons/svg/twitter.svg";
import PairedIcon from "../../assets/images/icons/svg/paired.svg";
import NotPairedIcon from "../../assets/images/icons/svg/notpaired.svg";
import { useState } from "react";

export default function Channels({ open, setOpen, channels, setChannels }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const [first, setfirst] = useState(true);

  const handlePair = (index) => {
    channels[index].paired = !channels[index].paired;
    setfirst(!first);
    setChannels(channels);
  };

  const handleSave = (index) => {
    handleClose();
  };

  const [socials, setSocials] = React.useState([
    {
      name: t("Facebook"),
      icon: <FacebookIcon />,
      paired: false,
    },
    {
      name: t("YouTube"),
      icon: <YoutubeIcon />,
      paired: false,
    },
    {
      name: t("Twitch"),
      icon: <TwitchIcon />,
      paired: false,
    },
    {
      name: t("Instagram"),
      icon: <InstagramIcon />,
      paired: false,
    },
    {
      name: t("Twitter"),
      icon: <TwitterIcon />,
      paired: false,
    },
  ]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <Card
        title={t("Choose Channel to Pair")}
        icon={null}
        altIcon={<CloseIcon />}
        altIconOnClick={handleClose}
      >
        <Box
          className="flex column"
          sx={{
            background:
              theme.palette.mode === "light"
                ? theme.palette.divider.light
                : theme.palette.divider.dark,
            padding: "25px 65px",
            gap: "11px",
          }}
        >
          {channels?.length > 0 &&
            channels.map((channel, i) => {
              return (
                <Box key={"ca" + i}>
                  <Card
                    onClick={() => handlePair(i)}
                    className="flex items-center hover"
                    sx={{
                      flexDirection: "row",
                      padding: "14px 24px 14px 16px",
                      gap: "15px",
                      cursor: "pointer",
                    }}
                  >
                    {channel.icon}
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? theme.palette.text.light
                            : theme.palette.text.dark,
                        fontSize: "14px",
                      }}
                      className="typography bold"
                    >
                      {channel.name}
                    </Typography>
                    <Box
                      className="flex column center relative"
                      sx={{ ml: "auto", mr: 1, mb: 1.5 }}
                    >
                      <img
                        alt="pairing"
                        src={channel.paired ? PairedIcon : NotPairedIcon}
                      />
                      <Typography
                        className="typography xsm text-center"
                        sx={{
                          color: channel.paired ? "#2DFD95" : "#878787",
                          position: "absolute",
                          width: "250%",
                          bottom: "-15px",
                        }}
                      >
                        {channel.paired ? t("Paired") : t("Not paired")}
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              );
            })}
        </Box>
        <Box className="flex column center" sx={{ padding: "18px 166px" }}>
          <Button
            onClick={handleSave}
            className="btn"
            variant="contained"
            color="primary"
            sx={{ padding: "8px 42px !important" }}
          >
            {t("Save")}
          </Button>
        </Box>
      </Card>
    </Dialog>
  );
}
