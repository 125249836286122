import {
  Box,
  Button,
  Container,
  Grow,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import { ReactComponent as SmileIcon } from "../../../assets/images/icons/svg/smile.svg";
import { ReactComponent as CrownIcon } from "../../../assets/images/icons/svg/crown.svg";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../../components/Card/Card";
import SelectBox from "../../../components/UI/SelectBox";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
function Membership() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [money, setMoney] = React.useState(1000);
  const balance = useSelector((state) => state.session?.user?.balance);
  // variable credita
  const [credits, setCredits] = React.useState(10);
  const [active, setActive] = React.useState(1);
  function setNewCredits(event) {
    let max_price =
      balance && balance.bonus_discount_percent
        ? Math.round(2500 / (1 - +balance.bonus_discount_percent))
        : 2500;

    if (max_price >= event.target.value) {
      setMoney(event.target.value);
      setNewMoney(event.target.value);
    } else {
      setMoney(max_price);
      setNewMoney(max_price);
    }
  }

  const handleUpgrade = () => {
    navigate("/payment");
  };

  function setNewMoney(arg) {
    setCredits(Math.round(arg * 0.1 * 100) / 1000);
  }
  return (
    <>
      <SmileIcon color="primary" style={{ display: "none" }} />
      <Header />
      <Container
        className="flex column"
        maxWidth="sm"
        sx={{
          mt: { xs: 4, sm: 8, md: 14 },
          gap: { xs: "26px", sm: "34px", md: "42px" },
        }}
        style={{ display: "flex" }}
      >
        <Link to="/settings">
          <Button
            variant="outlined"
            color="third"
            className="btn btn-third long"
            startIcon={
              <KeyboardReturnIcon
                sx={{
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.text.dark
                      : theme.palette.text.light,
                  width: "1.3em",
                  height: "1.3em",
                }}
              />
            }
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
              border:
                "2px solid" +
                (theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light),
              "&:hover": {
                border:
                  "2px solid" +
                  (theme.palette.mode === "light"
                    ? theme.palette.text.dark
                    : theme.palette.text.light),
              },
              width: "fit-content",
            }}
          >
            {t("Back")}
          </Button>
        </Link>
        <Box className="flex column" sx={{ gap: "20px" }}>
          <Typography
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
            }}
            className="typography lg bold"
          >
            {t("Membership")}
          </Typography>

          <SelectBox active={active === 1} onClick={() => setActive(1)}>
            <Typography
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.text.light
                    : theme.palette.text.dark,
                gap: "6px",
              }}
              className="typography bold xlg flex items-center absolute right-10"
            >
              {t("Free")}
            </Typography>
            <Typography
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.text.light
                    : theme.palette.text.dark,
              }}
              className="typography bold xlg"
            >
              {t("Basic")}
            </Typography>
            <List
              className="typography lg semibold"
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.text.light
                    : theme.palette.text.dark,
              }}
            >
              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <Box
                    sx={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      background: "grey",
                    }}
                  />
                </ListItemIcon>
                {t("720p secure streaming")}
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <Box
                    sx={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      background: "grey",
                    }}
                  />
                </ListItemIcon>
                {t("2 stream channels")}
              </ListItem>
            </List>
          </SelectBox>

          <SelectBox active={active === 2} onClick={() => setActive(2)}>
            <Typography
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.text.light
                    : theme.palette.text.dark,
                gap: "6px",
              }}
              className="typography bold xlg flex items-center absolute right-10"
            >
              {t("$12 mo")}
            </Typography>

            <Typography
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.text.light
                    : theme.palette.text.dark,
                gap: "6px",
              }}
              className="typography bold xlg flex items-center"
            >
              <span className="flex center" style={{ height: "100%" }}>
                {t("PRO")}
              </span>
              <CrownIcon
                style={{
                  fill:
                    active === 2
                      ? theme.palette.primary.light
                      : theme.palette.fourth.light,
                }}
              />
            </Typography>

            <List
              className="typography lg semibold"
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.text.light
                    : theme.palette.text.dark,
              }}
            >
              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <Box
                    sx={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      background: "grey",
                    }}
                  />
                </ListItemIcon>
                {t("1080p secure streaming")}
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <Box
                    sx={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      background: "grey",
                    }}
                  />
                </ListItemIcon>
                {t("All stream channels")}
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <Box
                    sx={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      background: "grey",
                    }}
                  />
                </ListItemIcon>
                {t("No Kewb watermark")}
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <Box
                    sx={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      background: "grey",
                    }}
                  />
                </ListItemIcon>
                {t("10 hours of recording")}
              </ListItem>
            </List>
            <Button
              className="btn w-fit"
              color={active === 2 ? "primary" : "fourth"}
              variant="contained"
              onClick={handleUpgrade}
              sx={{ marginLeft: "auto !important" }}
            >
              {t("Upgrade")}
            </Button>
          </SelectBox>
        </Box>
        <Box className="flex column" sx={{ gap: "20px", marginBottom: "50px" }}>
          <Typography
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
            }}
            className="typography lg bold"
          >
            {t("Pay per Second (All features)")}
          </Typography>
          <Grow in timeout={300}>
            <Box
              className="flex items-center cursor-pointer"
              sx={{ gap: "20px" }}
            >
              <Box
                sx={{
                  width: "19px",
                  height: "19px",
                  borderRadius: "50%",
                  background: "transparent",
                }}
              />
              <Card
                className={"w-full relative"}
                sx={{
                  padding: "30px 24px 30px 40px",
                  background:
                    theme.palette.mode === "light"
                      ? theme.palette.paper.light
                      : theme.palette.paper.dark,
                }}
              >
                <Box className="flex flex-start">
                  <Box className="flex" style={{ alignItems: "flex-end" }}>
                    <Box
                      className="flex column"
                      sx={{ gap: 1, justifySelf: "center", width: "40%" }}
                    >
                      <Typography
                        className="font EffraBold bold"
                        sx={{
                          color: "black",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        {t("Seconds")}
                      </Typography>
                      <TextField
                        fullWidth
                        onChange={setNewCredits}
                        value={money}
                        // type="number"
                        className="font EffraBold inputField"
                        sx={{
                          backgroundColor: "#F5F5F5",
                          border: "unset",
                          borderRadius: "10px",
                          "& .MuiInputAdornment-root": {
                            marginRight: "-38px",
                          },
                          input: {
                            color: "#212121",
                            fontFamily: '"Effra Bold" !important',
                            fontSize: "1.3rem",

                            textAlign: "flex-start",
                            paddingLeft: "14px",
                          },
                        }}
                      />
                    </Box>

                    {!isMobile && (
                      <Box
                        className="flex column center"
                        sx={{
                          gap: 1,
                          //   position: "absolute",
                          right: "90px",
                          bottom: "36%",
                        }}
                      >
                        <Typography
                          className="font EffraBold bold"
                          sx={{
                            color: "black",
                            textAlign: "left",
                            fontSize: "15px",
                            // padding: "0px 30px",
                          }}
                        >
                          {t("Cost")}
                        </Typography>
                        <Typography
                          className="EffraBold"
                          style={{
                            padding: "10px 30px",
                            color: "black",
                            textAlign: "center",
                            height: "62.69px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "1.5rem",
                            font: "Gillroy-Bold",
                            fontWeight: 1000,
                          }}
                        >
                          {balance && balance.bonus_discount_percent ? (
                            <>
                              <p
                                style={{
                                  fontSize: "0.875rem",
                                  textDecoration: "line-through",
                                  paddingRight: "10px",
                                }}
                              >
                                ${credits}
                              </p>

                              <p style={{ color: "#77F73B" }}>
                                {credits &&
                                  (
                                    credits *
                                    (1 - +balance.bonus_discount_percent)
                                  ).toFixed(2)}
                                $
                              </p>
                            </>
                          ) : (
                            "$" + credits
                          )}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      style={{
                        marginLeft: "auto",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="btn w-fit"
                        color={"fourth"}
                        variant="contained"
                        sx={{
                          color: "black",
                          height: "41px",
                          padding: "10px 20px !important",
                          borderRadius: "10px !important",
                          marginTop: "30px",
                        }}
                      >
                        {t("Purchase")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Grow>
        </Box>
      </Container>
    </>
  );
}

export default Membership;
