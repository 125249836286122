import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { isMobile } from "react-device-detect";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export default function DropZone(props) {
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="previw"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{
          backgroundColor:
            theme.palette.mode === "light"
              ? theme.palette.divider.light
              : theme.palette.divider.dark,
          padding: { xs: "80px 136px", sm: "60px 136px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          position: "relative",
        }}
      >
        <input {...getInputProps()} />
        <FileOpenIcon
          sx={{
            color:
              theme.palette.mode === "light"
                ? theme.palette.text.light
                : theme.palette.text.dark,
            opacity: 0.3,
            width: "2em",
            height: "2em",
            mb: 1,
          }}
        />
        <Typography
          sx={{
            opacity: 0.6,
            position: { xs: "absolute", sm: "initial" },
            bottom: { xs: "35px", sm: "none" },
          }}
          className="typography text-center"
        >
          {isMobile
            ? t("Tap to select some files")
            : t("Drag 'n' drop some files here, or click to select files")}
        </Typography>
      </Box>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}
