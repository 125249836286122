import {
  PARTICIPANTS,
  PARTICIPANTS_ADD_ONE,
  PARTICIPANTS_REMOVE,
} from "../types";

const initialState = [];
export const participantsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PARTICIPANTS: {
      const { data } = payload;
      return data;
    }
    case PARTICIPANTS_ADD_ONE: {
      const { data } = payload;
      return [...state, data];
    }
    case PARTICIPANTS_REMOVE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
