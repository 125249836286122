import {
  Box,
  Button,
  Container,
  Divider,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import { ReactComponent as SmileIcon } from "../../assets/images/icons/svg/smile.svg";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function Settings() {
  const { t } = useTranslation();
  const settings = useMemo(
    () => [
      {
        icon: <ManageAccountsOutlinedIcon color="primary" />,
        name: t("Account Settings"),
        link: "/settings/account",
      },
      {
        icon: <SmileIcon color="primary" />,
        name: t("Membership"),
        link: "/settings/membership",
      },
      {
        icon: <ContactSupportOutlinedIcon color="primary" />,
        name: t("Support"),
        link: "/settings/support",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const theme = useTheme();

  return (
    <>
      <SmileIcon color="primary" style={{ display: "none" }} />
      <Header />
      <Container
        className="flex column"
        maxWidth="sm"
        sx={{ mt: 14, gap: "42px" }}
        style={{ display: "flex" }}
      >
        <Link to="/">
          <Button
            variant="outlined"
            color="third"
            className="btn btn-third long"
            startIcon={
              <KeyboardReturnIcon
                sx={{
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.text.dark
                      : theme.palette.text.light,
                  width: "1.3em",
                  height: "1.3em",
                }}
              />
            }
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
              border:
                "2px solid" +
                (theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light),
              "&:hover": {
                border:
                  "2px solid" +
                  (theme.palette.mode === "light"
                    ? theme.palette.text.dark
                    : theme.palette.text.light),
              },
              width: "fit-content",
            }}
          >
            {t("Back")}
          </Button>
        </Link>
        <Box className="flex column" sx={{ gap: "10px" }}>
          <Typography
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
            }}
            className="typography lg bold"
          >
            {t("Settings")}
          </Typography>
          <Card className="menu" sx={{ padding: "0" }}>
            {settings.map((setting, i) => (
              <div key={"se" + i}>
                <Link to={setting.link}>
                  <ListItem
                    className="typography bold capitalize"
                    sx={{
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.text.light
                          : theme.palette.text.dark,
                      minWidth: "231px",
                      padding: "17px 20px",
                    }}
                  >
                    {setting.icon}
                    &emsp;{setting.name}
                    <NavigateNextIcon sx={{ ml: "auto" }} />
                  </ListItem>
                </Link>
                {settings.length !== i + 1 && (
                  <Divider
                    sx={{
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.divider.light
                          : theme.palette.divider.dark,
                    }}
                  />
                )}
              </div>
            ))}
          </Card>
        </Box>
      </Container>
    </>
  );
}

export default Settings;
