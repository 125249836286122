import { Box, Button, Dialog, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../Card/Card";
import CloseIcon from "@mui/icons-material/Close";
import PairedIcon from "../../assets/images/icons/svg/paired.svg";
import NotPairedIcon from "../../assets/images/icons/svg/notpaired.svg";
import { useState } from "react";

export default function Socials({ open, setOpen, socials, setSocials }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const [first, setfirst] = useState(true);

  const handlePair = (index) => {
    socials[index].paired = !socials[index].paired;
    setfirst(!first);
    setSocials(socials);
  };

  const handleSave = (index) => {
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        ".MuiPaper-root": {
          overflowX: "hidden",
        },
      }}
    >
      <Card
        title={t("Add Social Media Links")}
        icon={null}
        altIcon={<CloseIcon />}
        altIconOnClick={handleClose}
        sx={{ height: { xs: "100vh", sm: "100vh", md: "initial" } }}
      >
        <Box
          className="flex column"
          sx={{
            background:
              theme.palette.mode === "light"
                ? theme.palette.divider.light
                : theme.palette.divider.dark,
            padding: { xs: "14px 46px", sm: "18px 52px", md: "25px 65px" },
            gap: "11px",
            height: { xs: "100%", sm: "100%", md: "initial" },
          }}
        >
          {socials.map((social, i) => {
            return (
              <Card
                key={"se" + i}
                onClick={() => handlePair(i)}
                className="flex items-center hover"
                sx={{
                  flexDirection: "row",
                  padding: {
                    xs: "10px 18px 10px 12px",
                    sm: "12px 20px 12px 14px",
                    md: "14px 24px 14px 16px",
                  },
                  gap: "15px",
                  cursor: "pointer",
                }}
              >
                {social.icon}
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.light
                        : theme.palette.text.dark,
                    fontSize: "14px",
                  }}
                  className="typography bold"
                >
                  {social.name}
                </Typography>
                <Box
                  className="flex column center relative"
                  sx={{ ml: "auto", mr: 1, mb: 1.5 }}
                >
                  <img
                    alt="pairing"
                    src={social.paired ? PairedIcon : NotPairedIcon}
                  />
                  <Typography
                    className="typography xsm text-center"
                    sx={{
                      color: social.paired ? "#2DFD95" : "#878787",
                      position: "absolute",
                      width: "250%",
                      bottom: "-15px",
                    }}
                  >
                    {social.paired ? t("Paired") : t("Not paired")}
                  </Typography>
                </Box>
              </Card>
            );
          })}
        </Box>
        <Box className="flex column center" sx={{ padding: "18px 166px" }}>
          <Button
            onClick={handleSave}
            className="btn"
            variant="contained"
            color="primary"
            sx={{ padding: "8px 42px !important" }}
          >
            {t("Save")}
          </Button>
        </Box>
      </Card>
    </Dialog>
  );
}
