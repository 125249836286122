import { MIC_DISABLE, MIC_ENABLE } from '../types'

export const micEnableReducer = (state = true, action) =>{
  const {type} = action

  switch (type) {
    case MIC_ENABLE: {
      return true
    }
    case MIC_DISABLE: {
      return false;
    }
    default: {
      return state
    }
  }
}
