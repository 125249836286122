import { Container, Grid, Slide } from "@mui/material";
import React from "react";
import Header from "../../components/Header/Header";
import Toolbar from "../../components/Toolbar/Toolbar";
import Screen from "../../components/Screen/Screen";
import Chat from "../../components/Chat/Chat";
import { useDispatch, useSelector } from "react-redux";
import Waitings from "../../components/Sidebars/Waitings";
import { StreamContextProvider } from "../../contexts/Stream";
import {
  getParticipants,
  streamExists,
} from "../../redux/actions/sessionActions";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import WaitingsModalButton from "../../components/ModalButtons/Waitings";

function Main() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatEnabled = useSelector((state) => state.chatEnabled);
  const stream_session = useSelector((state) => state.stream_session);
  const [waitersMenuIsOpen, setWaitersMenuIsOpen] = React.useState(!isMobile);

  const session = useSelector((state) => state.session);

  const [isHost, setIsHost] = React.useState(
    stream_session?.current_participant?.is_host
  );

  React.useEffect(() => {
    setIsHost(stream_session?.current_participant?.is_host);
  }, [stream_session]);

  React.useEffect(() => {
    if (
      localStorage.getItem("access_token")?.length > 0 &&
      session.user?.is_guest &&
      !stream_session.stream_session
    ) {
      localStorage.removeItem("access_token");
      navigate("/signup");
    }
  }, [stream_session]);

  React.useEffect(() => {
    if (
      stream_session?.stream_session?.hash &&
      isHost &&
      localStorage.getItem("access_token")
    )
      dispatch(getParticipants(stream_session.stream_session.hash));

    if (
      stream_session?.stream_session?.hash &&
      !isHost &&
      localStorage.getItem("access_token")
    )
      dispatch(streamExists(stream_session.stream_session.hash));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StreamContextProvider>
      {stream_session?.stream_session?.hash &&
      stream_session?.current_participant?.status === "accepted" ? (
        <Helmet>
          <link rel="canonical" href="https://kewb.io" />
          <title>{stream_session?.stream_session.title} | Kewb</title>
          <meta
            name="description"
            content={stream_session?.stream_session.description}
          />
          <meta
            property="og:title"
            content={stream_session?.stream_session.title + " | Kewb"}
          />
          <meta
            property="og:description"
            content={stream_session?.stream_session.description}
          />
          <meta property="og:url" content="https://kewb.io" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://kewb.io/static/media/Logo-dark.8751af1a28a7b4847ee87d1300f1877d.svg"
          />
        </Helmet>
      ) : (
        <Helmet>
          <link rel="canonical" href="https://kewb.io" />
          <title>Multistream Live Videos | Kewb</title>
          <meta
            name="description"
            content={`Watch our live spy cams inside Warehouse X and follow our models’ real life as a voyeur. Hidden webcams with free preview. Come in and enjoy!`}
          />
          <meta property="og:title" content="Multistream Live Videos | Kewb" />
          <meta
            property="og:description"
            content="Kewb is an easy-to-use live video platform that enables you to simultaneously broadcast stream across your preferred channels."
          />
          <meta property="og:url" content="https://kewb.io" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://kewb.io/static/media/Logo-dark.8751af1a28a7b4847ee87d1300f1877d.svg"
          />
        </Helmet>
      )}
      {stream_session?.current_participant?.status === "accepted" ? (
        isHost ? (
          <Header />
        ) : (
          <></>
        )
      ) : (
        <Header />
      )}

      {!isMobile ? (
        <Grid
          className="wrap overflow-x-hidden"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 0 }}
          sx={{
            height:
              stream_session?.current_participant?.status === "accepted"
                ? Boolean(isHost)
                  ? "92%"
                  : "100%"
                : "92%",
          }}
        >
          {Boolean(isHost) && (
            <Waitings
              chatEnabled={chatEnabled}
              open={waitersMenuIsOpen}
              setOpen={setWaitersMenuIsOpen}
            />
          )}
          <Grid
            item
            xs={9}
            sx={{
              margin: "0 auto",
              display: "block",
              position: "relative",
              left:
                chatEnabled && isHost
                  ? "0"
                  : waitersMenuIsOpen && isHost
                  ? "22%"
                  : "12%",
              transition: "0.22s !important",
            }}
          >
            <Container
              className="screen_container"
              maxWidth="false"
              sx={{ position: "relative", height: "85%" }}
            >
              <Screen />
              <Toolbar />
            </Container>
          </Grid>

          <Slide in={chatEnabled} direction="left">
            <Grid item xs={3} sx={{ mb: 2 }}>
              {isHost && <Chat />}
            </Grid>
          </Slide>
        </Grid>
      ) : (
        <Container
          className="screen_container"
          maxWidth="false"
          sx={{ position: "relative", height: "72vh", mt: 2 }}
        >
          {Boolean(isHost) && <WaitingsModalButton />}
          <Screen />
          <Toolbar />
        </Container>
      )}
    </StreamContextProvider>
  );
}

export default Main;
