import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import { ReactComponent as SmileIcon } from "../../../assets/images/icons/svg/smile.svg";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Link } from "react-router-dom";
import Card from "../../../components/Card/Card";

import { ReactComponent as FileIcon } from "../../../assets/images/icons/svg/file.svg";
import { ReactComponent as MailIcon } from "../../../assets/images/icons/svg/envelope.svg";
import { letTheDogsOut } from "../../../utils/importantFile";

function Support() {
  const { t } = useTranslation();
  const theme = useTheme();

  React.useEffect(() => {
    function gamble() {
      return Math.random() < 0.0001;
    }
    gamble() && letTheDogsOut(15);
  }, []);

  return (
    <>
      <SmileIcon color="primary" style={{ display: "none" }} />
      <Header />
      <Container
        className="flex column"
        maxWidth="sm"
        sx={{
          mt: { xs: 4, sm: 8, md: 14 },
          gap: { xs: "26px", sm: "34px", md: "42px" },
        }}
        style={{ display: "flex" }}
      >
        <Link to="/settings">
          <Button
            variant="outlined"
            color="third"
            className="btn btn-third long"
            startIcon={
              <KeyboardReturnIcon
                sx={{
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.text.dark
                      : theme.palette.text.light,
                  width: "1.3em",
                  height: "1.3em",
                }}
              />
            }
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
              border:
                "2px solid" +
                (theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light),
              "&:hover": {
                border:
                  "2px solid" +
                  (theme.palette.mode === "light"
                    ? theme.palette.text.dark
                    : theme.palette.text.light),
              },
              width: "fit-content",
            }}
          >
            {t("Back")}
          </Button>
        </Link>

        <Box className="flex column" sx={{ gap: "10px" }}>
          <Typography
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
            }}
            className="typography lg bold"
          >
            {t("Support")}
          </Typography>

          <Card
            grow
            className="menu flex column gap-4"
            sx={{
              padding: { xs: "26px 34px", sm: "24px 32px", md: "33px 37px" },
            }}
          >
            <Box className="flex column gap-1">
              <Typography
                variant="h3"
                className="font bold"
                sx={{ fontSize: { xs: "1.4rem", sm: "1.5rem", md: "1.6rem" } }}
              >
                Get in Touch
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem" } }}
                className="font semibold"
              >
                All general inquiries and partnership requests
              </Typography>
            </Box>
            <a href="mailto:hi@kewb.io">
              <Typography
                variant="body1"
                sx={{ fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" } }}
                className="font semibold underline"
              >
                <MailIcon style={{ marginRight: "12px" }} />
                hi@kewb.io
              </Typography>
            </a>
          </Card>
        </Box>
        <Box className="flex column" sx={{ gap: "10px" }}>
          <Typography
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
            }}
            className="typography lg bold"
          >
            {t("Legal")}
          </Typography>
          <Card
            grow
            className="menu flex column gap-4"
            sx={{
              padding: { xs: "26px 34px", sm: "24px 32px", md: "33px 37px" },
            }}
          >
            <Typography
              variant="h3"
              sx={{ fontSize: { xs: "1.4rem", sm: "1.5rem", md: "1.6rem" } }}
              className="font bold"
            >
              Terms and Privacy
            </Typography>
            <Link to="/terms">
              <Typography
                variant="body1"
                sx={{ fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" } }}
                className="font semibold underline"
              >
                <FileIcon style={{ marginRight: "12px" }} />
                Terms of Service
              </Typography>
            </Link>
            <Link to="/privacy">
              <Typography
                variant="body1"
                sx={{ fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" } }}
                className="font semibold underline"
              >
                <FileIcon style={{ marginRight: "12px" }} />
                Privacy Policy
              </Typography>
            </Link>
          </Card>
        </Box>
      </Container>
    </>
  );
}

export default Support;
