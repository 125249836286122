import {
  Box,
  Button,
  Container,
  Grid,
  Grow,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import { ReactComponent as SmileIcon } from "../../../assets/images/icons/svg/smile.svg";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Link } from "react-router-dom";
import Card from "../../../components/Card/Card";
import TextFieldUI from "../../../components/UI/TextField";
import ImageUpload from "../../../components/UI/ImageUpload";
import WebCamera from "../../../components/WebCamera/WebCamera";
import { ReactComponent as Circle } from "../../../assets/images/icons/svg/circle-plus.svg";
import Socials from "../../../components/Socials/Socials";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as FacebookIcon } from "../../../assets/images/icons/svg/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/images/icons/svg/youtube.svg";
import { ReactComponent as TwitchIcon } from "../../../assets/images/icons/svg/twitch.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/images/icons/svg/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/images/icons/svg/twitter.svg";
import { isMobile } from "react-device-detect";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Backdrop from "@mui/material/Backdrop";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  API_USER_DELETE_AVATAR_ROUTE,
  API_USER_PROFILE_ROUTE,
} from "../../../utils/consts";
import { setMessage } from "../../../redux/rootReducer";
import axios from "../../../utils/axios";
import {
  userAvatarDelete,
  userUpdateProfile,
} from "../../../redux/actions/userAction";

function Account() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState(user?.username);
  const [bio, setBio] = React.useState(user?.bio);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(user?.avatar?.imageSmall);

  const [openChannels, setOpenChannels] = React.useState(false);

  const [socials, setSocials] = React.useState([
    {
      name: t("Facebook"),
      icon: <FacebookIcon />,
      paired: false,
    },
    {
      name: t("YouTube"),
      icon: <YoutubeIcon />,
      paired: false,
    },
    {
      name: t("Twitch"),
      icon: <TwitchIcon />,
      paired: false,
    },
    {
      name: t("Instagram"),
      icon: <InstagramIcon />,
      paired: false,
    },
    {
      name: t("Twitter"),
      icon: <TwitterIcon />,
      paired: false,
    },
  ]);

  const [openSocials, setOpenSocials] = React.useState(false);

  const handleOpen = () => {
    // setSearchParams({login: true});
    setOpen(true);
  };
  const handleClose = () => {
    // setSearchParams({login: false});
    setOpen(false);
  };

  const handleSocials = () => {
    setOpenSocials(true);
  };

  const handleChannels = () => {
    setOpenChannels(true);
  };

  React.useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const handleProceed = () => {
    handleSend();
  };
  const handleAvatarDelete = async () => {
    setImageUrl(null);
    if (user.avatar) {
      try {
        const res = await axios.delete(
          process.env.REACT_APP_BACKEND_URL + API_USER_DELETE_AVATAR_ROUTE,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              Accept: "application/json",
            },
          }
        );

        if (res.status === 200) {
          dispatch(userAvatarDelete());
          if (res.data?.success) {
            dispatch(setMessage(res.data.message));
          }
        } else {
          dispatch(
            setMessage(
              res.data.message ?? res.data.errors[0]?.text,
              res.data.type
            )
          );
        }
      } catch (error) {
        dispatch(setMessage(error.response?.data?.errors[0]?.text, "error"));
      }
    }
  };

  const handleSend = async () => {
    const body = { username: username, bio: bio, avatar: selectedImage };
    let formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL + API_USER_PROFILE_ROUTE,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            Accept: "application/json",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      );
      const data = res.data;
      if (data.success) {
        dispatch(userUpdateProfile(data.data.user));

        dispatch(setMessage(data.message));
      } else {
        dispatch(setMessage(data.message ?? data.errors[0]?.text, data.type));
      }
    } catch (error) {
      dispatch(setMessage(error.response?.data?.errors[0]?.text, "error"));
    }
  };
  return (
    <>
      <SmileIcon color="primary" style={{ display: "none" }} />
      <Header />
      <Container
        className="flex column"
        sx={{
          mt: { xs: 4, sm: 8, md: 8 },
          gap: { xs: "26px", sm: "34px", md: "42px" },
        }}
        style={{ display: "flex", maxWidth: "745px" }}
      >
        <Link to="/settings">
          <Button
            variant="outlined"
            color="third"
            className="btn btn-third long"
            startIcon={
              <KeyboardReturnIcon
                sx={{
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.text.dark
                      : theme.palette.text.light,
                  width: "1.3em",
                  height: "1.3em",
                }}
              />
            }
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
              border:
                "2px solid" +
                (theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light),
              "&:hover": {
                border:
                  "2px solid" +
                  (theme.palette.mode === "light"
                    ? theme.palette.text.dark
                    : theme.palette.text.light),
              },
              width: "fit-content",
            }}
          >
            {t("Back")}
          </Button>
        </Link>
        <Box className="flex column" sx={{ gap: "10px" }}>
          <Grid container sx={{ gap: "36px" }}>
            <>
              <Grid
                className="flex column"
                item
                xs={12}
                sm={5.5}
                sx={{ gap: "30px", mx: { xs: "30px", sm: "0" } }}
              >
                <Grow in>
                  <Box>
                    <Typography
                      variant="h2"
                      className="typography bold"
                      sx={{
                        mb: 1.5,
                        color:
                          theme.palette.mode === "light"
                            ? theme.palette.text.dark
                            : theme.palette.text.light,
                      }}
                    >
                      {t("Profile")}
                    </Typography>
                    <Card
                      sx={{
                        padding: "50px 24px 30px",
                        justifyContent: "start",
                      }}
                    >
                      <TextFieldUI
                        sx={{ mb: 4, mt: 1 }}
                        label={t("Username")}
                        value={username}
                        type="text"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <TextFieldUI
                        sx={{ mb: 0.5, mt: 1 }}
                        label={t("Bio")}
                        value={bio}
                        multiline={true}
                        rows={4}
                        type="text"
                        onChange={(e) => setBio(e.target.value)}
                      />
                    </Card>
                  </Box>
                </Grow>
                <Grow in>
                  <Box>
                    <Typography
                      variant="h2"
                      className="typography bold"
                      sx={{
                        mb: 1.5,
                        color:
                          theme.palette.mode === "light"
                            ? theme.palette.text.dark
                            : theme.palette.text.light,
                      }}
                    >
                      {t("Avatar")}
                    </Typography>
                    <Card sx={{ padding: "30px 60px" }}>
                      <Box
                        className="flex center relative"
                        sx={{
                          ":hover": {
                            ".MuiIconButton-root": {
                              opacity: "1",
                            },
                          },
                        }}
                      >
                        <Box sx={{ position: "relative" }}>
                          <ImageUpload
                            value={imageUrl}
                            onChange={(e) =>
                              setSelectedImage(e.target.files[0])
                            }
                          />
                          {imageUrl !== null && (
                            <IconButton
                              color="primary"
                              onClick={handleAvatarDelete}
                              aria-label={t("Delete")}
                              title={t("Delete")}
                              sx={{
                                opacity: isMobile ? "1" : "0",
                                position: "absolute",
                                top: "-5px",
                                left: "-15px",
                                transition: "all 0.2s",
                                background:
                                  theme.palette.mode === "light"
                                    ? theme.palette.paper.light
                                    : theme.palette.paper.dark,
                                ":hover": {
                                  background:
                                    theme.palette.mode === "light"
                                      ? theme.palette.paper.light
                                      : theme.palette.paper.dark,
                                  filter: "brightness(1.15)",
                                },
                              }}
                            >
                              <DeleteForeverOutlinedIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      <IconButton
                        onClick={handleOpen}
                        disableFocusRipple
                        size="small"
                        color="primary"
                        sx={{
                          position: "absolute",
                          right: "20px",
                          bottom: "10px",
                        }}
                      >
                        <CameraAltOutlinedIcon />
                      </IconButton>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                        className="modal"
                      >
                        <Box>
                          <Card
                            sx={{
                              width: { xs: "100%", sm: "100%", md: "32%" },
                              height: "100%",
                              margin: "auto",
                              padding: "0",
                              py: 3,
                            }}
                          >
                            <WebCamera
                              setImageUrl={setImageUrl}
                              handleClose={handleClose}
                              imageUrl={imageUrl}
                            />
                          </Card>
                        </Box>
                      </Modal>
                    </Card>
                  </Box>
                </Grow>
              </Grid>

              <Grid
                className="flex column"
                item
                xs={12}
                sm={5.5}
                sx={{ gap: "30px", mx: { xs: "30px", sm: "0" } }}
              >
                <Grow in>
                  <Box>
                    <Typography
                      variant="h2"
                      className="typography bold"
                      sx={{
                        mb: 1.5,
                        color:
                          theme.palette.mode === "light"
                            ? theme.palette.text.dark
                            : theme.palette.text.light,
                      }}
                    >
                      {t("Social Media")}
                    </Typography>

                    <Box className="flex column gap-7">
                      {socials?.length > 0 &&
                        socials.map((social, i) => {
                          if (social.paired)
                            return (
                              <Grow in delay={300} timeout={300} key={"sc" + i}>
                                <Box>
                                  <Card sx={{ padding: 0 }}>
                                    <Box className="flex column center">
                                      <Button
                                        onClick={handleChannels}
                                        className="no-hover rounded"
                                        fullWidth
                                        color="primary"
                                        sx={{
                                          position: "relative",
                                          padding: "24px",
                                        }}
                                      >
                                        <Box
                                          className="absolute"
                                          sx={{ left: "25px" }}
                                        >
                                          {social.icon}
                                        </Box>
                                        <Typography
                                          className="typography medium initial"
                                          sx={{
                                            fontWeight: 800,
                                            color:
                                              theme.palette.mode === "light"
                                                ? theme.palette.text.light
                                                : theme.palette.text.dark,
                                          }}
                                        >
                                          {social.name}
                                        </Typography>
                                      </Button>
                                    </Box>
                                  </Card>
                                </Box>
                              </Grow>
                            );
                        })}

                      <Card sx={{ padding: 0 }}>
                        <Box className="flex column center">
                          <Button
                            onClick={handleSocials}
                            className="no-hover rounded"
                            fullWidth
                            color="primary"
                            sx={{ position: "relative", padding: "24px" }}
                          >
                            <Circle
                              style={{
                                width: "23px",
                                height: "23px",
                                position: "absolute",
                                left: "25px",
                              }}
                              color="primary"
                            />
                            <Typography
                              className="typography medium initial"
                              sx={{
                                fontWeight: 800,
                                color:
                                  theme.palette.mode === "light"
                                    ? theme.palette.text.light
                                    : theme.palette.text.dark,
                              }}
                            >
                              {t("Add social media links")}
                            </Typography>
                          </Button>
                        </Box>
                      </Card>
                    </Box>

                    <Socials
                      open={openSocials}
                      setOpen={setOpenSocials}
                      socials={socials}
                      setSocials={setSocials}
                    />
                  </Box>
                </Grow>
              </Grid>
            </>
          </Grid>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={handleProceed}
              variant="contained"
              className="btn btn-primary xlong"
              color="primary"
              sx={{ mt: 4, mb: 4, width: "50%" }}
            >
              {t("Save")}
            </Button>
          </div>
        </Box>
      </Container>
    </>
  );
}

export default Account;
