export function letTheDogsOut(count = 1) {
  // Create a DOM element for the dog image
  // Play a barking sound
  const barkSound = new Audio(
    "https://www.myinstants.com/media/sounds/who-let-the-dogs-out-song.mp3"
  );

  const explostionSound = new Audio(
    "https://www.myinstants.com/media/sounds/tie-fighter-explode.mp3"
  );

  let duration;
  barkSound.addEventListener("loadedmetadata", () => {
    // Get the duration of the sound in seconds
    duration = Math.floor(barkSound.duration);
    console.log(`The sound duration is ${duration} seconds.`);

    console.log(duration);
    document.body.style.pointerEvents = "none";

    for (let i = 0; i < count; i++) {
      const barks = new Audio(
        "https://www.myinstants.com/media/sounds/who-let-the-dogs-out-song.mp3"
      );
      const positionTop = Math.random() * 100;
      const positionLeft = Math.random() * 100;
      const dogImage = document.createElement("img");
      dogImage.src = "https://media.tenor.com/ecQ74O7JktoAAAAC/doge.gif";
      dogImage.style.position = "fixed";
      dogImage.style.top = `${positionTop}%`;
      dogImage.style.left = `${positionLeft}%`;
      dogImage.style.transform = "translate(-50%, -50%)";
      dogImage.style.width = "300px";
      dogImage.style.height = "300px";

      // Add the dog image to the document
      document.body.appendChild(dogImage);
      barks.volume = 0.05;
      barks.play();

      // Add a glowing effect to the dog image
      let glowIntensity = 0;
      const glowInterval = setInterval(() => {
        glowIntensity = (glowIntensity + 1) % 360;
        dogImage.style.filter = `hue-rotate(${glowIntensity}deg)`;
      }, 10);

      // Add a VFX effect to the dog image
      const vfxImage = document.createElement("img");
      vfxImage.src =
        "https://i.gifer.com/origin/8b/8b1b3a0e7a2ff5d84a2248c70a68df19_w200.gif";
      vfxImage.style.position = "fixed";
      vfxImage.style.top = `${positionTop}%`;
      vfxImage.style.left = `${positionLeft}%`;
      vfxImage.style.transform = "translate(-50%, -50%)";
      vfxImage.style.width = "600px";
      vfxImage.style.height = "600px";
      vfxImage.style.opacity = "0";
      document.body.appendChild(vfxImage);

      const vfxImage2 = document.createElement("img");
      vfxImage2.src =
        "https://media.tenor.com/rmGoaS8S8CAAAAAj/mlg-deal-with-it.gif";
      vfxImage2.style.position = "fixed";
      vfxImage2.style.top = `${positionTop}%`;
      vfxImage2.style.left = `${positionLeft}%`;
      vfxImage2.style.transform = "translate(-50%, -50%)";
      vfxImage2.style.width = "300px";
      vfxImage2.style.height = "300px";
      vfxImage2.style.opacity = "0";
      document.body.appendChild(vfxImage2);

      // Fade in and out the VFX image
      let vfxOpacity = 0;
      const vfxInterval = setInterval(() => {
        vfxOpacity = Math.sin(Date.now() / 500) * 0.5 + 0.5;
        vfxImage.style.opacity = vfxOpacity;
        vfxImage2.style.opacity = vfxOpacity;
      }, 10);

      // Rotate the dog image
      let dogRotation = 0;
      const rotationInterval = setInterval(() => {
        dogRotation = (dogRotation + Math.floor(Math.random() * 31)) % 360;
        dogImage.style.transform = `translate(-50%, -50%) rotate(${dogRotation}deg)`;
      }, 10);

      // Remove the created images and stop the effects when the sound finishes playing
      barks.onended = () => {
        console.log("1");
        clearInterval(glowInterval);
        clearInterval(vfxInterval);
        clearInterval(rotationInterval);
        document.body.removeChild(dogImage);
        document.body.removeChild(vfxImage);
        document.body.removeChild(vfxImage2);
        document.body.style.pointerEvents = null;
      };
    }
    // Create the countdown element
    const countdownEl = document.createElement("div");
    countdownEl.id = "countdown";
    countdownEl.style.position = "fixed";
    countdownEl.style.top = "50%";
    countdownEl.style.left = "50%";
    countdownEl.style.transform = "translate(-50%, -50%)";
    countdownEl.style.zIndex = "100000000";
    document.body.appendChild(countdownEl);

    // Set the countdown duration (in seconds)
    let vfxExp;
    // Start the countdown
    let timeLeft = duration;
    let countdownInterval = setInterval(() => {
      // Update the countdown element
      countdownEl.textContent = timeLeft;

      // Reset the effect on each tick
      void countdownEl.offsetWidth;

      // Reduce the font size and opacity
      countdownEl.style.fontSize = `${5 - timeLeft / duration}rem`;
      countdownEl.style.opacity = timeLeft / duration;

      // Check if the countdown has finished
      if (timeLeft <= 0) {
        // Clear the interval and hide the countdown element
        countdownEl.style.display = "none";
        document.body.removeChild(countdownEl);
        clearInterval(countdownInterval);
        document.body.removeChild(vfxExp);
      }
      if (timeLeft == 1) {
        vfxExp = document.createElement("img");
        vfxExp.src =
          "https://media.tenor.com/ptNG8DQFPD4AAAAj/explotion-explode.gif";
        vfxExp.style.position = "fixed";
        vfxExp.style.top = `50%`;
        vfxExp.style.left = `50%`;
        vfxExp.style.transform = "translate(-50%, -50%)";
        vfxExp.style.width = "900px";
        vfxExp.style.height = "900px";
        vfxExp.style.opacity = "1";
        document.body.appendChild(vfxExp);
        explostionSound.play();
        explostionSound.play();
        explostionSound.play();
      }

      // Decrement the time left
      timeLeft--;
    }, 1000);
  });
}
