import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../Card/Card";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, useTheme, Divider } from "@mui/material";
import InputField from "../UI/InputField";
import { useDispatch } from "react-redux";
import { createNewSession } from "../../redux/actions/sessionActions";
import { setMessage } from "../../redux/rootReducer";

export default function StreamSettings({ altOnClick }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [category, setCategory] = React.useState("");

  const handleSave = () => {
    // if (!title || title === "") {
    //   dispatch(setMessage("Title is not specified", "error"));
    //   return;
    // }
    altOnClick();
    dispatch(
      createNewSession({
        title: title,
        description: description,
        category: category,
      })
    );
  };

  return (
    <Card
      title={t("Stream Settings")}
      icon={<SettingsIcon color="primary" />}
      altIcon={<CloseIcon />}
      altIconOnClick={altOnClick}
    >
      <Box
        className="flex column"
        sx={{
          background:
            theme.palette.mode === "light"
              ? theme.palette.divider.light
              : theme.palette.divider.dark,
        }}
      >
        <InputField
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          label={t("Title")}
          sx={{ padding: "0 0 0 65px" }}
        />

        <Divider
          sx={{
            color:
              theme.palette.mode === "light"
                ? theme.palette.divider.light
                : theme.palette.divider.dark,
          }}
        />

        <InputField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label={t("Description")}
          optional
          sx={{ padding: "0 0 0 65px" }}
        />

        <Divider
          sx={{
            color:
              theme.palette.mode === "light"
                ? theme.palette.divider.light
                : theme.palette.divider.dark,
          }}
        />

        <InputField
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          label={t("Category")}
          optional
          sx={{ padding: "0 0 0 65px" }}
        />
      </Box>
      <Box className="flex column center" sx={{ padding: "18px 166px" }}>
        <Button
          onClick={handleSave}
          className="btn"
          variant="contained"
          color="primary"
          sx={{ padding: "4px 42px !important" }}
        >
          {t("Save")}
        </Button>
      </Box>
    </Card>
  );
}
