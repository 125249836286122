import React from 'react';
import { TextField, Box, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import { withTheme } from '@mui/styles';

const StyledTextField = styled(withTheme(TextField))(props => ({
    backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey.light : props.theme.palette.grey.dark,
    color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark,
    width: '100%',
    'input': {
        color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark
    },
    'textarea': {
        color: props.theme.palette.mode === 'light' ? props.theme.palette.text.light : props.theme.palette.text.dark
    }
}));

const StyledFormControl = styled(withTheme(FormControl))(props => ({
    'label': {
        color: props.theme.palette.mode === 'light' ? props.theme.palette.label.light : props.theme.palette.label.dark,
        fontFamily: "Gilroy SemiBold  !important",
        padding: 'inherit'
    },
    'fieldset': {
        border: '0'
    }
}));

export default function InputField({ placeholder, type, onChange, value, icon, sx, style, label, multiline, rows, required, optional }) {
    if (!onChange) {
        return <></>;
    }
    return (
        <Box sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', left: '-30px', top: '0', bottom: '0', transform: 'translateY(37%)' }}>
                {icon}
            </Box>
            <StyledFormControl variant="standard" sx={{ width: '100%' }} label={label}>
                <InputLabel htmlFor="input-with-icon-textfield">{label}</InputLabel>
                <StyledTextField
                    className="TextFieldUI font medium"
                    id="input-with-icon-textfield"
                    sx={sx}
                    style={style}
                    type={type}
                    label={label + (optional ? ' (Optional)' : '')}
                    required={required}
                    rows={rows}
                    multiline={multiline}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                />
            </StyledFormControl>
        </Box>
    )
}
