import { Avatar, IconButton } from "@mui/material";
import React, { useRef } from "react";

export default function ImageUpload({ value, onChange, width, height }) {
  const inputRef = useRef();

  const handleClick = () => {
    inputRef?.current?.click();
  };

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        disableFocusRipple
        onClick={handleClick}
      >
        <Avatar
          src={value}
          sx={{
            width: width ?? { xs: 68, sm: 78, md: 97 },
            height: height ?? { xs: 68, sm: 78, md: 97 },
          }}
        />
      </IconButton>
      <input
        accept="image/*"
        id="contained-button-file"
        type="file"
        style={{ display: "none" }}
        onChange={onChange}
        ref={inputRef}
      />
    </>
  );
}
