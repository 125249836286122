import { CAM_ENABLE, CAM_DISABLE, CHAT_ENABLE, CHAT_DISABLE, MIC_ENABLE, MIC_DISABLE } from "../types"

export const cameraEnable = () => {
    return {
        type: CAM_ENABLE
    }
}

export const cameraDisable = () => {
    return {
        type: CAM_DISABLE
    }
}

export const chatEnable = () => {
    return {
        type: CHAT_ENABLE
    }
}

export const chatDisable = () => {
    return {
        type: CHAT_DISABLE
    }
}

export const micEnable = () => {
    return {
        type: MIC_ENABLE
    }
}

export const micDisable = () => {
    return {
        type: MIC_DISABLE
    }
}