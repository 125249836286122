import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import { ReactComponent as PersonSeatIcon } from "../../assets/images/icons/svg/person-seat.svg";
import { useDispatch, useSelector } from "react-redux";
import { participant } from "../../redux/actions/sessionActions";
export default function Waitings({ open, setOpen, chatEnabled }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const stream_session = useSelector((state) => state.stream_session);
  const awaiters = useSelector((state) => state.awaiters);
  const [acceptAll, setAcceptAll] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAcceptAll = () => {
    console.log("accept");
  };

  const handleApprove = (e, user) => {
    console.log("approve");
    dispatch(participant("accept", stream_session?.stream_session?.hash, user));
    handleClose();
  };
  const handleDecline = (e, user) => {
    console.log("decline");
    dispatch(
      participant("decline", stream_session?.stream_session?.hash, user)
    );
    handleClose();
  };
  const handleKick = (e, user) => {
    console.log("kick");
    dispatch(participant("kick", stream_session?.stream_session?.hash, user));
    handleClose();
  };
  const handleBan = (e, user) => {
    console.log("ban");
    dispatch(participant("ban", stream_session?.stream_session?.hash, user));
    handleClose();
  };

  const handleCheckbox = (e) => {
    setAcceptAll(e.target.checked);
  };

  return (
    <Box
      className="flex column absolute"
      sx={{
        backgroundColor: "#15042B",
        minWidth: "17vw",
        height: "92vh",
        transition: "0.2s all",
        transform:
          open && !chatEnabled ? "translateX(0px)" : "translateX(-16.5vw)",
      }}
    >
      <Box
        className="flex column relative"
        sx={{ px: 4, pt: 3, pb: 2, gap: 4 }}
      >
        <Grow in={!chatEnabled}>
          <Box
            className="absolute"
            sx={{
              top: "0.25vw",
              right: open && !chatEnabled ? "-0.9vw" : "-2vw",
            }}
          >
            <IconButton
              onClick={() => setOpen(!open)}
              color="white"
              variant="contained"
              sx={{
                backgroundColor: "#fff",
                color: "#000",
                width: "2vw",
                height: "2vw",
                "&:hover": { backgroundColor: "#d9d9d9" },
              }}
            >
              {open ? (
                <ChevronLeftRoundedIcon
                  sx={{ width: "1.25vw", height: "1.25vw" }}
                />
              ) : (
                <MenuRoundedIcon sx={{ width: "1.25vw", height: "1.25vw" }} />
              )}
            </IconButton>
          </Box>
        </Grow>

        <Box className="flex items-center" sx={{ gap: 1 }}>
          <PersonSeatIcon style={{ fill: theme.palette.fourth.main }} />
          <Typography
            className="typography semibold"
            sx={{ color: theme.palette.fourth.main }}
          >
            Waiting in the lobby
          </Typography>
          {awaiters.length > 0 && (
            <span
              className="font semibold flex center"
              style={{
                width: 24,
                height: 24,
                borderRadius: "50%",
                backgroundColor: theme.palette.fourth.main,
              }}
            >
              {awaiters.length}
            </span>
          )}
        </Box>

        <Box
          className="flex items-center"
          sx={{ justifyContent: "space-between" }}
        >
          {/* <FormControlLabel
            className="font semibold"
            sx={{
              color: theme.palette.mode === "light" ? "#fff" : "#fff",
              "& .MuiTypography-root": {
                fontFamily: "inherit",
                fontSize: "14px",
              },
            }}
            control={
              <Checkbox
                checked={acceptAll}
                color="white"
                onChange={handleCheckbox}
                sx={{
                  svg: {
                    fill: "#fff",
                  },
                }}
              />
            }
            label="Auto-accept"
          />
          <Button
            className="font bold"
            variant="contained"
            color="white"
            onClick={handleAcceptAll}
            sx={{
              borderRadius: "10px",
              fontSize: "14px",
              textTransform: "unset",
              maxHeight: "30px",
            }}
          >
            Accept All
          </Button> */}
        </Box>
      </Box>
      <List sx={{ color: "#fff" }}>
        {awaiters.map((user, i) => (
          <Box key={"w" + i}>
            <ListItem
              aria-controls={openMenu ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={handleClick}
              sx={{
                backgroundColor: i % 2 !== 1 && "#210F39",
                py: 1.25,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor:
                    i % 2 !== 1
                      ? "rgba(53, 35, 77, 1) !important"
                      : "rgba(61, 44, 83, 0.8) !important",
                },
              }}
            >
              <ListItemAvatar>
                <Avatar sx={{ width: 30, height: 30 }} src={user.avatar} />
              </ListItemAvatar>
              <ListItemText
                className="font semibold"
                primary={user.participant_name}
                primaryTypographyProps={{
                  fontSize: "14px",
                  fontFamily: "inherit",
                }}
              />
            </ListItem>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "10px",
                },
                "& .MuiList-root": {
                  py: 0,
                },
                "& svg": {
                  color: "#D3D3D3",
                  width: "18px",
                  height: "18px",
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "rgba(240,240,240,1) !important",
                },
              }}
            >
              <MenuItem
                className="flex items-center font bold"
                sx={{ gap: 1, fontSize: "14px", px: 2, py: 1.5 }}
                onClick={(e) => handleApprove(e, user)}
              >
                <CheckIcon /> <span>Approve</span>
              </MenuItem>
              {/* <MenuItem
                className="flex items-center font bold"
                sx={{
                  gap: 1,
                  fontSize: "14px",
                  px: 2,
                  py: 1.5,
                  borderTop: "1px solid #EDEDED",
                  borderBottom: "1px solid #EDEDED",
                }}
                onClick={(e) => handleDecline(e, user)}
              >
                <CloseRoundedIcon /> <span>Decline</span>
              </MenuItem> */}
              {/* <MenuItem
                className="flex items-center font bold"
                sx={{ gap: 1, fontSize: "14px", px: 2, py: 1.5 }}
                onClick={(e) => handleKick(e, user)}
              >
                <RemoveCircleOutlineIcon /> <span>Kick</span>
              </MenuItem> */}
              <MenuItem
                className="flex items-center font bold"
                sx={{ gap: 1, fontSize: "14px", px: 2, py: 1.5 }}
                onClick={(e) => handleBan(e, user)}
              >
                <BlockRoundedIcon /> <span>Ban</span>
              </MenuItem>
            </Menu>
          </Box>
        ))}
      </List>
    </Box>
  );
}
