import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Logo from "../../assets/images/Logo.svg";
import LogoDark from "../../assets/images/Logo-dark.svg";
import { useDispatch, useSelector } from "react-redux";
import { SwitchModeButton } from "../../components/UI/SwitchModeButton";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { ReactComponent as ShareIcon } from "../../assets/images/icons/svg/share2.svg";

import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import twitch from "../../assets/images/icons/svg/twitch.svg";
import facebook from "../../assets/images/icons/svg/facebook.svg";
import instagram from "../../assets/images/icons/svg/instagram.svg";
import youtube from "../../assets/images/icons/svg/youtube.svg";
import Channels from "../Channels/Channels";
import StreamSettings from "../StreamSettings/StreamSettings";
import ShareLink from "../ShareLink/ShareLink";
import {
  SessionDelete,
  removeAwaiters,
} from "../../redux/actions/sessionActions";
import { isMobile } from "react-device-detect";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
export default function MobileView() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElSession, setAnchorElSession] = React.useState(null);
  const [anchorElShare, setAnchorElShare] = React.useState(null);
  const session = useSelector((state) => state.session);
  const navigate = useNavigate();
  const [openChannels, setOpenChannels] = React.useState(false);

  const { t } = useTranslation();

  const settings = React.useMemo(
    () => [
      {
        icon: <ManageAccountsOutlinedIcon color="primary" />,
        name: t("Account Settings"),
        link: "/settings",
      },
      {
        icon: <ContactSupportOutlinedIcon color="primary" />,
        name: t("Support"),
        link: "/settings/support",
      },
      {
        icon: <LogoutOutlinedIcon color="primary" />,
        name: t("Logout"),
        link: "/logout",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleChannels = () => {
    setOpenChannels(true);
  };
  const handleStartSession = (event) => {
    setAnchorElSession(event.currentTarget);
  };
  const handleCloseStartSession = () => {
    setAnchorElSession(null);
  };
  const handleStopSession = (event) => {
    dispatch(removeAwaiters());
    dispatch(SessionDelete());
    window.location.reload();
  };
  const handleShareLinkOpen = (event) => {
    setAnchorElShare(event.currentTarget);
  };
  const handleShareLinkClose = () => {
    setAnchorElShare(null);
  };

  const stream_session = useSelector((state) => state.stream_session);
  if (session?.user)
    return (
      <AppBar
        position="static"
        enableColorOnDark
        sx={{
          backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
        }}
      >
        <Toolbar disableGutters sx={{ px: 2, minHeight: 40 }}>
          <Box className="flex center">
            {/* Mobile */}

            {/* Burger Menu */}
            {/* <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              color: theme.palette.mode === "light" ? "#000" : "#fff",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              {Boolean(anchorElNav) ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: "block",
              }}
            >
              {settings.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link to={page.link}>
                    <Button
                      key={page.name}
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        mx: 1,
                        fontSize: "13px",
                        color: "white",
                        display: "block",
                        fontWeight: "bold",
                      }}
                    >
                      {page.name}
                    </Button>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

            {/* Mobile */}
            <Link to="/">
              {/* LOGO */}
              <Avatar
                sx={{
                  height: "100%",
                  width: "60px",
                }}
                variant="square"
                src={theme.palette.mode === "light" ? LogoDark : Logo}
              />
            </Link>

            <Box sx={{ flexGrow: 0 }}>
              <Box
                className="flex items-center"
                id="channels"
                sx={{ gap: "17px" }}
              >
                {/* <Channels open={openChannels} setOpen={setOpenChannels} /> */}
                <Box className="flex items-center">
                  {stream_session?.current_participant?.is_host && (
                    <>
                      <Button
                        variant="text"
                        disableElevation
                        disableRipple
                        sx={{ ml: 1 }}
                        onClick={handleShareLinkOpen}
                        startIcon={
                          <ShareIcon style={{ width: 18, height: 18 }} />
                        }
                        className="btn no-hover"
                      >
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === "light"
                                ? theme.palette.text.light
                                : theme.palette.text.dark,
                            fontSize: { xs: "0.85rem", sm: "0.9rem" },
                          }}
                          className="typography bold capitalize"
                        >
                          {t("Share")}
                        </Typography>
                      </Button>
                      <Menu
                        sx={{ mt: "65px" }}
                        id="menu-appbar"
                        anchorEl={anchorElShare}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        className="menu"
                        open={Boolean(anchorElShare)}
                        onClose={handleShareLinkClose}
                      >
                        <ShareLink altOnClick={handleShareLinkClose} />
                      </Menu>
                    </>
                  )}

                  <Button
                    onClick={
                      stream_session?.current_participant?.status ===
                        "accepted" && stream_session?.stream_session?.is_active
                        ? handleStopSession
                        : handleStartSession
                    }
                    color="primary"
                    variant="contained"
                    className="btn short"
                    sx={{
                      ml: stream_session?.current_participant?.is_host ? 0 : 2,
                      borderRadius: "8px !important",
                    }}
                  >
                    {stream_session?.current_participant?.status ===
                      "accepted" && stream_session?.stream_session?.is_active
                      ? t("Stop")
                      : t("Start")}
                  </Button>

                  <Menu
                    sx={{ mt: "65px" }}
                    id="menu-appbar"
                    anchorEl={anchorElSession}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    className="menu"
                    open={Boolean(anchorElSession)}
                    onClose={handleCloseStartSession}
                  >
                    <StreamSettings altOnClick={handleCloseStartSession} />
                  </Menu>
                </Box>
              </Box>
            </Box>
            {/* Actions */}
          </Box>

          <Box className="flex center" sx={{ gap: "20px", ml: "auto" }}>
            {/* User */}
            <Box sx={{ flexGrow: 0 }}>
              <Stack className="flex center" direction="row" spacing={2}>
                <Box
                  className="flex column content-center"
                  sx={{ position: "relative" }}
                >
                  <Typography
                    color="primary"
                    className="typography bold"
                    sx={{
                      fontSize: {
                        xs: "0.8rem",
                        sm: "0.85rem",
                      },
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "65px",
                      height: "1.2em",
                      whiteSpace: "nowrap",
                      "@media (min-width: 400px)": {
                        maxWidth: "90px",
                      },
                      "@media (min-width: 450px)": {
                        maxWidth: "115px",
                      },
                      "@media (min-width: 500px)": {
                        maxWidth: "135px",
                      },
                      "@media (max-width: 350px)": {
                        maxWidth: "30px",
                      },
                    }}
                  >
                    {session?.user?.username ?? session?.user?.email}
                  </Typography>
                  <Typography className="typography xsm uppercase badge">
                    {session?.user?.package ?? "Basic"}
                  </Typography>
                </Box>
                <Avatar
                  className="cursor"
                  sx={{ width: 27, height: 27 }}
                  onClick={handleOpenUserMenu}
                  alt={session?.user?.username + " avatar"}
                  src={session?.user?.avatar?.imageSmall}
                />
              </Stack>
              <Menu
                sx={{
                  mt: "65px",
                  "& .MuiMenuItem-root": {
                    backgroundColor:
                      theme.palette.mode === "light" ? "#F8F8F8" : "#121212",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#d5d5d5" : "#2b2b2b",
                    },
                  },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                className="menu"
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, i) => (
                  <div key={"s" + i}>
                    <Link to={setting.link}>
                      <MenuItem
                        onClick={handleCloseUserMenu}
                        className="typography bold capitalize"
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? theme.palette.text.light
                              : theme.palette.text.dark,
                          minWidth: "231px",
                          padding: "17px 20px",
                        }}
                      >
                        {setting.icon}
                        &emsp;{setting.name}
                      </MenuItem>
                    </Link>
                    {settings.length !== i + 1 && (
                      <Divider
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? theme.palette.divider.dark
                              : theme.palette.divider.light,
                        }}
                      />
                    )}
                  </div>
                ))}
                <MenuItem
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? "#dddddd !important"
                        : "#1c1c1c !important",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? "#cccccc !important"
                          : "#4b4b4b !important",
                    },
                  }}
                >
                  <SwitchModeButton />
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    );
}
