import {
  Alert,
  Box,
  Fade,
  Grow,
  IconButton,
  Input,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../../redux/actions/messageAction";
import LockIcon from "@mui/icons-material/Lock";
import SendIcon from "@mui/icons-material/Send";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import { useTranslation } from "react-i18next";
import { setMessage } from "../../redux/rootReducer";
import { SendRounded } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

var timer = null;
export default function InputField({ setChatIsTimedOut, chatIsTimedOut }) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const stream_session = useSelector((state) => state.stream_session);
  const { t } = useTranslation();

  const inputMessage = React.useRef();

  const [text, setText] = React.useState("");

  // Config
  const messagesLimit = 2 - 1;
  const disableChatInSeconds = 5;

  // chat messages limiting here
  const [canWrite, setCanWrite] = React.useState(true);
  const [msgsInSecondsLimit, setMsgsInSecondsLimit] = React.useState(0);
  const [chatTimer, setChatTimer] = React.useState(disableChatInSeconds);
  const [chatMsgStarted, setChatMsgStarted] = React.useState(false);
  const [chatId, setChatId] = React.useState(
    stream_session?.stream_session?.chat?.virtualId
  );
  // Chat timer
  React.useEffect(() => {
    if (stream_session?.stream_session?.chat?.virtualId) {
      setChatId(stream_session.stream_session.chat.virtualId);
    }
    return () => {};
  }, [stream_session]);

  function CheckMessageTimeout() {
    // if counter reaches limit in seconds
    if (msgsInSecondsLimit > messagesLimit && canWrite) {
      //Unable to write -> wait N seconds
      setCanWrite(false);
      setChatIsTimedOut(true);
      setTimeout(() => {
        //after wait N seconds, set to able
        setCanWrite(true);
        setChatIsTimedOut(false);
        setMsgsInSecondsLimit(0);
      }, disableChatInSeconds * 1000);
    } else if (canWrite) {
      //add 1 counter
      setMsgsInSecondsLimit(msgsInSecondsLimit + 1);
      if (!chatMsgStarted) {
        setChatMsgStarted(true);
        setTimeout(() => {
          setChatMsgStarted(false);
          setMsgsInSecondsLimit(0);
        }, 1000);
      }
    }
  }

  // Chat timer
  React.useEffect(() => {
    if (chatIsTimedOut) {
      if (timer === null) {
        setChatTimer(disableChatInSeconds);
        let seconds = disableChatInSeconds - 1;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = setInterval(() => {
          setChatTimer(seconds--);
        }, 1000);
      }
    } else {
      clearInterval(timer);
      timer = null;
    }
    return () => {
      clearTimeout(timer);
      timer = null;
    };
  }, [chatIsTimedOut]);

  const handleSetText = React.useCallback((e) => {
    const limit = 255;
    const length = e.target.value.length;
    if (length > limit) {
      setText(e.target.value.slice(0, limit));
      return;
    }
    setText(e.target.value);
  }, []);

  function handleOnEnter(e) {
    if (e.code === "Enter" || e.key === "Enter" || e.keyCode === "13") {
      if (text === "") return;
      if (!chatId) {
        dispatch(setMessage("Error. Chat is not found.", "error"));
      }
      const limit = 255;
      if (text.length > limit) {
        setText(text.slice(0, limit));
      }

      CheckMessageTimeout();

      if (!canWrite) return;

      dispatch(sendMessage(text, chatId));
      scrollToBottom();
      setText("");
    }
  }

  function handleOnSend(e) {
    e.preventDefault();

    if (text === "") return;
    if (!chatId) {
      dispatch(setMessage("Error. Chat is not found.", "error"));
    }
    const limit = 255;

    if (text.length > limit) {
      setText(text.slice(0, limit));
    }

    CheckMessageTimeout();

    if (!canWrite) return;

    dispatch(sendMessage(text, chatId));
    scrollToBottom();
    setText("");
  }

  function scrollToBottom() {
    const chatElem = document.getElementById("ChatList");
    if (!chatElem) return;
    setTimeout(() => {
      chatElem.scrollTop = chatElem.scrollHeight;
    }, 5);
  }

  return (
    <Box
      className="flex items-center"
      sx={{
        gap: "15px",
        padding: { xs: "12px 16px 6px", sm: "16px 16px 12px" },
      }}
    >
      {chatIsTimedOut && (
        <Fade in={true}>
          <Box
            style={{
              position: "absolute",
              bottom: "55px",
              left: "5px",
              right: "5px",
            }}
          >
            <Alert severity="warning">
              {t("You are sending messages too quickly.")}
            </Alert>
          </Box>
        </Fade>
      )}

      <Grow in={!chatIsTimedOut}>
        <Typography
          style={
            text.length >= 250
              ? { color: theme.palette.primary.light }
              : { color: "#6e6e6e" }
          }
          sx={{
            fontSize: {
              xs: "0.8rem !important",
              sm: "0.85rem !important",
              md: "0.9rem !important",
            },
          }}
          className={
            !chatIsTimedOut ? "chat-text-info" : "chat-text-info timedOut"
          }
        >
          <LockIcon
            style={{
              width: isMobile ? "0.7em" : "0.75em",
              marginRight: "3px",
              marginTop: "-2px",
            }}
          />
          {text.length}
          {"/255"}
        </Typography>
      </Grow>
      {chatIsTimedOut && (
        <Typography
          style={{
            color: "#6e6e6e",
          }}
          className="flex center"
          sx={{
            marginLeft: { xs: "5px", sm: "10px", md: "15px" },
            minWidth: { xs: "30px", sm: "35px", md: "40px" },
            fontSize: {
              xs: "0.85rem !important",
              sm: "0.9rem !important",
            },
          }}
        >
          <LockIcon
            style={{
              width: isMobile ? "0.75em" : "0.85em",
              marginRight: "3px",
              marginTop: "-3px",
            }}
          />
          {chatTimer}
        </Typography>
      )}
      <TextsmsOutlinedIcon
        sx={{
          color: theme.palette.mode === "light" ? "#5D5D5D" : "#5D5D5D",
        }}
      />
      <Input
        type="text"
        disableUnderline
        inputRef={inputMessage}
        value={text}
        onChange={(e) => handleSetText(e)}
        onKeyUpCapture={(e) => handleOnEnter(e)}
        maxLength={255}
        className={"input"}
        autoFocus={true}
        placeholder="Type your message here..."
        style={{ fontSize: "14px" }}
      />
      <IconButton
        onClick={handleOnSend}
        aria-label="close"
        className="btn"
        color="primary"
        sx={{
          position: "absolute",
          bottom: { xs: "unset", sm: "10px" },
          right: "8px",
        }}
      >
        <SendRounded sx={{ width: "1em" }} />
      </IconButton>
    </Box>
  );
}
