import { CAM_DISABLE, CAM_ENABLE } from '../types'

export const camEnableReducer = (state = true, action) =>{
  const {type} = action

  switch (type) {
    case CAM_ENABLE: {
      return true
    }
    case CAM_DISABLE: {
      return false;
    }
    default: {
      return state
    }
  }
}
