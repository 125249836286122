import React from "react";
import { Button, useTheme } from "@mui/material";
import DarkIcon from "@mui/icons-material/Brightness4";
import { Brightness4 as LightIcon } from "@mui/icons-material";
import { ColorContext } from "../../themes/ColorContext";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useTranslation } from "react-i18next";

export const SwitchModeButton = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const colorMode = React.useContext(ColorContext);
  return (
    <Button
      className="typography bold"
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "start",
        gap: 1.5,
        color:
          theme.palette.mode === "light"
            ? theme.palette.text.light
            : theme.palette.text.dark,
        "&:hover": {
          backgroundColor: "initial",
        },
        textTransform: "capitalize",
      }}
      onClick={colorMode.toggleColorMode}
    >
      {theme.palette.mode === "dark" ? <LightModeIcon /> : <DarkIcon />}
      {theme.palette.mode === "light" ? "dark" : "light"} {t("mode")}
    </Button>
  );
};
