export const USER = "USER";
export const USER_UPDATE = "USER_UPDATE";

export const ERROR = "ERROR";
export const ERRORS = "ERRORS";

export const ERROR_REMOVE = "ERROR_REMOVE";
export const ERRORS_REMOVE = "ERRORS_REMOVE";

export const MODAL_ENABLE = "MODAL_ENABLE";
export const MODAL_DISABLE = "MODAL_DISABLE";

export const TAB = "TAB";
export const TAB_REMOVE = "TAB_REMOVE";

export const MESSAGES = "MESSAGES";
export const CHAT = "CHAT";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SET_MESSAGES = "SET_MESSAGES";
export const ADD_MESSAGE_TO_MESSAGES = "ADD_MESSAGE_TO_MESSAGES";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

export const MESSAGE = "MESSAGE";
export const MESSAGE_REMOVE = "MESSAGE_REMOVE";
export const MESSAGES_REMOVE = "MESSAGES_REMOVE";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

export const SET_LOADINGS = "SET_LOADINGS";
export const REMOVE_LOADINGS = "REMOVE_LOADINGS";

export const MARK_MESSAGE_AS_SEEN = "MARK_MESSAGE_AS_SEEN";

/////////////// NEW CONSTS

export const LANGUAGE = "LANGUAGE";

export const CHAT_ENABLE = "CHAT_ENABLE";
export const CHAT_DISABLE = "CHAT_DISABLE";

export const CAM_ENABLE = "CAM_ENABLE";
export const CAM_DISABLE = "CAM_DISABLE";

export const MIC_ENABLE = "MIC_ENABLE";
export const MIC_DISABLE = "MIC_DISABLE";

export const SESSION_CREATE = "SESSION_CREATE";
export const SESSION_ACCEPTED = "SESSION_ACCEPTED";
export const SESSION_DELETE = "SESSION_DELETE";

export const PARTICIPANTS = "PARTICIPANTS";
export const PARTICIPANTS_ADD_ONE = "PARTICIPANTS_ADD_ONE";
export const PARTICIPANTS_REMOVE = "PARTICIPANTS_REMOVE";
export const PARTICIPANTS_REMOVE_ONE = "PARTICIPANTS_REMOVE_ONE";

export const USER_AVATAR_DELETE = 'USER_AVATAR_DELETE'

export const AWAITERS = "AWAITERS";
export const AWAITERS_ADD_ONE = "AWAITERS_ADD_ONE";
export const AWAITERS_REMOVE = "AWAITERS_REMOVE";
export const AWAITERS_REMOVE_ONE = "AWAITERS_REMOVE_ONE";