import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import banner1 from '../../assets/images/banners/banner1.png'

export default function Banner({ title, content }) {
    const theme = useTheme();
    return (
        <Box className="flex column content-center"
            sx={{
                backgroundImage: 'url(' + banner1 + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                padding: '20px 50px',
                minHeight: '104px',
                borderRadius: '10px',
                gap: '2px',
                width: '100%'
            }}>
            <Typography
                variant='h1'
                className="typography bold"
                sx={{
                    padding: { xs: '0 30px', sm: 'unset' },
                    textAlign: { xs: 'center', sm: 'unset', md: "unset", lg: "unset" },
                    color: theme.palette.mode === 'light' ? theme.palette.text.dark : theme.palette.text.light,
                }}
            >
                {title}
            </Typography>
            <Typography
                className="typography"
                sx={{
                    padding: { xs: '0 30px', sm: 'unset' },
                    textAlign: { xs: 'center', sm: 'unset', md: "unset", lg: "unset" },
                    color: theme.palette.mode === 'light' ? theme.palette.text.dark : theme.palette.text.light
            }}
            >
                {content}
            </Typography>
        </Box>
    )
}
