import { Grid } from "@mui/material";
import React from "react";
import Header from "../../components/Header/Header";

function Page404() {
  return (
    <>
      <Header />

      <Grid
        className="wrap overflow-x-hidden"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 0 }}
        sx={{ height: "92%" }}
      >
        404
      </Grid>
    </>
  );
}

export default Page404;
