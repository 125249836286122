const access_token = sessionStorage.getItem("access_token");

export const headers_auth = {
    'Content-Type': 'application/json',
    "Accept": "application/json",
    'Authorization': `Bearer ` + access_token
}
export const headers = {
    'Content-Type': 'application/json',
    "Accept": "application/json",
}