import {
  Box,
  Typography,
  useTheme,
  Button,
  Grid,
  IconButton,
  Modal,
  Avatar,
  Skeleton,
  Grow,
} from "@mui/material";
import React from "react";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import Card from "../../components/Card/Card";
import TextFieldUI from "../../components/UI/TextField";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ImageUpload from "../../components/UI/ImageUpload";
import { isMobile } from "react-device-detect";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import WebCamera from "../../components/WebCamera/WebCamera";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Backdrop from "@mui/material/Backdrop";
import { handleUserData } from "../../redux/actions/userAction";
import {
  SessionCreate,
  SessionDelete,
} from "../../redux/actions/sessionActions";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Triangle from "../../assets/images/icons/svg/triangle-exclamation-solid.svg";
import { ReactComponent as PersonSeatIcon } from "../../assets/images/icons/svg/person-seat.svg";
import { API_STREAM } from "../../utils/consts";
import { setMessage } from "../../redux/rootReducer";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import Loader from "../../components/Loader/Loader";

function Join() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.session.user);
  const stream_session = useSelector((state) => state.stream_session);
  const slug = useParams();
  const [IsRedirecting, setIsRedirecting] = React.useState(false);
  const [GrowEffect, setGrowEffect] = React.useState(true);

  React.useEffect(() => {
    if (stream_session?.current_participant?.status === "accepted") {
      setIsRedirecting(true);
      setTimeout(() => {
        setGrowEffect(false);
        setTimeout(() => {
          navigate("/");
        }, 300);
      }, 900);
      return;
    }
    dispatch(handleUserData);
    return () => {
      if (stream_session?.current_participant?.status !== "accepted") {
        dispatch(SessionDelete);
      }
    };
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("access_token")) {
      setTimeout(() => {
        getSession(slug);
      }, 50);
    }
  }, [localStorage.getItem("access_token")]);

  const [pending, setPending] = React.useState(false);

  const [error, setError] = React.useState({
    email: null,
    username: null,
  });

  const getSession = async (item) => {
    setPending(true);
    try {
      const res = await axios.get(
        process.env.REACT_APP_BACKEND_URL + API_STREAM + item.slug,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ` + localStorage.getItem("access_token"),
          },
        }
      );
      if (res.data) {
        if (res.data.errors) {
          dispatch(setMessage(res.data.errors[0].text, "error"));
        } else {
          dispatch(SessionCreate(res.data.data));
        }
        //dispatch(setMessage(data.message));
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setPending(false);
    }
  };

  const [username, setUsername] = React.useState(
    user?.username ? user.username : ""
  );
  const [bio, setBio] = React.useState(user?.bio ? user.bio : "");
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(
    user?.avatar?.imageSmall ? user.avatar.imageSmall : null
  );
  const [open, setOpen] = React.useState(false);

  const [fetching, setFetching] = React.useState(false);

  const [sentJoinRequest, setSentJoinRequest] = React.useState(false);

  const handleOpen = () => {
    // setSearchParams({login: true});
    setOpen(true);
  };
  const handleClose = () => {
    // setSearchParams({login: false});
    setOpen(false);
  };

  const deleteImage = () => {
    setImageUrl(null);
  };

  React.useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  console.log(selectedImage);

  const handleDecline = () => {
    setSentJoinRequest(false);
  };

  const handleProceed = () => {
    let usernameRequired = false;
    if (username === "" || !username) {
      usernameRequired = true;
    }
    let BioRequired = false;
    if (BioRequired || usernameRequired) {
      setError({
        bio: BioRequired ? t("This field is required") : null,
        username: usernameRequired ? t("This field is required") : null,
      });
      return;
    }
    joinSession(slug.slug);
  };

  const joinSession = async (slug) => {
    setFetching(true);
    try {
      const formData = new FormData();
      // Update the formData object
      formData.append("display_name", username);
      formData.append("bio", bio);
      if (selectedImage)
        formData.append("avatar", selectedImage, selectedImage?.name);
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL + API_STREAM + slug + "/join",
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ` + localStorage.getItem("access_token"),
          },
        }
      );
      if (res.data?.type === "success") {
        dispatch(SessionCreate(res.data.data));

        //Save socket token for future connections to the given room.
        localStorage.setItem("core_api_token", res.data.data.chat_token);
        setSentJoinRequest(true);
        //dispatch(setMessage(data.message));
        //window.location.href = `/session/${slug}`;
      }

      if (res.data?.type === "error") {
        dispatch(setMessage(res.data.errors[0].text, "error"));
      }
    } catch (error) {
      setMessage(error, "error");
    } finally {
      setFetching(false);
    }
  };

  return (
    <Box
      className={"flex column center" + (isMobile ? "" : " fullscreen")}
      sx={{
        backgroundColor: "#1E0640",
        p: isMobile && "15px",
      }}
    >
      <Box className="flex column" sx={{ gap: isMobile ? "30px" : "63px" }}>
        <Box
          className="flex"
          sx={{
            width: "100%",
            gap: "13px",
            alignItems: "center",
            justifyContent: "space-between",
            px: { xs: "15px", sm: "15px", md: "0" },
          }}
        >
          <Link to="/">
            <Logo style={{ width: isMobile && 60 }} />
          </Link>

          <Typography
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
            }}
            className="typography semibold"
          >
            Invited
          </Typography>
        </Box>
        {IsRedirecting ? (
          <Grow in={GrowEffect} timeout={300}>
            <Box>
              <Card
                className="margin"
                sx={{
                  margin: { xs: "0 20px", sm: "0", md: "0", lg: "0" },
                }}
              >
                <Loader />
              </Card>
            </Box>
          </Grow>
        ) : (
          <Grid
            style={{
              display: !isMobile && "grid",
              gridTemplateColumns: !isMobile && "1fr 1fr",
            }}
          >
            <Grid
              item
              sm={6}
              xs={12}
              style={{
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: isMobile ? "100vw" : "450px",
              }}
              sx={{
                padding: { xs: "0 15px", sm: "0 15px", md: "0" },
              }}
            >
              {!isMobile && (
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.dark
                        : theme.palette.text.light,
                  }}
                  className="typography lg bold"
                >
                  {t("Your Profile")}
                </Typography>
              )}
              <Card
                sx={{
                  padding: { xs: "20px 30px", sm: "30px 60px" },
                  gap: "20px",
                  borderRadius: isMobile
                    ? "10px 10px 0px 0px"
                    : "10px 0px 0px 10px",
                }}
              >
                {!sentJoinRequest ? (
                  // Sending request for approval
                  <Grow in>
                    <Box className="flex column">
                      <Box
                        className="flex start relative"
                        sx={{
                          ":hover": {
                            ".MuiIconButton-root": {
                              opacity: "1",
                            },
                          },
                          alignItems: isMobile && "center",
                        }}
                      >
                        <ImageUpload
                          value={imageUrl}
                          onChange={(e) => setSelectedImage(e.target.files[0])}
                        />
                        {imageUrl !== null && (
                          <IconButton
                            color="primary"
                            onClick={() => deleteImage()}
                            aria-label={t("Delete")}
                            title={t("Delete")}
                            sx={{
                              opacity: isMobile ? "1" : "0",
                              position: "absolute",
                              top: "-5px",
                              left: {
                                xs: "-20px",
                              },
                              transition: "all 0.2s",
                              background:
                                theme.palette.mode === "light"
                                  ? theme.palette.paper.light
                                  : theme.palette.paper.dark,
                              ":hover": {
                                background:
                                  theme.palette.mode === "light"
                                    ? theme.palette.paper.light
                                    : theme.palette.paper.dark,
                                filter: "brightness(1.15)",
                              },
                            }}
                          >
                            <DeleteForeverOutlinedIcon
                              fontSize={isMobile ? "small" : "medium"}
                            />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={handleOpen}
                          disableFocusRipple
                          size="small"
                          color="primary"
                          sx={{
                            position: "absolute",
                            left: {
                              xs: "55px",
                              sm: "65px",
                              md: "75px",
                            },
                            bottom: "0px",
                          }}
                        >
                          <CameraAltOutlinedIcon
                            fontSize={isMobile ? "small" : "medium"}
                          />
                        </IconButton>
                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          open={open}
                          onClose={handleClose}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                            timeout: 500,
                          }}
                          className="modal"
                        >
                          <Box>
                            <Card
                              sx={{
                                width: isMobile ? "100%" : "32%",
                                height: "100%",
                                margin: "auto",
                                padding: "0",
                                py: 3,
                              }}
                            >
                              <WebCamera
                                setImageUrl={setImageUrl}
                                handleClose={handleClose}
                                imageUrl={imageUrl}
                              />
                            </Card>
                          </Box>
                        </Modal>
                        {isMobile && (
                          <Box sx={{ mb: "-30px", width: "100%", pl: "15px" }}>
                            <TextFieldUI
                              sx={{ mb: 2.5, mt: 0.5 }}
                              label={t("Display name")}
                              value={username}
                              type="text"
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{ paddingTop: "10px", mt: 3, mb: isMobile && 2 }}
                      >
                        {!isMobile && (
                          <TextFieldUI
                            sx={{ mb: 4, mt: 1 }}
                            label={t("Display name")}
                            value={username}
                            type="text"
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        )}
                        <TextFieldUI
                          sx={{ mb: 0.5, mt: 1 }}
                          label={
                            <div>
                              {t("Bio") + " "}
                              <Box
                                sx={{
                                  display: "inline",
                                  color: "#A7A7A7",
                                  fontSize: {
                                    xs: "0.8rem",
                                    sm: "0.9rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                {t("(Optional)")}
                              </Box>
                            </div>
                          }
                          value={bio}
                          multiline={true}
                          rows={4}
                          type="text"
                          onChange={(e) => setBio(e.target.value)}
                        />
                      </Box>

                      <LoadingButton
                        onClick={handleProceed}
                        variant="contained"
                        loading={fetching}
                        // disabled={!stream_session?.stream_session?.host?.username}
                        className="btn btn-primary xlong"
                        color="primary"
                        sx={{
                          mt: 0.5,
                          mb: 2,
                          mx: isMobile && "auto",
                          width: isMobile ? "200px" : "100%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("Join the Lobby")}
                      </LoadingButton>
                    </Box>
                  </Grow>
                ) : (
                  // Request is sent, waiting for approval
                  <Grow in>
                    <Box className="flex column">
                      <Box
                        className="flex column center relative"
                        sx={{ minHeight: "50vh", gap: 3 }}
                      >
                        <Avatar src={imageUrl} sx={{ width: 97, height: 97 }} />
                        <Typography className="typography semibold">
                          {username}
                          <span
                            style={{ marginLeft: "8px", color: "lightgrey" }}
                          >
                            <EditIcon fontSize="small" />
                          </span>
                        </Typography>
                        <Box
                          className="flex column center"
                          sx={{ position: "absolute", bottom: "0", gap: 1 }}
                        >
                          <Box
                            className="flex items-center"
                            sx={{
                              backgroundColor: theme.palette.fourth.main,
                              textTransform: "unset",
                              boxShadow: "unset",
                              borderRadius: "10px",
                              px: 2,
                              py: 1,
                            }}
                          >
                            <span style={{ marginRight: "12px" }}>
                              <PersonSeatIcon />
                            </span>
                            <Typography className="typography semibold">
                              Waiting for host to approve
                            </Typography>
                          </Box>
                          <Button
                            className="font bold"
                            variant="text"
                            color="white"
                            onClick={handleDecline}
                            sx={{
                              textDecoration: "underline",
                              textTransform: "unset",
                              color: "#AAAAAA",
                            }}
                          >
                            Decline
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grow>
                )}
              </Card>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              style={{
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: isMobile ? "100vw" : "450px",
              }}
              sx={{
                padding: { xs: "0 15px", sm: "0 15px", md: "0" },
              }}
            >
              {!isMobile && (
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.dark
                        : theme.palette.text.light,
                  }}
                  className="typography lg bold"
                >
                  {t("Meeting & Host")}
                </Typography>
              )}
              <Card
                sx={{
                  padding: { xs: "20px 30px", sm: "30px 60px" },
                  gap: "20px",
                  borderRadius: {
                    xs: "0px 0px 10px 10px",
                    sm: "0px 0px 10px 10px",
                    md: "0px 10px 10px 0px",
                  },
                  backgroundColor: "#15042B",
                  height: "94.6%",
                  flexDirection: { xs: "row", sm: "row", md: "column" },
                }}
              >
                <Box className="flex">
                  {pending ? (
                    <Skeleton
                      variant="rounded"
                      height={97}
                      width={97}
                      sx={{
                        bgcolor: "rgba(255,255,255,0.15)",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    stream_session?.stream_session?.host?.avatar && (
                      <Avatar
                        sx={{
                          mt: { xs: 2, sm: 2, md: 0 },
                          width: { xs: 58, sm: 78, md: 97 },
                          height: { xs: 58, sm: 78, md: 97 },
                        }}
                        src={
                          stream_session?.stream_session?.host?.avatar
                            ?.imageMedium
                        }
                      />
                    )
                  )}
                </Box>
                <Box
                  sx={{
                    paddingTop: "10px",
                    marginTop: { xs: "0", sm: "0", md: "30px" },
                    gap: "20px",
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <Grid>
                    {pending ? (
                      <Skeleton
                        variant="text"
                        height={40}
                        sx={{ bgcolor: "rgba(255,255,255,0.15)" }}
                      />
                    ) : (
                      stream_session?.stream_session?.host && (
                        <>
                          <Typography
                            variant="h2"
                            className="typography bold"
                            sx={{ color: theme.palette.fourth.main }}
                          >
                            Host
                          </Typography>
                          <Typography
                            className="typography"
                            sx={{ color: "#fff" }}
                          >
                            {stream_session?.stream_session?.host?.username ??
                              "Guest"}
                          </Typography>
                        </>
                      )
                    )}
                  </Grid>
                  <Grid>
                    {pending ? (
                      <Skeleton
                        variant="text"
                        height={40}
                        sx={{ bgcolor: "rgba(255,255,255,0.15)" }}
                      />
                    ) : (
                      stream_session?.stream_session?.title && (
                        <>
                          <Typography
                            variant="h2"
                            className="typography bold"
                            sx={{ color: theme.palette.fourth.main }}
                          >
                            Meeting
                          </Typography>
                          <Typography
                            className="typography"
                            sx={{ color: "#fff" }}
                          >
                            {stream_session?.stream_session?.title}
                          </Typography>
                        </>
                      )
                    )}
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                gap: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                maxWidth: isMobile ? "100vw" : "450px",
                paddingTop: "20px",
              }}
              sx={{ px: isMobile && "15px", mt: isMobile && "10px" }}
            >
              <Avatar
                className="warning"
                alt="Cindy Baker"
                sx={{ borderRadius: "unset" }}
                src={Triangle}
              />
              <Typography
                className="typography"
                fontSize={14}
                sx={{
                  lineHeight: "1.4 !important",
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.text.dark
                      : theme.palette.text.light,
                }}
              >
                {t(
                  "Do not share your Credit Card or any Personal details if you are unsure about the host!"
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={12}
              style={{
                gap: !isMobile && "45px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: isMobile ? "space-evenly" : "flex-end",
                width: "100%",
                maxWidth: isMobile ? "100vw" : "450px",
              }}
              sx={{
                mt: isMobile && "30px",
                mb: isMobile && "50px",
              }}
            >
              <Link
                to="/terms"
                style={{
                  width: "fit-content",
                  marginRight: !isMobile && "10px",
                }}
              >
                <Typography
                  className="typography"
                  fontSize={14}
                  sx={{
                    whiteSpace: "nowrap",
                    // color: "white",
                    textDecoration: "underline",
                  }}
                  style={{ color: "white", textAlign: isMobile && "center" }}
                >
                  {t("Terms of Service")}
                </Typography>
              </Link>
              <Link
                to="/privacy"
                style={{
                  width: "fit-content",
                  marginRight: !isMobile && "40px",
                }}
              >
                <Typography
                  className="typography"
                  fontSize={14}
                  sx={{
                    whiteSpace: "nowrap",
                    // color: "white",
                    textDecoration: "underline",
                  }}
                  style={{ color: "white", textAlign: isMobile && "center" }}
                >
                  {t("Privacy")}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(Join);
