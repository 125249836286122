import React from 'react'
import { Box, Button, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Card from '../Card/Card';
import CloseIcon from '@mui/icons-material/Close';
import DropZone from '../DropZone/DropZone';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

export default function DropFile({ setOpen, open }) {

    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        handleClose();
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <Card title={t('Drop file')} icon={<UploadFileOutlinedIcon color="primary" />} altIcon={<CloseIcon />} altIconOnClick={handleClose}>
                <Box className='flex column' sx={{ padding: '0 22px', gap: '16px' }}>

                    <DropZone />

                    <Button
                        onClick={handleSave}
                        className="btn"
                        variant="contained"
                        color="primary"
                        sx={{ padding: '4px 42px !important', width: 'fit-content', alignSelf: 'center', mb: 3 }}
                    >
                        {t('Submit')}
                    </Button>
                </Box>
            </Card>
        </Dialog>
    )
}
