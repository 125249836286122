import {
  Container,
  Grow,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";

import Card from "../../components/Card/Card";
import { KeyboardArrowUpRounded } from "@mui/icons-material";
import BackButton from "../../components/UI/BackButton";

const scrollTop = 1000;

function Privacy() {
  const { t } = useTranslation();
  const [showScrollToBottom, setShowScrollToBottom] = React.useState(false);

  const handleScrollButtonClick = (event) => {
    scrollOnce();
    setTimeout(() => {
      setShowScrollToBottom(false);
    }, 1);
  };

  const handleScroll = (event) => {
    if (document.documentElement.scrollTop > scrollTop) {
      setShowScrollToBottom(true);
    } else {
      setShowScrollToBottom(false);
    }
  };

  const scrollOnce = React.useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const theme = useTheme();

  return (
    <>
      <Header />
      <Container
        className="flex column"
        maxWidth="lg"
        sx={{ mt: { xs: 4, sm: 8, md: 12 }, pb: 7 }}
        style={{ display: "flex" }}
      >
        <Grow in={showScrollToBottom}>
          <IconButton
            onClick={handleScrollButtonClick}
            sx={{
              position: "fixed",
              bottom: 30,
              right: 50,
              color: "#000",
              backgroundColor: theme.palette.primary.main,
              zIndex: 5,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <KeyboardArrowUpRounded />
          </IconButton>
        </Grow>
        <BackButton sx={{ mb: 4 }} />
        <Card
          sx={{
            h1: { fontSize: { xs: "1.8rem", sm: "2.5rem" }, mt: 2 },
            h2: {
              fontSize: { xs: "1.4rem", sm: "1.8rem" },
              fontWeight: "bold",
              mt: 5,
            },
            h3: {
              fontSize: { xs: "1.2rem", sm: "1.3rem" },
              fontWeight: "bold",
              mt: 3,
            },
            h4: {
              fontSize: { xs: "1.15rem", sm: "1.15rem" },
              fontWeight: "bold",
              mt: 3,
            },
            "& p": {
              mt: 2,
              lineHeight: { xs: "1.5 !important", sm: "1.4 !important" },
              overflowWrap: "break-word",
            },

            ol: {
              listStyleType: "lower-alpha",
              ml: 2,
            },
            p: { xs: "30px 30px", sm: "45px 60px" },
          }}
        >
          <Typography variant="h1" className="font bold">
            KEWB PRIVACY POLICY
          </Typography>
          <Typography variant="h2" className="font medium">
            Introduction
          </Typography>
          <Typography variant="h3" className="font medium">
            1.1. Overview
          </Typography>
          <Typography className="typography">
            This Privacy Policy outlines the practices of Kewb Labs OÜ ("we,"
            "us," or "our") concerning the collection, use, and disclosure of
            personal information in connection with the Kewb platform, a
            multichannel live streaming web app, and its associated mobile app
            (collectively referred to as the "platform"). Protecting the privacy
            of our users ("you" or "your") is of utmost importance to us. This
            Privacy Policy is designed to inform you about our practices and to
            help you make informed decisions about the use of our platform.
          </Typography>
          <Typography variant="h3" className="font medium">
            1.2. Scope
          </Typography>
          <Typography className="typography">
            This Privacy Policy applies to personal information collected
            through the platform, including information provided by users during
            account creation, content submission, and any other interaction with
            the platform. By using the platform, you agree to the collection,
            use, and disclosure of your personal information in accordance with
            this Privacy Policy. If you do not agree with this Privacy Policy,
            please do not use the platform.
          </Typography>
          <Typography variant="h2" className="font medium">
            Collection of Personal Information
          </Typography>
          <Typography variant="h3" className="font medium">
            2.1. Information Provided by Users
          </Typography>
          <Typography className="typography">
            When you create an account or use the Kewb platform, we may collect
            personal information that you voluntarily provide to us, such as
            your name, email address, username, avatar image, and any optional
            bio information. We may also collect payment information, such as
            credit card details, for the processing of subscription fees.
            However, we do not store credit card information on our servers, as
            payments are processed by a secure third-party payment processor.
          </Typography>
          <Typography variant="h3" className="font medium">
            2.2. Information Collected Automatically
          </Typography>
          <Typography className="typography">
            When you use the Kewb platform, we may automatically collect certain
            information about your device, usage, and interactions with the
            platform. This information may include your IP address, browser
            type, device type, operating system, access times, pages viewed, and
            interactions with other users or content on the platform. We may use
            cookies, web beacons, or other tracking technologies to collect this
            information.
          </Typography>
          <Typography variant="h4" className="font medium">
            2.2.1. Google Analytics
          </Typography>
          <Typography className="typography">
            We use Google Analytics, a web analytics service provided by Google,
            to help us understand how users interact with the Kewb platform.
            Google Analytics collects information such as the pages you visit,
            time spent on the platform, and the referring website or search
            terms that led you to the platform. This information is aggregated
            and anonymized to protect your privacy. Google Analytics uses
            cookies to collect this information, and the information generated
            by the cookies is transmitted to and stored by Google on servers in
            the United States. You can learn more about Google Analytics' data
            practices by visiting their Privacy Policy at
            https://policies.google.com/privacy.
          </Typography>
          <Typography variant="h3" className="font medium">
            2.3. Managing Cookies and Tracking Technologies
          </Typography>
          <Typography className="typography">
            This information helps us understand user preferences, improve the
            platform's functionality, and provide a better user experience. You
            may be able to disable the collection of certain information through
            your browser or device settings, such as by managing your cookie
            preferences or opting out of Google Analytics tracking. To opt out
            of Google Analytics tracking, you can use Google's Analytics Opt-out
            Browser Add-on, available at
            https://tools.google.com/dlpage/gaoptout. Note that disabling
            certain tracking technologies may affect the functionality of the
            platform.
          </Typography>

          <Typography variant="h2" className="font medium">
            Use and Disclosure of Personal Information
          </Typography>
          <Typography component="h3" className="typography">
            3.1. Purposes of Use
          </Typography>
          <ul>
            <li>
              <Typography component="p" className="typography">
                a. Providing, maintaining, and improving the Kewb platform and
                its services.
              </Typography>
            </li>
            <li>
              <Typography component="p" className="typography">
                b. Personalizing your experience on the platform by customizing
                content, recommendations, and user interactions.
              </Typography>
            </li>
            <li>
              <Typography component="p" className="typography">
                c. Processing payments for subscription packages and other
                services.
              </Typography>
            </li>
            <li>
              <Typography component="p" className="typography">
                d. Communicating with you about updates, promotions, or other
                information related to the platform and its services.
              </Typography>
            </li>
            <li>
              <Typography component="p" className="typography">
                e. Responding to your inquiries, feedback, or concerns.
              </Typography>
            </li>
            <li>
              <Typography component="p" className="typography">
                f. Enhancing the security and integrity of the platform.
              </Typography>
            </li>
            <li>
              <Typography component="p" className="typography">
                g. Complying with legal requirements, court orders, or other
                requests from law enforcement authorities.
              </Typography>
            </li>
          </ul>
          <Typography component="h3" className="typography">
            3.2. Sharing with Third Parties
          </Typography>
          <ul>
            <li>
              <Typography component="p" className="typography">
                a. Third-party service providers: We may engage third-party
                service providers to perform functions on our behalf, such as
                payment processing, data storage, or analytics. These service
                providers may access your personal information only to perform
                their specific tasks and are obligated to protect your
                information in accordance with applicable laws and regulations.
              </Typography>
            </li>
            <li>
              <Typography component="p" className="typography">
                b. Business transfers: In the event of a merger, acquisition,
                sale of assets, or other business transactions, we may transfer
                your personal information to the relevant parties involved in
                the transaction, subject to applicable privacy protections.
              </Typography>
            </li>
            <li>
              <Typography component="p" className="typography">
                c. Legal compliance: We may disclose your personal information
                when required by law, regulation, or legal process, or when
                necessary to protect our rights, property, or the safety of our
                users or others.
              </Typography>
            </li>
          </ul>
          <Typography component="h3" className="typography">
            3.3. Legal and Law Enforcement Requests
          </Typography>
          <Typography component="p" className="typography">
            We may disclose your personal information to comply with legal or
            regulatory requirements, such as responding to a subpoena, court
            order, or other lawful requests from law enforcement or regulatory
            authorities. We reserve the right to cooperate with law enforcement
            in any investigation involving potential illegal activities on the
            platform or the violation of our Terms of Service.
          </Typography>
          <Typography component="h2" className="typography">
            Data Security and Retention
          </Typography>
          <Typography component="h3" className="typography">
            4.1. Security Measures
          </Typography>
          <Typography component="p" className="typography">
            We take the security of your personal information seriously and
            implement various technical, physical, and administrative measures
            to protect it from unauthorized access, disclosure, alteration, or
            destruction. These measures may include encryption, secure data
            storage, access controls, and security training for our employees.
            However, no method of data transmission or storage is completely
            secure, and we cannot guarantee the absolute security of your
            personal information. We encourage you to protect your account by
            using a strong password and keeping it confidential.
          </Typography>

          <Typography component="h3" className="typography">
            4.2. Data Retention Period
          </Typography>
          <Typography component="p" className="typography">
            We retain your personal information for as long as necessary to
            fulfill the purposes for which it was collected, comply with legal
            obligations, resolve disputes, or enforce our agreements. The
            specific retention period for your personal information may depend
            on various factors, such as the nature of the information, the
            purpose of its collection, and our legal obligations. When your
            personal information is no longer required, we will securely delete
            or anonymize it in accordance with our data retention policies and
            applicable laws and regulations.
          </Typography>

          <Typography variant="h2" className="font medium">
            User Rights and Control
          </Typography>

          <Typography variant="h3" className="font medium">
            5.1. Access, Modification, and Deletion
          </Typography>

          <Typography variant="body1" className="typography">
            You have the right to access, modify, or delete your personal
            information stored on the Kewb platform. You can review and update
            your account information, such as your name, email address,
            username, and avatar image, through the account settings or user
            profile section of the platform. If you wish to delete your account
            and the associated personal information, please contact our customer
            support team or follow the account deletion process provided within
            the platform.
          </Typography>

          <Typography variant="h3" className="font medium">
            5.2. Objection and Restriction of Processing
          </Typography>

          <Typography variant="body1" className="typography">
            In certain circumstances, you may have the right to object to or
            restrict the processing of your personal information. For example,
            you may object to the use of your personal information for direct
            marketing purposes or request the restriction of processing if you
            believe the information is inaccurate or no longer necessary for the
            purposes it was collected. To exercise these rights, please contact
            our customer support team with a detailed explanation of your
            request.
          </Typography>

          <Typography variant="h3" className="font medium">
            5.3. Withdrawal of Consent
          </Typography>

          <Typography variant="body1" className="typography">
            Where the processing of your personal information is based on your
            consent, you have the right to withdraw your consent at any time.
            The withdrawal of consent does not affect the lawfulness of the
            processing based on consent before its withdrawal. To withdraw your
            consent, please contact our customer support team or follow the
            relevant instructions provided within the platform. Note that
            withdrawing your consent may affect your access to certain features
            or services of the platform.
          </Typography>

          <Typography variant="h2" className="font medium">
            Third-Party Services and Links
          </Typography>

          <Typography variant="h3" className="font medium">
            6.1. Integration of Third-Party Services
          </Typography>

          <Typography variant="body1" className="typography">
            The Kewb platform may integrate with third-party services to provide
            additional features, functionality, or content. These third-party
            services may collect, use, and disclose your personal information in
            accordance with their own privacy policies and practices. We
            encourage you to review the privacy policies of any third-party
            services you choose to use or interact with on the platform.
          </Typography>

          <Typography variant="h3" className="font medium">
            6.2. Links to External Websites
          </Typography>

          <Typography variant="body1" className="typography">
            The Kewb platform may contain links to external websites, including
            those of our partners, advertisers, or other third parties. When you
            click on these links, you will be directed to the respective
            website, which may have its own privacy policy and practices. We are
            not responsible for the content, privacy policies, or practices of
            any external websites and encourage you to review their privacy
            policies before providing any personal information.
          </Typography>

          <Typography variant="h3" className="font medium">
            6.3. Liability for Third-Party Services and Links
          </Typography>

          <Typography variant="body1" className="typography">
            Kewb Labs OÜ is not responsible or liable for the privacy practices,
            policies, or actions of any third parties, including those providing
            third-party services or operating external websites linked to from
            the Kewb platform. We encourage you to exercise caution and review
            the privacy policies of any third-party services or websites you
            interact with through the platform.
          </Typography>

          <Typography variant="h2" className="font medium">
            International Data Transfers
          </Typography>

          <Typography variant="body1" className="typography">
            As Kewb Labs OÜ operates globally, your personal information may be
            transferred to, stored, or processed in countries other than your
            country of residence. These countries may have different data
            protection laws and regulations than those in your country. We take
            appropriate measures to ensure that your personal information is
            treated securely and in accordance with this Privacy Policy and
            applicable data protection laws, regardless of the country in which
            it is processed.
          </Typography>

          <Typography variant="body1" className="typography">
            When transferring your personal information across borders, we may
            use standard contractual clauses approved by the relevant
            authorities, seek your consent, or rely on other legal mechanisms to
            ensure the lawful transfer of data. By using the Kewb platform, you
            consent to the transfer, storage, and processing of your personal
            information in countries outside your country of residence, as
            described in this Privacy Policy.
          </Typography>

          <Typography variant="h2" className="font medium">
            Children's Privacy
          </Typography>

          <Typography variant="body1" className="typography">
            The Kewb platform is not intended for use by children under the age
            of 13, and we do not knowingly collect personal information from
            children under 13 years of age. If we become aware that we have
            inadvertently collected personal information from a child under 13,
            we will take appropriate measures to promptly delete such
            information from our records.
          </Typography>
          <Typography variant="body1" className="typography">
            If you are a parent or guardian and believe that your child has
            provided personal information to us without your consent, please
            contact our customer support team. We will take steps to verify your
            identity and, if necessary, delete any personal information related
            to the child from our records.
          </Typography>

          <Typography variant="h2" className="font medium">
            Changes to the Privacy Policy
          </Typography>

          <Typography variant="h3" className="font medium">
            9.1. Updates and Modifications
          </Typography>

          <Typography variant="body1" className="typography">
            We may update or modify this Privacy Policy from time to time to
            reflect changes in our data collection, usage, or disclosure
            practices, or to comply with applicable laws and regulations. When
            we make changes to the Privacy Policy, we will revise the "last
            updated" date at the beginning of the policy and notify you through
            the platform or via email, as appropriate.
          </Typography>

          <Typography variant="h3" className="font medium">
            9.2. Continued Use of the Platform
          </Typography>
          <Typography variant="body1" className="typography">
            Your continued use of the Kewb platform after any changes to the
            Privacy Policy constitutes your acceptance of the updated policy. If
            you do not agree with the changes, you should discontinue your use
            of the platform and delete your account.
          </Typography>
          <Typography variant="h3" className="font medium">
            9.3. Notification of Changes
          </Typography>
          <Typography variant="body1" className="typography">
            We encourage you to periodically review this Privacy Policy to stay
            informed about our privacy practices and how we protect your
            personal information. If we make any significant changes to the
            Privacy Policy that materially affect our collection, use, or
            disclosure of your personal information, we will notify you through
            the platform, by email, or by other means, as appropriate.
          </Typography>

          <Typography variant="h2" className="font medium">
            Contact Information and Support
          </Typography>
          <Typography variant="h3" className="font medium">
            10.1. Customer Support
          </Typography>
          <Typography variant="body1" className="typography">
            If you have any questions, concerns, or requests related to this
            Privacy Policy, your personal information, or the Kewb platform,
            please contact our customer support team. We will make every effort
            to address your inquiries and resolve any issues you may have.
          </Typography>
          <Typography variant="h3" className="font medium">
            10.2. Feedback and Suggestions
          </Typography>
          <Typography variant="body1" className="typography">
            We welcome your feedback and suggestions for improving our privacy
            practices and the Kewb platform. If you have any ideas or
            recommendations, please feel free to share them with our customer
            support team.
          </Typography>
          <Typography variant="h3" className="font medium">
            10.3. Contact Information
          </Typography>
          <Typography variant="body1" className="typography">
            You can reach our customer support team and direct any
            privacy-related inquiries to the following contact information:
          </Typography>
          <Typography variant="body1" className="typography">
            Email: hi@kewb.io
          </Typography>
          <Typography variant="h2" className="font medium">
            GDPR Data Protection Rights
          </Typography>
          <Typography variant="h3" className="font medium">
            11.1. Right of Access
          </Typography>
          <Typography variant="body1" className="typography">
            You have the right to request a copy of the personal information we
            hold about you. We will provide you with this information in a
            structured, commonly used, and machine-readable format, subject to
            verifying your identity.
          </Typography>
          <Typography variant="h3" className="font medium">
            11.2. Right to Rectification
          </Typography>
          <Typography variant="body1" className="typography">
            You have the right to request the correction of any inaccurate or
            incomplete personal information we hold about you. We will make the
            necessary corrections promptly upon receiving your request, subject
            to verifying your identity.
          </Typography>
          <Typography variant="h3" className="font medium">
            11.3. Right to Erasure (Right to be Forgotten)
          </Typography>
          <Typography variant="body1" className="typography">
            You have the right to request the deletion of your personal
            information when it is no longer necessary for the purposes for
            which it was collected or when you have withdrawn your consent for
            its processing, subject to our legal obligations, data retention
            policies, and verifying your identity.
          </Typography>
          <Typography variant="h3" className="font medium">
            11.4. Right to Restriction of Processing
          </Typography>
          <Typography variant="body1" className="typography">
            You have the right to request the restriction of the processing of
            your personal information in certain situations, such as when you
            contest the accuracy of the data or when you have objected to the
            processing based on our legitimate interests.
          </Typography>
          <Typography variant="h3" className="font medium">
            11.5. Right to Data Portability
          </Typography>
          <Typography variant="body1" className="typography">
            You have the right to request the transfer of your personal
            information to another organization or directly to you, in a
            structured, commonly used, and machine-readable format, when it is
            technically feasible.
          </Typography>

          <Typography variant="h3" className="font medium">
            11.6. Right to Object
          </Typography>
          <Typography variant="body1" className="typography">
            You have the right to object to the processing of your personal
            information based on our legitimate interests or for direct
            marketing purposes. We will cease processing your personal
            information unless we can demonstrate compelling legitimate grounds
            for the processing that override your interests, rights, and
            freedoms, or if the processing is necessary for the establishment,
            exercise, or defense of legal claims.
          </Typography>

          <Typography variant="h3" className="font medium">
            11.7. Right to Withdraw Consent
          </Typography>
          <Typography variant="body1" className="typography">
            Where the processing of your personal information is based on your
            consent, you have the right to withdraw your consent at any time,
            without affecting the lawfulness of processing based on consent
            before its withdrawal.
          </Typography>
          <Typography variant="body1" className="typography">
            To exercise any of these GDPR data protection rights, please contact
            our customer support team at hi@kewb.io. We will respond to your
            request within one month, as required by the GDPR, and may ask you
            to provide additional information to verify your identity. If you
            are unsatisfied with our response or believe that our processing of
            your personal information does not comply with the GDPR, you have
            the right to lodge a complaint with a supervisory authority in the
            EU or EEA.
          </Typography>
        </Card>
      </Container>
    </>
  );
}

export default Privacy;
