import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../Card/Card";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  useTheme,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";
import { ReactComponent as InstaIcon } from "../../assets/images/socials/dark/inst.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/socials/dark/fb.svg";
import { ReactComponent as TwitIcon } from "../../assets/images/socials/dark/twit.svg";
import { ReactComponent as TelegIcon } from "../../assets/images/socials/dark/teleg.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/icons/svg/link-solid.svg";
import { setMessage } from "../../redux/rootReducer";

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

export default function ShareLink({ altOnClick }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const stream_session = useSelector((state) => state.stream_session);
  const [link, setLink] = React.useState(
    window.location.href + "join/" + stream_session?.stream_session?.hash
  );

  const handleShare = (type) => {
    if (type === "instagram") {
    } else if (type === "telegram") {
      window.open(
        `https://telegram.me/share/url?url=${link}&text=${link}`,
        "_blank"
      );
    } else if (type === "facebook") {
      window.open(`https://www.facebook.com/sharer.php?u=${link}`, "_blank");
    } else if (type === "twitter") {
      window.open(
        `https://twitter.com/intent/tweet?url=${link}&text=${link}`,
        "_blank"
      );
    }
  };

  const handleCopy = () => {
    copyTextToClipboard(link);
    dispatch(setMessage("Link copied to clipboard"));
  };

  return (
    <Card
      title={t("Share")}
      icon={<ShareIcon color="primary" />}
      altIcon={<CloseIcon />}
      altIconOnClick={altOnClick}
      sx={{ minWidth: { xs: "unset", sm: "414px" } }}
    >
      <Box
        className="flex column"
        sx={{
          px: 4,
          pb: 4,
          pt: 5,
          gap: 4,
          background:
            theme.palette.mode === "light"
              ? theme.palette.divider.light
              : theme.palette.divider.dark,
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
          }}
          spacing={2.5}
          direction="row"
        >
          <IconButton onClick={() => handleShare("instagram")}>
            <InstaIcon />
          </IconButton>
          <IconButton onClick={() => handleShare("telegram")}>
            <TelegIcon />
          </IconButton>
          <IconButton onClick={() => handleShare("facebook")}>
            <FacebookIcon />
          </IconButton>
          <IconButton onClick={() => handleShare("twitter")}>
            <TwitIcon />
          </IconButton>
        </Stack>

        <Box className="flex column" sx={{ gap: 1 }}>
          <Typography className="typography medium">
            Copy direct link
          </Typography>
          <Box
            className="flex items-center"
            sx={{
              backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
              p: 1.5,
              borderRadius: "10px",
              gap: 1,
              justifyContent: "space-between",
            }}
          >
            <Typography
              className="typography"
              sx={{
                fontSize: { xs: "0.85rem !important", sm: "0.9rem !important" },
                maxWidth: { xs: "73%", sm: "73%", md: "unset" },
                textOverflow: "ellipsis",
                overflow: { xs: "hidden", sm: "hidden", md: "unset" },
              }}
            >
              <Box
                sx={{ margin: "0 7px", display: { xs: "none", sm: "initial" } }}
              >
                <LinkIcon />
              </Box>{" "}
              {link}
            </Typography>
            <Button
              onClick={() => handleCopy()}
              className="btn"
              variant="contained"
              color="primary"
              sx={{
                padding: {
                  xs: "4px 12px !important",
                  sm: "4px 22px !important",
                },
                fontSize: "15px !important",
              }}
            >
              {t("Copy")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
