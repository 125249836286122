import { Box, Grow, Typography, useTheme } from "@mui/material";
import React from "react";
import background from "../../assets/images/background/kewb-bg.jpg";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import Card from "../../components/Card/Card";
import { useTranslation } from "react-i18next";
import { API_LOGIN_ROUTE } from "../../utils/consts";
import { setMessage } from "../../redux/rootReducer";
import { useDispatch } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../redux/actions/userAction";

export default function Login() {
  const theme = useTheme();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailHash, setEmailHash] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenIn = queryParams.get("token");
    const email_hash = queryParams.get("email_hash");
    if (tokenIn && email_hash) {
      setEmailHash(email_hash);
      setToken(tokenIn);
    } else {
      navigate("/signup");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (token && emailHash) {
      handleLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, emailHash]);

  const handleLogin = async () => {
    const body = {
      email_hash: emailHash,
      token: token,
      isWeb: true,
    };
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + API_LOGIN_ROUTE,
        options
      );
      const data = await response.json();
      if (data.data?.access_token) {
        dispatch(userLogin(data.data));

        localStorage.setItem("core_api_token", data.data.kewb_token);
        localStorage.setItem("access_token", data.data.access_token);
        if (data.data.user.username) {
          setTimeout(() => {
            navigate("/");
          }, 300);
        } else {
          setTimeout(() => {
            navigate("/setup");
          }, 300);
        }
      } else {
        setTimeout(() => {
          dispatch(setMessage(data.message ?? data.errors[0].text, data.type));
          navigate("/signup");
        }, 300);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      className="flex column center fullscreen"
      sx={{
        backgroundImage: "url(" + background + ")",
        backgroundSize: "cover",
      }}
    >
      <Box className="flex column" sx={{ gap: "63px" }}>
        <Box
          className="flex column"
          sx={{
            width: "100%",
            gap: "13px",
            maxWidth: { xs: "unset", sm: "223px" },
            alignItems: { xs: "center", sm: "unset" },
          }}
        >
          <Logo />
          <Typography
            variant="h1"
            className="typography bold"
            sx={{
              padding: { xs: "0 30px", sm: "unset" },
              textAlign: {
                xs: "center",
                sm: "unset",
                md: "unset",
                lg: "unset",
              },
              color:
                theme.palette.mode === "light"
                  ? theme.palette.text.dark
                  : theme.palette.text.light,
            }}
          >
            {t("Stream anything to everywhere!")}
          </Typography>
        </Box>
        <Grow in={isLoading} timeout={300}>
          <Box>
            <Card
              className="margin"
              sx={{ margin: { xs: "0 20px", sm: "0", md: "0", lg: "0" } }}
            >
              <Loader />
            </Card>
          </Box>
        </Grow>
      </Box>
    </Box>
  );
}
