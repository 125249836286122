import React from "react";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { withTheme } from "@mui/styles";

const StyledTextField = styled(withTheme(TextField))((props) => ({
  backgroundColor:
    props.theme.palette.mode === "light"
      ? props.theme.palette.grey.light
      : props.theme.palette.grey.dark,
  color:
    props.theme.palette.mode === "light"
      ? props.theme.palette.text.light
      : props.theme.palette.text.dark,
  borderRadius: "10px",
  width: "100%",
  input: {
    color:
      props.theme.palette.mode === "light"
        ? props.theme.palette.text.light
        : props.theme.palette.text.dark,
  },
  textarea: {
    color:
      props.theme.palette.mode === "light"
        ? props.theme.palette.text.light
        : props.theme.palette.text.dark,
  },
}));

const StyledFormControl = styled(withTheme(FormControl))((props) => ({
  label: {
    color:
      props.theme.palette.mode === "light"
        ? props.theme.palette.text.light
        : props.theme.palette.text.dark,
    fontFamily: "Gilroy SemiBold  !important",
  },
  fieldset: {
    top: props.label ? "0px" : "-5px",
  },
  ".MuiTextField-root label": {
    display: props.label ? "none" : "block",
  },
  legend: {
    display: props.label ? "none" : "block",
  },
  "& > label": {
    top: props.label ? "-40px" : "0",
  },
}));

export default function TextFieldUI({
  placeholder,
  type,
  onChange,
  onKeyPress,
  value,
  icon,
  sx,
  style,
  label,
  multiline,
  rows,
  error,
  required,
}) {
  if (!onChange) {
    return <></>;
  }
  return (
    <Box>
      <StyledFormControl
        variant="standard"
        sx={{
          width: "100%",
          position: "relative",
          "& .MuiFormLabel-root": {
            fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
          },
        }}
        label={label}
      >
        <Box
          sx={{
            position: "absolute",
            left: "-30px",
            top: "0",
            bottom: "0",
            transform: "translateY(37%)",
          }}
        >
          {icon}
        </Box>
        <InputLabel htmlFor="input-with-icon-textfield">
          {label}
          {required && " *"}
        </InputLabel>
        <StyledTextField
          className="TextFieldUI font medium"
          id="input-with-icon-textfield"
          sx={sx}
          style={style}
          type={type}
          label={label}
          rows={rows}
          multiline={multiline}
          placeholder={placeholder}
          onChange={onChange}
          onKeyUp={onKeyPress}
          value={value}
        />
      </StyledFormControl>
      {error && (
        <Typography className="typography" color="primary" sx={{ mt: 0.5 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
}
