import { Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import { withTheme } from "@mui/styles";

const StyledButton = styled(withTheme(Button))((props) => ({
  backgroundColor:
    props.theme.palette.mode === "light"
      ? props.theme.palette.grey.light
      : props.theme.palette.grey.dark,
  borderRadius: "21px",
  width: "fit-content",
  height: "fit-content",
  padding: "5px 20px 5px 6px",
  gap: "11px",
  textTransform: "unset",
  svg: {
    width: "30px",
    height: "30px",
  },
}));

export default function Badge({ title, icon, onClick, key }) {
  const theme = useTheme();
  return (
    <StyledButton onClick={onClick} className="flex center" key={key}>
      {icon}
      <Typography
        sx={{
          color:
            theme.palette.mode === "light"
              ? theme.palette.text.light
              : theme.palette.text.dark,
        }}
        className="typography bold"
      >
        {title}
      </Typography>
    </StyledButton>
  );
}
