import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { ReactComponent as PersonSeatIcon } from "../../assets/images/icons/svg/person-seat.svg";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import { participant } from "../../redux/actions/sessionActions";
import Card from "../Card/Card";
import { CloseRounded } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "47%",
  transform: "translate(-50%, -50%) !important",
  width: "95vw",
  border: "2px solid #000",
  textAlign: "center",
  margin: "0 auto",
  p: 0,
};

export default function WaitingsModalButton() {
  const dispatch = useDispatch();
  const stream_session = useSelector((state) => state.stream_session);
  const awaiters = useSelector((state) => state.awaiters);
  const [open, setOpen] = React.useState(false);
  const [acceptAll, setAcceptAll] = React.useState(true);

  const [GrowEffect, setGrowEffect] = React.useState(false);
  const clickPositionRef = React.useRef({ x: 0, y: 0 });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    clickPositionRef.current = { x: event.clientX, y: event.clientY };
    setAnchorEl(event.currentTarget);
  };
  console.log(openMenu, anchorEl, clickPositionRef);
  const handleClose = () => {
    setAnchorEl(null);
    clickPositionRef.current = { x: 0, y: 0 };
    console.log("close");
  };

  const handleOpen = () => {
    if (!open) {
      setGrowEffect(true);
      setOpen(true);
    } else {
      handleCloseModal();
    }
  };
  const handleCloseModal = () => {
    setGrowEffect(false);
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  const handleAcceptAll = () => {
    console.log("accept");
  };

  const handleApprove = (e, user) => {
    console.log("approve");
    dispatch(participant("accept", stream_session?.stream_session?.hash, user));
    handleClose();
  };
  const handleDecline = (e, user) => {
    console.log("decline");
    dispatch(
      participant("decline", stream_session?.stream_session?.hash, user)
    );
    handleClose();
  };
  const handleKick = (e, user) => {
    console.log("kick");
    dispatch(participant("kick", stream_session?.stream_session?.hash, user));
    handleClose();
  };
  const handleBan = (e, user) => {
    console.log("ban");
    dispatch(participant("ban", stream_session?.stream_session?.hash, user));
    handleClose();
  };

  const handleCheckbox = (e) => {
    setAcceptAll(e.target.checked);
  };
  return (
    <Box>
      <Grow in timeout={300}>
        <Box className="fixed" sx={{ top: 60, left: 30, zIndex: 1000 }}>
          <Badge
            color="fourth"
            badgeContent={awaiters.length}
            sx={{
              ".MuiBadge-badge": {
                fontWeight: "bold",
                fontSize: "0.9rem",
                width: 24,
                height: 24,
                top: 7,
                right: 7,
                color: "#1E0640",
              },
            }}
          >
            <IconButton
              onClick={handleOpen}
              color="white"
              variant="contained"
              sx={{
                backgroundColor: "#fff",
                boxShadow: "0px 3px 6px #00000029",
                width: 50,
                height: 50,
                "&:hover": { backgroundColor: "#d9d9d9" },
              }}
            >
              <PersonSeatIcon style={{ color: "#1E0640" }} />
            </IconButton>
          </Badge>
        </Box>
      </Grow>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        keepMounted
        className="flex center"
        sx={{
          zIndex: 100,
          ".MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Grow in={GrowEffect} timeout={150}>
          <Box>
            <Card sx={style}>
              <Box className="relative">
                <IconButton
                  onClick={handleCloseModal}
                  className="right-2 top-2"
                  sx={{ color: "#1E0640", position: "absolute !important" }}
                >
                  <CloseRounded />
                </IconButton>
                <Box
                  className="flex column"
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    "& p": {
                      color: "#1E0640",
                    },
                    px: 3,
                    pt: 2,
                    pb: 1,
                    gap: 5,
                  }}
                >
                  <Typography
                    className="typography bold relative"
                    sx={{ fontSize: "0.9rem", top: 12, left: "-20px" }}
                  >
                    Waiting in the Lobby
                  </Typography>
                  <Box
                    className="flex items-center"
                    sx={{ justifyContent: "space-between" }}
                  >
                    <FormControlLabel
                      className="font semibold"
                      sx={{
                        color: "#1E0640",
                        "& .MuiTypography-root": {
                          fontFamily: "inherit",
                          fontSize: "0.9rem",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={acceptAll}
                          color="white"
                          onChange={handleCheckbox}
                          sx={{
                            svg: {
                              fill: "#1E0640",
                            },
                          }}
                        />
                      }
                      label="Auto-accept"
                    />
                    <Button
                      className="font bold"
                      variant="contained"
                      color="white"
                      onClick={handleAcceptAll}
                      sx={{
                        borderRadius: "6px",
                        fontSize: "0.9rem !important",
                        textTransform: "unset",
                        boxShadow: "unset !important",
                        maxHeight: "30px",
                        backgroundColor: "#1E0640 !important",
                        color: "#fff",
                        px: 3,
                        py: 2,
                      }}
                    >
                      Accept All
                    </Button>
                  </Box>
                </Box>
                <Box
                  className="flex column"
                  sx={{ px: 1, py: 1, height: "60vh", overflowY: "scroll" }}
                >
                  <List>
                    {awaiters.map((user, i) => (
                      <ListItem
                        aria-controls={
                          openMenu ? "demo-positioned-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                        onClick={handleClick}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.05) !important",
                          },
                        }}
                      >
                        <ListItemAvatar sx={{ minWidth: 47 }}>
                          <Avatar
                            sx={{ width: 33, height: 33 }}
                            src={user.avatar}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            className="typography semibold"
                            sx={{ fontSize: "0.9rem" }}
                          >
                            {user.participant_name}
                          </Typography>
                        </ListItemText>

                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleClose}
                          anchorReference="anchorPosition"
                          anchorPosition={
                            anchorEl
                              ? {
                                  top: clickPositionRef.current.y,
                                  left: clickPositionRef.current.x,
                                }
                              : undefined
                          }
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              borderRadius: "10px",
                            },
                            "& .MuiList-root": {
                              py: 0,
                            },
                            "& svg": {
                              color: "#D3D3D3",
                              width: "18px",
                              height: "18px",
                            },
                            "& .MuiMenuItem-root:hover": {
                              backgroundColor: "rgba(240,240,240,1) !important",
                            },
                          }}
                        >
                          <MenuItem
                            className="flex items-center font bold"
                            sx={{ gap: 1, fontSize: "14px", px: 2, py: 1.5 }}
                            onClick={(e) => handleApprove(e, user)}
                          >
                            <CheckIcon /> <span>Approve</span>
                          </MenuItem>
                          {/* <MenuItem
                className="flex items-center font bold"
                sx={{
                  gap: 1,
                  fontSize: "14px",
                  px: 2,
                  py: 1.5,
                  borderTop: "1px solid #EDEDED",
                  borderBottom: "1px solid #EDEDED",
                }}
                onClick={(e) => handleDecline(e, user)}
              >
                <CloseRoundedIcon /> <span>Decline</span>
              </MenuItem> */}
                          {/* <MenuItem
                className="flex items-center font bold"
                sx={{ gap: 1, fontSize: "14px", px: 2, py: 1.5 }}
                onClick={(e) => handleKick(e, user)}
              >
                <RemoveCircleOutlineIcon /> <span>Kick</span>
              </MenuItem> */}
                          <MenuItem
                            className="flex items-center font bold"
                            sx={{ gap: 1, fontSize: "14px", px: 2, py: 1.5 }}
                            onClick={(e) => handleBan(e, user)}
                          >
                            <BlockRoundedIcon /> <span>Ban</span>
                          </MenuItem>
                        </Menu>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Card>
          </Box>
        </Grow>
      </Modal>
    </Box>
  );
}
