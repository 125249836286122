import { AWAITERS, AWAITERS_ADD_ONE, AWAITERS_REMOVE, AWAITERS_REMOVE_ONE } from "../types";

const initialState = [];
export const awaitersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AWAITERS: {
      const { data } = payload;
      return data;
    }
    case AWAITERS_ADD_ONE: {
      const { data } = payload;
      return [...state, data];
    }
    case AWAITERS_REMOVE: {
      return initialState;
    }
    case AWAITERS_REMOVE_ONE: {
      const { data } = payload;
      return state.filter((item) => item.id !== data.id);
    }
    default: {
      return state;
    }
  }
};
