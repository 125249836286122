import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Logout from "../components/Logout/Logout";
import Signup from "../pages/Main/Signup";
import Main from "../pages/Main/Main";
import Setup from "../pages/Main/Setup";
import Settings from "../pages/Main/Settings";
import Login from "../pages/Main/Login";
import Account from "../pages/Main/Settings/Account";
import Membership from "../pages/Main/Settings/Membership";
import Support from "../pages/Main/Settings/Support";
import Page404 from "../pages/Main/Page404";
import Join from "../pages/Main/Join";
import Payment from "../pages/Main/Payment";
import Terms from "../pages/Main/Terms";
import Privacy from "../pages/Main/Privacy";

function Router() {
  const session = useSelector((state) => state.session);

  return (
    <Routes>
      {session?.user ? (
        <>
          <Route path={"/"} element={<Main />} />
          <Route path={"/setup"} element={<Setup />} />
          <Route path={"/session/:slug"} element={<Main />} />
          <Route path={"/logout"} element={<Logout />} />
          <Route path={"/settings"} element={<Settings />} />
          <Route path={"/settings/account"} element={<Account />} />
          <Route path={"/settings/membership"} element={<Membership />} />
          <Route path={"/settings/support"} element={<Support />} />
          <Route path={"/payment"} element={<Payment />} />
          <Route path="*" element={<Page404 />} status={404} />
        </>
      ) : (
        <>
          <Route path={"/login"} element={<Login />} />
          <Route path={"/signup"} element={<Signup />} />
          <Route path="*" element={<Navigate to="/signup" replace />} />
        </>
      )}
      <Route path={"/join/:slug"} element={<Join />} />
      <Route path={"/terms"} element={<Terms />} />
      <Route path={"/privacy"} element={<Privacy />} />
    </Routes>
  );
}

export default Router;
