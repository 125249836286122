import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function Screen() {
  const stream_session = useSelector((state) => state.stream_session);
  const cameraEnabled = useSelector((state) => state.cameraEnabled);
  const user = useSelector((state) => state?.session?.user);
  return (
    <>
      <Box className="screen">
        <div id="players" className="Screens_grid">
          <Box className="player" sx={{ width: "100%", height: "inherit" }}>
            <video
              id="localVideo"
              autoPlay
              muted={false}
              controls={false}
              playsInline
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />

            <Typography
              className="typography bold"
              style={{
                position: "absolute",
                right: "20px",
                bottom: "15px",
                color: "white",
                fontSize: "1.3rem",
              }}
            >
              {stream_session?.current_participant?.participant_name}
            </Typography>
            {!cameraEnabled && (
              <Avatar className="cameraOff typography bold">
                {user ? user?.username?.slice(0, 1) : "G"}
              </Avatar>
            )}
          </Box>
        </div>
      </Box>
    </>
  );
}
