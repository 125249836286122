import { AUTH_LOGIN, AUTH_LOGOUT, USER_AVATAR_DELETE, USER_UPDATE } from "../types";

const initialState = {};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_LOGIN: {
      let { data } = payload;
      return data;
    }

    case AUTH_LOGOUT: {
      return initialState;
    }

    case USER_AVATAR_DELETE: {
      let newState = Object.assign({}, state);
      newState.user.avatar = null;
      return newState;
    }

    case USER_UPDATE: {
      const { data } = payload;
      let newState = Object.assign({}, state);
      newState.user = data;
      return newState;
    }

    default: {
      return state;
    }
  }
};
