import { Button, useTheme } from "@mui/material";
import React from "react";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
export default function BackButton({ sx, style }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Button
      variant="outlined"
      color="third"
      component="a"
      onClick={handleBack}
      className="btn btn-third long"
      style={style}
      startIcon={
        <KeyboardReturnIcon
          sx={{
            color:
              theme.palette.mode === "light"
                ? theme.palette.text.dark
                : theme.palette.text.light,
            width: "1.3em",
            height: "1.3em",
          }}
        />
      }
      sx={{
        color:
          theme.palette.mode === "light"
            ? theme.palette.text.dark
            : theme.palette.text.light,
        border:
          "2px solid" +
          (theme.palette.mode === "light"
            ? theme.palette.text.dark
            : theme.palette.text.light),
        "&:hover": {
          border:
            "2px solid" +
            (theme.palette.mode === "light"
              ? theme.palette.text.dark
              : theme.palette.text.light),
        },
        width: "fit-content",
        ...sx,
      }}
    >
      {t("Back")}
    </Button>
  );
}
