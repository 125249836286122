import React, { createContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import {
  appendMessageToState,
  getMessages,
  getToken,
} from "../redux/actions/messageAction";
import {
  addAwaiter,
  sessionAccepted,
  SessionDelete,
} from "../redux/actions/sessionActions";
import { setMessage } from "../redux/rootReducer";
import { logoutUser } from "../redux/actions/userAction";

const SocketContext = createContext();

const ContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const user = useSelector((state) => state.session?.user);
  const [initialized, setInitialized] = React.useState(false);

  useEffect(() => {
    const token = localStorage.getItem("core_api_token") ?? getToken();
    if (token) {
      let socketio = io.connect(process.env.REACT_APP_SOCKET_API_URL, {
        query: { token },
        transports: ["polling", "websocket"],
        reconnection: true,
        reconnectionDelay: 3500,
      });
      socketio.token = token;
      setSocket(socketio);
    }
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Socket disconnected on unmount");
      }
    };
  }, [localStorage.getItem("core_api_token")]);

  const stream_session = useSelector((state) => state.stream_session);

  useEffect(() => {
    if (stream_session) {
      socket?.removeAllListeners();
      setInitialized(false);
    }
  }, [stream_session]);

  useEffect(() => {
    if (
      stream_session?.stream_session?.chat?.virtualId &&
      socket &&
      !initialized
    ) {
      initializer(stream_session.stream_session.chat.virtualId);
    }
  }, [stream_session, initialized, socket]);

  const initializer = (socketRoomId) => {
    // if (!initialized && socketRoomId) {
    //   initializeEvents(socketRoomId);
    // }
    socket?.on("connect", async (e) => {
      if (!initialized && socketRoomId) initializeEvents(socketRoomId);
    });
    socket?.on("disconnect", () => {
      setInitialized(false);
      console.log("### Chat disconnected.");
    });
  };

  const initializeEvents = (socketRoomId) => {
    setInitialized(true);
    socket.removeAllListeners();
    socket.emit("join_room", socketRoomId);

    dispatch(getMessages(socketRoomId, socket.token, 200));

    console.log(`### Connected to chat web socket;`);
    console.log("### Getting messages from backend (͠≖ ͜ʖ͠≖);");

    socket?.onAny((event, data, ...args) => {
      let dataVal = JSON.stringify(data);
      console.log(`Event received: ${event} data: ${dataVal}`);
    });

    if (!socket.hasListeners("message_sent")) {
      socket.on("message_sent", async (data) => {
        // if(data.data.roomId !== 1)
        //     return;
        const message = {
          id: parseInt(data.id),
          message: data.message,
          type: data.type,
          banned: data.banned !== 0 ? true : false,
          data: {
            avatar: data.data.avatar ? data.data.avatar : "",
            name: data.data.name,
            role: data.data.role,
            userId: data.data.userId,
            time: data.data.time,
            is_pool: data.data.is_pool,
            is_premium: data.data.is_premium,
            pool_id: data.data.itemId,
            event: data.data.event ? data.data.event : "",
            event_type: data.data.event_type ? data.data.event_type : "",
            url: data.data.url ? data.data.url : "",
            actionType: data.data.actionType ? data.data.actionType : null,
          },
        };
        dispatch(appendMessageToState(message));
      });
    }
    if (!socket.hasListeners("joinRequestAccepted")) {
      socket.on("joinRequestAccepted", async (data) => {
        dispatch(sessionAccepted(data.data));
        navigate("/");
      });
    }
    if (!socket.hasListeners("joinRequestDeclined")) {
      socket.on("joinRequestDeclined", async (data) => {
        dispatch(setMessage("Your request was declined by host."));
        dispatch(SessionDelete());
        navigate("/");
      });
    }
    if (!socket.hasListeners("sessionParticipantKicked")) {
      socket.on("sessionParticipantKicked", async (data) => {
        dispatch(setMessage("Your were kicked from the waiting list by host."));
        dispatch(SessionDelete());
        navigate("/");
      });
    }
    if (!socket.hasListeners("newJoinRequest")) {
      socket.on("newJoinRequest", async (data) => {
        dispatch(addAwaiter(data.data.participant));
      });
    }
    if (!socket.hasListeners("streamSessionFinished")) {
      socket.on("streamSessionFinished", async (data) => {
        dispatch(setMessage("Stream session has been finished."));
        dispatch(SessionDelete());
        if (!user) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("core_api_token");
          window.location.reload();
        } else {
          if (user.is_guest) {
            dispatch(logoutUser());
          }
        }
      });
    }
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
