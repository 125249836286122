import { SET_LOADING, REMOVE_LOADING } from '../types'

const initialState = null;

export const loadingReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case SET_LOADING: {
      const { data } = payload
      const newState = {
          name: data,
          isLoading: true
      }
      return newState
    }

    case REMOVE_LOADING: {
      return null;
    }
    
    default: {
      return state
    }
  }
}
