import { Box, Grow, useTheme } from "@mui/material";
import React from "react";
import Card from "../Card/Card";

export default function SelectBox({ children, onClick, active }) {
  const theme = useTheme();

  return (
    <Grow in timeout={300}>
      <Box
        className="flex items-center cursor-pointer"
        sx={{ gap: "20px" }}
        onClick={onClick}
      >
        <Box
          sx={{
            width: { xs: "20px", sm: "20px", md: "20px", lg: "19px" },
            height: { xs: "18px", sm: "18px", md: "18px", lg: "19px" },
            borderRadius: "50%",
            background: active
              ? theme.palette.mode === "light"
                ? theme.palette.fourth.light
                : theme.palette.fourth.dark
              : "transparent",
            border:
              "2px solid " +
              (active
                ? theme.palette.mode === "light"
                  ? theme.palette.fourth.light
                  : theme.palette.fourth.dark
                : theme.palette.mode === "light"
                ? theme.palette.paper.light
                : theme.palette.paper.dark),
          }}
        />
        <Card
          className={"w-full relative"}
          sx={{
            padding: {
              xs: "22px 16px 22px 26px",
              sm: "26px 20px 26px 32px",
              md: "30px 24px 30px 40px",
            },
            background: active
              ? theme.palette.mode === "light"
                ? theme.palette.fourth.light
                : theme.palette.fourth.dark
              : theme.palette.mode === "light"
              ? theme.palette.paper.light
              : theme.palette.paper.dark,
          }}
        >
          {children}
        </Card>
      </Box>
    </Grow>
  );
}
