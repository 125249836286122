import { CHAT_DISABLE, CHAT_ENABLE } from '../types'

export const chatEnableReducer = (state = true, action) =>{
  const {type} = action

  switch (type) {
    case CHAT_ENABLE: {
      return true
    }
    case CHAT_DISABLE: {
      return false;
    }
    default: {
      return state
    }
  }
}
