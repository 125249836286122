import { Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import { removeMessage } from "../../redux/rootReducer";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function SnackbarUI() {
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(removeMessage());
  };
  const handleExited = () => {
    dispatch(removeMessage());
  };
  const message = useSelector((state) => state.message);
  if (message && Object.keys(message).length > 0) {
    return (
      <Snackbar
        open={Object.keys(message).length > 0}
        autoHideDuration={7000}
        TransitionProps={{ onExited: handleExited }}
        onClose={handleClose}
        anchorOrigin={
          message.type === "warning"
            ? { vertical: "center", horizontal: "center" }
            : message.position
            ? { vertical: "bottom", horizontal: message.position }
            : { vertical: "bottom", horizontal: "left" }
        }
      >
        <Alert
          onClose={handleClose}
          color={message.type}
          className="alert"
          severity={message.type}
          sx={{ width: "100%" }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    );
  }
  return <></>;
}
