import * as React from "react";
import Box from "@mui/material/Box";
import { Avatar, Grow, ListItem, Typography } from "@mui/material";
import styles from "./Message.module.scss";
import { useRef } from "react";
import { markMessage } from "../../redux/actions/messageAction";
import { useDispatch } from "react-redux";

function Message({ message }) {
  const [checked, setChecked] = React.useState(false);
  const elem = useRef(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (message.message === "") {
      return;
    }
    if (message.seen === false) {
      dispatch(markMessage(message.id));
    }
    setTimeout(() => {
      setChecked(true);
    }, 30);

    if (message?.previousId && message?.previousSameType) {
      setTimeout(() => {
        const el = document.getElementById("message_" + message.previousId);
        if (el) {
          if (!document.getElementById("msg_" + message.id)) {
            el.insertAdjacentHTML(
              "beforeend",
              '<div class="msg typography animate pop MuiBox-root css-0"  id="msg_' +
                message.id +
                '" data-message="' +
                message.message +
                '" data-id="' +
                message.id +
                '">' +
                `<p class="MuiTypography-root MuiTypography-body1 css-ahj2mt-MuiTypography-root">` +
                message.message +
                `</p></div>`
            );
          }
        }
      }, 350);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!message) {
    return <></>;
  }

  return (
    message.message !== "" &&
    !message.isPrevious && (
      <ListItem
        ref={elem}
        key={message.id}
        id={message.id}
        className={styles.ChatListElem}
        sx={{ padding: { xs: "4px 8px", sm: "6px 14px", md: "8px 16px" } }}
      >
        <Grow timeout={370} delay={200} in={checked} mountOnEnter unmountOnExit>
          <Box className={styles.ChatListElem_wrap}>
            <Box
              className={
                styles.img_wrap +
                (message.data?.is_premium && " border-anim-rotate-rainbow")
              }
            >
              <Avatar
                className={styles.img}
                src={message.data?.avatar}
                alt=""
              />
            </Box>

            <Box
              className={styles.message_wrap}
              id={"message_wrap_" + message.id}
            >
              <Box className={styles.msg} id={"message_" + message.id}>
                <Box id={"msg_" + message.id}>
                  <Typography>{message.message}</Typography>
                </Box>
              </Box>
              <Typography fontSize={12} className="typography bold username">
                {message.data?.name}
              </Typography>
            </Box>
          </Box>
        </Grow>
      </ListItem>
    )
  );
}

export default Message;
