import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Stepper,
  StepLabel,
  Grow,
  useTheme,
  Modal,
} from "@mui/material";
import React, { Suspense } from "react";
import { lazy } from "react";
import Card from "../../components/Card/Card";
import Step from "@mui/material/Step";
import TextFieldUI from "../../components/UI/TextField";
import { useTranslation } from "react-i18next";
import { API_USER_PROFILE_ROUTE } from "../../utils/consts";
import { setMessage } from "../../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../components/Banner/Banner";
import ImageUpload from "../../components/UI/ImageUpload";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Backdrop from "@mui/material/Backdrop";
import { ReactComponent as Logo } from "../../assets/images/icons/svg/logo-icon.svg";
import { ReactComponent as Circle } from "../../assets/images/icons/svg/circle-plus.svg";
import Badge from "../../components/UI/Badge";
import Channels from "../../components/Channels/Channels";
import Socials from "../../components/Socials/Socials";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { ReactComponent as FacebookIcon } from "../../assets/images/icons/svg/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/icons/svg/youtube.svg";
import { ReactComponent as TwitchIcon } from "../../assets/images/icons/svg/twitch.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/icons/svg/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/icons/svg/twitter.svg";
import { isMobile } from "react-device-detect";
import { userUpdateProfile } from "../../redux/actions/userAction";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
const WebCamera = lazy(() => import("../../components/WebCamera/WebCamera"));
export default function Setup() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState(
    session?.user?.username ? session.user.username : ""
  );
  const [bio, setBio] = React.useState(
    session?.user?.bio ? session.user.bio : ""
  );
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(
    session?.user?.avatar?.imageSmall
  );
  const [socials, setSocials] = React.useState([
    {
      name: t("Facebook"),
      icon: <FacebookIcon />,
      paired: false,
    },
    {
      name: t("YouTube"),
      icon: <YoutubeIcon />,
      paired: false,
    },
    {
      name: t("Twitch"),
      icon: <TwitchIcon />,
      paired: false,
    },
    {
      name: t("Instagram"),
      icon: <InstagramIcon />,
      paired: false,
    },
    {
      name: t("Twitter"),
      icon: <TwitterIcon />,
      paired: false,
    },
  ]);

  const [channels, setChannels] = React.useState([
    {
      name: t("Facebook"),
      icon: <FacebookIcon />,
      paired: false,
    },
    {
      name: t("YouTube"),
      icon: <YoutubeIcon />,
      paired: false,
    },
    {
      name: t("Twitch"),
      icon: <TwitchIcon />,
      paired: false,
    },
    {
      name: t("Instagram"),
      icon: <InstagramIcon />,
      paired: false,
    },
    {
      name: t("Twitter"),
      icon: <TwitterIcon />,
      paired: false,
    },
  ]);

  const [openChannels, setOpenChannels] = React.useState(false);
  const [openSocials, setOpenSocials] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  //const steps = [t("Account"), t("Pairing")];
  const steps = [t("Account")];
  const handleOpen = () => {
    // setSearchParams({login: true});
    setOpen(true);
  };
  const handleClose = () => {
    // setSearchParams({login: false});
    setOpen(false);
  };

  const isStepOptional = (step) => {
    return step === -1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleChannels = () => {
    setOpenChannels(true);
  };

  const handleSocials = () => {
    setOpenSocials(true);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleProceed = () => {
    // if (activeStep === 0) setActiveStep(activeStep + 1);
    // else {
    //   handleSend();
    // }
    if (activeStep === 0) {
      handleSend();
    }
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleSend = async () => {
    var body = new FormData();
    body.append("username", username);
    body.append("bio", bio);
    body.append("avatar", selectedImage);
    setLoading(true);
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ` + session.access_token,
        },
        body: body,
      };
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + API_USER_PROFILE_ROUTE,
        options
      );
      const data = await response.json();
      if (data.success) {
        dispatch(userUpdateProfile(data.data.user));
        navigate("/");
        console.log(data);
      } else {
        dispatch(setMessage(data.message));
      }
    } catch (error) {
      dispatch(setMessage(error, "error"));
    } finally {
      setLoading(false);
    }
  };

  const deleteImage = () => {
    setImageUrl(null);
  };

  React.useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // React.useEffect(() => {
  //   if (!session.user) {
  //     navigate("/signup");
  //   }
  // }, []);

  return (
    <Box
      className="flex column center"
      sx={{
        padding: "40px 0",
        maxWidth: "745px",
        margin: "0 auto",
        gap: "15px",
      }}
    >
      <Banner
        title={
          activeStep === 0
            ? t("Awesome!")
            : activeStep === 1
            ? t("Stream to Anywhere!!")
            : ""
        }
        content={
          activeStep === 0
            ? t(
                "Now let’s quickly fill your account details! Don’t worry, you can edit them later."
              )
            : activeStep === 1
            ? t(
                "Kewb allows you to stream to many platforms from a single device!"
              )
            : ""
        }
      />

      <Stepper
        activeStep={activeStep}
        sx={{
          width: "50%",
          mb: 2,
          justifyContent: steps.length === 1 ? "center" : "unset",
        }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step
              sx={{ cursor: "pointer" }}
              key={label}
              {...stepProps}
              onClick={(e) => handleStep(index)}
            >
              <StepLabel
                {...labelProps}
                className="flex column items-center relative"
                sx={{ gap: "7px", top: "13px" }}
              >
                <Typography
                  className="typography bold"
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.dark
                        : theme.palette.text.light,
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Grid container sx={{ gap: "36px" }}>
        {activeStep === 0 && (
          <>
            <Grid
              className="flex column"
              item
              xs={12}
              sm={5.5}
              sx={{ gap: "30px", mx: { xs: "30px", sm: "0" } }}
            >
              <Grow in={activeStep === 0}>
                <Box>
                  <Typography
                    variant="h2"
                    className="typography bold"
                    sx={{
                      mb: 1.5,
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.text.dark
                          : theme.palette.text.light,
                    }}
                  >
                    {t("Profile")}
                  </Typography>
                  <Card
                    sx={{ padding: "50px 24px 30px", justifyContent: "start" }}
                  >
                    <TextFieldUI
                      sx={{ mb: 4, mt: 1 }}
                      label={t("Username")}
                      value={username}
                      required
                      type="text"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextFieldUI
                      sx={{ mb: 0.5, mt: 1 }}
                      label={t("Bio")}
                      value={bio}
                      multiline={true}
                      rows={4}
                      type="text"
                      onChange={(e) => setBio(e.target.value)}
                    />
                  </Card>
                </Box>
              </Grow>
              <Grow in={activeStep === 0}>
                <Box>
                  <Typography
                    variant="h2"
                    className="typography bold"
                    sx={{
                      mb: 1.5,
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.text.dark
                          : theme.palette.text.light,
                    }}
                  >
                    {t("Avatar")}
                  </Typography>
                  <Card sx={{ padding: "30px 60px" }}>
                    <Box
                      className="flex center relative"
                      sx={{
                        ":hover": {
                          ".MuiIconButton-root": {
                            opacity: "1",
                          },
                        },
                      }}
                    >
                      <ImageUpload
                        value={imageUrl}
                        onChange={(e) => setSelectedImage(e.target.files[0])}
                      />
                      {imageUrl !== null && (
                        <IconButton
                          color="primary"
                          onClick={() => deleteImage()}
                          aria-label={t("Delete")}
                          title={t("Delete")}
                          sx={{
                            opacity: isMobile ? "1" : "0",
                            position: "absolute",
                            top: "0",
                            left: "40px",
                            transition: "all 0.2s",
                            background:
                              theme.palette.mode === "light"
                                ? theme.palette.paper.light
                                : theme.palette.paper.dark,
                            ":hover": {
                              background:
                                theme.palette.mode === "light"
                                  ? theme.palette.paper.light
                                  : theme.palette.paper.dark,
                              filter: "brightness(1.15)",
                            },
                          }}
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      )}
                    </Box>
                    <IconButton
                      onClick={handleOpen}
                      disableFocusRipple
                      size="small"
                      color="primary"
                      sx={{
                        position: "absolute",
                        right: "20px",
                        bottom: "10px",
                      }}
                    >
                      <CameraAltOutlinedIcon />
                    </IconButton>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                      className="modal"
                    >
                      <Box>
                        <Card
                          sx={{
                            width: "32%",
                            height: "100%",
                            margin: "auto",
                            padding: "0",
                            py: 3,
                          }}
                        >
                          <Suspense fallback={null}>
                            <WebCamera
                              setImageUrl={setImageUrl}
                              handleClose={handleClose}
                              imageUrl={imageUrl}
                            />
                          </Suspense>
                        </Card>
                      </Box>
                    </Modal>
                  </Card>
                </Box>
              </Grow>
            </Grid>

            <Grid
              className="flex column"
              item
              xs={12}
              sm={5.5}
              sx={{ gap: "30px", mx: { xs: "30px", sm: "0" } }}
            >
              <Grow in={activeStep === 0}>
                <Box>
                  <Typography
                    variant="h2"
                    className="typography bold"
                    sx={{
                      mb: 1.5,
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.text.dark
                          : theme.palette.text.light,
                    }}
                  >
                    {t("Social Media")}
                  </Typography>

                  <Box className="flex column gap-7">
                    {socials?.length > 0 &&
                      socials.map((social, i) => {
                        if (social.paired)
                          return (
                            <Grow in delay={300} timeout={300}>
                              <Box key={"sz" + i}>
                                <Card sx={{ padding: 0 }}>
                                  <Box className="flex column center">
                                    <Button
                                      onClick={handleChannels}
                                      className="no-hover rounded"
                                      fullWidth
                                      color="primary"
                                      sx={{
                                        position: "relative",
                                        padding: "24px",
                                      }}
                                    >
                                      <Box
                                        className="absolute"
                                        sx={{ left: "25px" }}
                                      >
                                        {social.icon}
                                      </Box>
                                      <Typography
                                        className="typography medium initial"
                                        sx={{
                                          fontWeight: 800,
                                          color:
                                            theme.palette.mode === "light"
                                              ? theme.palette.text.light
                                              : theme.palette.text.dark,
                                        }}
                                      >
                                        {social.name}
                                      </Typography>
                                    </Button>
                                  </Box>
                                </Card>
                              </Box>
                            </Grow>
                          );
                      })}
                    <Card sx={{ padding: 0 }}>
                      <Box className="flex column center">
                        <Button
                          onClick={handleSocials}
                          className="no-hover rounded"
                          fullWidth
                          color="primary"
                          sx={{ position: "relative", padding: "24px" }}
                        >
                          <Circle
                            style={{
                              width: "23px",
                              height: "23px",
                              position: "absolute",
                              left: "25px",
                            }}
                            color="primary"
                          />
                          <Typography
                            className="typography medium initial"
                            sx={{
                              fontWeight: 800,
                              color:
                                theme.palette.mode === "light"
                                  ? theme.palette.text.light
                                  : theme.palette.text.dark,
                            }}
                          >
                            {t("Add social media links")}
                          </Typography>
                        </Button>
                      </Box>
                    </Card>
                  </Box>

                  <Socials
                    open={openSocials}
                    setOpen={setOpenSocials}
                    socials={socials}
                    setSocials={setSocials}
                  />
                </Box>
              </Grow>
            </Grid>
          </>
        )}
        {activeStep === 1 && (
          <Grow in={activeStep === 1}>
            <Grid
              className="flex column"
              item
              xs={12}
              sx={{ mx: { xs: "30px", sm: "0" } }}
            >
              <Typography
                variant="h2"
                className="typography bold"
                sx={{
                  mb: 0.5,
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.text.dark
                      : theme.palette.text.light,
                }}
              >
                {t("Pair Channels")}
              </Typography>

              <Typography
                className="typography"
                sx={{
                  mb: 1.5,
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.text.dark
                      : theme.palette.text.light,
                }}
              >
                {t("Where you want to livestream to -")}
                <span className="typography bold">
                  {t(" or choose Kewb to start immediately!")}
                </span>
              </Typography>

              <Card
                sx={{
                  padding: "13px",
                  minHeight: "134px",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Badge title={t("Kewb")} icon={<Logo />} />
                {channels?.length > 0 &&
                  channels.map((channel, i) => {
                    if (channel.paired)
                      return (
                        <Badge
                          key={"sb" + i}
                          title={channel.name}
                          icon={channel.icon}
                        />
                      );
                  })}
                <Box
                  className="flex center"
                  sx={{ width: "40px", height: "40px", marginLeft: "4px" }}
                >
                  <IconButton
                    onClick={handleChannels}
                    color="primary"
                    sx={{
                      width: "fit-content",
                      height: "fit-content",
                      padding: 0,
                    }}
                  >
                    <Circle
                      sx={{ width: "23px", height: "23px" }}
                      color="primary"
                    />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          </Grow>
        )}
      </Grid>

      <Channels
        open={openChannels}
        setOpen={setOpenChannels}
        channels={channels}
        setChannels={setChannels}
      />

      <LoadingButton
        loading={loading}
        onClick={handleProceed}
        variant="contained"
        className="btn btn-primary xlong"
        color="primary"
        sx={{ mt: 4 }}
      >
        {t("Proceed")}
      </LoadingButton>
    </Box>
  );
}
