import React from "react";
import { Box, Grow, IconButton, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { withTheme } from "@mui/styles";
import Draggable from "react-draggable";

const StyledBox = styled(withTheme(Box))((props) => ({
  backgroundColor:
    props.theme.palette.mode === "light"
      ? props.theme.palette.paper.light
      : props.theme.palette.paper.dark,
  borderRadius: "10px",
  padding: props.title ? "4px 0 6px" : "45px 60px",
  display: "flex",
  flexDirection: "column",
  position: "relative",
}));

export default function Card({
  icon,
  title,
  altIcon,
  altIconOnClick,
  draggable,
  className,
  children,
  sx,
  style,
  onClick,
  grow,
}) {
  const theme = useTheme();
  if (grow) {
    return (
      <Grow in timeout={300}>
        <Box>
          <Draggable disabled={!draggable}>
            <StyledBox
              onClick={onClick}
              className={className}
              sx={sx}
              style={style}
              title={title}
            >
              {title && (
                <Box
                  className="flex items-center"
                  sx={{ gap: "6px", padding: "0 12px", minHeight: "39px" }}
                >
                  {icon}
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.text.light
                          : theme.palette.text.dark,
                    }}
                    className="typography bold"
                  >
                    {title}
                  </Typography>

                  <IconButton sx={{ ml: "auto" }} onClick={altIconOnClick}>
                    {altIcon}
                  </IconButton>
                </Box>
              )}
              {children}
            </StyledBox>
          </Draggable>
        </Box>
      </Grow>
    );
  }
  return (
    <Draggable disabled={!draggable}>
      <StyledBox
        onClick={onClick}
        className={className}
        sx={sx}
        style={style}
        title={title}
      >
        {title && (
          <Box
            className="flex items-center"
            sx={{ gap: "6px", padding: "0 12px", minHeight: "39px" }}
          >
            {icon}
            <Typography
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.text.light
                    : theme.palette.text.dark,
              }}
              className="typography bold"
            >
              {title}
            </Typography>

            <IconButton sx={{ ml: "auto" }} onClick={altIconOnClick}>
              {altIcon}
            </IconButton>
          </Box>
        )}
        {children}
      </StyledBox>
    </Draggable>
  );
}
