import { Box, Typography, useTheme, Button, Grow } from "@mui/material";
import React from "react";
import background from "../../assets/images/background/kewb-bg.jpg";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import Card from "../../components/Card/Card";
import TextFieldUI from "../../components/UI/TextField";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useTranslation } from "react-i18next";
import CheckboxUI from "../../components/UI/CheckboxUI";
import { API_LOGIN_ATTEMPT_ROUTE } from "../../utils/consts";
import { setMessage } from "../../redux/rootReducer";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { userLogin } from "../../redux/actions/userAction";
import Loader from "../../components/Loader/Loader";

export default function Signup() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [remember, setRemember] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [pending, setPending] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [GrowEffect, setGrowEffect] = React.useState(true);
  const [error, setError] = React.useState({
    email: null,
    username: null,
  });

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.code === "Enter" || e.keyCode === 13) {
      handleSend();
    }
  };

  const handleLogin = (data) => {
    setIsLoading(true);
    if (data?.access_token) {
      localStorage.setItem("core_api_token", data.kewb_token);
      localStorage.setItem("access_token", data.access_token);
      if (data.user.username) {
        setTimeout(() => {
          setGrowEffect(false);
          setTimeout(() => {
            dispatch(userLogin(data));
            navigate("/");
            setIsLoading(false);
          }, 300);
        }, 900);
      } else {
        setTimeout(() => {
          setGrowEffect(false);
          setTimeout(() => {
            dispatch(userLogin(data));
            navigate("/setup");
            setIsLoading(false);
          }, 300);
        }, 900);
      }
    }
  };

  const handleSend = async () => {
    setError({
      email: null,
      username: null,
    });
    let emailRequired = false;
    if (email === "") {
      emailRequired = true;
    }
    let usernameRequired = false;
    // if (username === '') {
    //   usernameRequired = true;
    // }
    if (emailRequired || usernameRequired) {
      setError({
        email: emailRequired ? t("This field is required") : null,
        username: usernameRequired ? t("This field is required") : null,
      });
      return;
    }
    const body = {
      email: email,
      username: username,
      isWeb: true,
    };
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      };
      setPending(true);
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + API_LOGIN_ATTEMPT_ROUTE,
        options
      );
      const data = await response.json();
      if (data.success) {
        if (data.data.is_logged_in) {
          handleLogin(data.data);
          setPending(false);
          return;
        }
        dispatch(
          setMessage(
            t("The activation link has been sent to your email."),
            "success"
          )
        );
        setPending(false);
      } else {
        if (data.errors.length > 0) {
          if (data.errors[0].type === "email") {
            setError({
              email: data.errors[0].text,
              username: null,
            });
          } else if (data.errors[0].type === "username") {
            setError({
              username: data.errors[0].text,
              email: null,
            });
          } else {
            dispatch(setMessage(data.errors[0].text, "error"));
          }
        } else {
          dispatch(setMessage("Unknown server error.", "error"));
        }
        setPending(false);
      }
    } catch (error) {
      //dispatch(setMessage(error, "error"));
      console.log(error);
      setPending(false);
    }
  };

  return (
    <>
      <Box
        className="flex column center fullscreen"
        sx={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
        }}
      >
        <Box
          className="flex column"
          sx={{ gap: { xs: "33px", sm: "46px", md: "63px" } }}
        >
          <Box
            className="flex column"
            sx={{
              width: "100%",
              gap: "13px",
              maxWidth: { xs: "unset", sm: "223px" },
              alignItems: { xs: "center", sm: "unset" },
              position: "relative",
              zIndex: 2,
            }}
          >
            <Logo />
            <Typography
              variant="h1"
              className="typography bold"
              sx={{
                padding: { xs: "0 30px", sm: "unset" },
                textAlign: {
                  xs: "center",
                  sm: "unset",
                  md: "unset",
                  lg: "unset",
                },
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.text.dark
                    : theme.palette.text.light,
              }}
            >
              {t("Stream anything to everywhere!")}
            </Typography>
          </Box>
          <Box className="flex column gap-6">
            {isLoading ? (
              <Grow in={GrowEffect} timeout={300}>
                <Box>
                  <Card
                    className="margin"
                    sx={{ margin: { xs: "0 20px", sm: "0", md: "0", lg: "0" } }}
                  >
                    <Loader />
                  </Card>
                </Box>
              </Grow>
            ) : (
              <Card
                grow
                className="margin"
                sx={{
                  margin: { xs: "0 20px", sm: "0", md: "0", lg: "0" },
                  minWidth: { xs: "350px", sm: "454px" },
                }}
              >
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.light
                        : theme.palette.text.dark,
                    marginBottom: "7px",
                  }}
                  className="typography bold lg"
                >
                  {t("Sign in")}
                </Typography>

                <Box className="flex column" sx={{ gap: "15px" }}>
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.text.light
                          : theme.palette.text.dark,
                      maxWidth: "259px",
                      mb: 2,
                    }}
                    className="typography"
                  >
                    {t("You will receive a sign in link to your email.")}
                  </Typography>

                  <TextFieldUI
                    placeholder={t("E-mail")}
                    icon={<AlternateEmailIcon color="primary" />}
                    value={email}
                    error={error.email}
                    type="email"
                    onChange={handleEmail}
                    onKeyPress={handleKeyPress}
                  />

                  {/* <TextFieldUI
              placeholder={t('Username')}
              icon={<PersonOutlineOutlinedIcon color='primary' />}
              value={username}
              type="text"
              error={error.username}
              onChange={handleUsername}
              onKeyPress={handleKeyPress}
            /> */}

                  <CheckboxUI
                    sx={{ mb: 4.5 }}
                    title={t("Keep me logged in for a week")}
                    value={remember}
                    onChange={(e) => setRemember(e.target.value)}
                  />
                </Box>

                <Button
                  onClick={handleSend}
                  disabled={pending}
                  variant="contained"
                  className="btn btn-primary long"
                  color="primary"
                >
                  {t("Get link")}
                </Button>
              </Card>
            )}
            <Box
              className={"flex items-center" + (isMobile ? " column" : "")}
              sx={{
                justifyContent: !isMobile && "space-evenly",
                position: !isMobile && "fixed",
                bottom: 20,
                left: 0,
                right: 0,
                px: isMobile && 4,
              }}
            >
              <Typography
                className="typography"
                sx={{ color: theme.palette.text.dark }}
              >
                © 2023 Kewb Labs OÜ. All Rights Reserved
              </Typography>
              <Box
                className="flex items-center gap-10"
                sx={{
                  justifyContent: isMobile && "center",
                  mt: isMobile && 2,
                  pb: isMobile && 2,
                }}
              >
                <Link to="/terms">
                  <Typography
                    className="typography underline"
                    sx={{ color: theme.palette.text.dark }}
                  >
                    Terms of Service
                  </Typography>
                </Link>
                <Link to="/privacy">
                  <Typography
                    className="typography underline"
                    sx={{ color: theme.palette.text.dark }}
                  >
                    Privacy Policy
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
