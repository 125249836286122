import { palette } from "./theme";

export const lightTheme = {
  palette: {
    ...palette,
    mode: "light",
    background: {
      default: "#1E0640",
    },
  },
  components: {
    // Name of the component
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "0.84vw",
        },
      },
    },
  },
};
