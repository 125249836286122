import React from "react";
import Card from "../Card/Card";
import IconButtonUI from "../UI/IconButtonUI";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";

import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import NoPhotographyOutlinedIcon from "@mui/icons-material/NoPhotographyOutlined";

import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";

import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";

import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";

import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { ReactComponent as PersonSeatIcon } from "../../assets/images/icons/svg/person-seat.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { Avatar, Button, Divider, Popover, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  cameraDisable,
  cameraEnable,
  chatDisable,
  chatEnable,
  micDisable,
  micEnable,
} from "../../redux/actions/toolbarActions";
import AvSettings from "../AvSettings/AvSettings";
import DropFile from "../DropFile/DropFile";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Logo.svg";
import LogoDark from "../../assets/images/Logo-dark.svg";
import Chat from "../Chat/Chat";
import {
  SessionDelete,
  endSession,
  removeAwaiters,
} from "../../redux/actions/sessionActions";
import { isMobile } from "react-device-detect";
import Awaiters from "../Awaiters/Awaiters";
export default function Toolbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cameraEnabled = useSelector((state) => state.cameraEnabled);
  const micEnabled = useSelector((state) => state.micEnabled);
  const awaiters = useSelector((state) => state.awaiters);
  const chatEnabled = useSelector((state) => state.chatEnabled);
  const theme = useTheme();
  const [openAVsettings, setOpenAVsettings] = React.useState(false);
  const [openDropFile, setOpenDropFile] = React.useState(false);
  const [newMessages, setNewMessages] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorElAwaiters, setAnchorElAwaiters] = React.useState(null);
  const awaitersOpen = Boolean(anchorElAwaiters);
  const awaitersId = open ? "awaiters-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenWaitings = (event) => {
    setAnchorElAwaiters(event.currentTarget);
  };
  const handleCloseAwaiters = () => {
    setAnchorElAwaiters(null);
  };

  const handleCam = () => {
    if (cameraEnabled) dispatch(cameraDisable());
    else dispatch(cameraEnable());
  };
  const handleMic = () => {
    if (micEnabled) dispatch(micDisable());
    else dispatch(micEnable());
  };
  const handleChat = () => {
    if (chatEnabled) dispatch(chatDisable());
    else dispatch(chatEnable());
  };
  const handleShareScreen = () => {
    console.log(1);
  };
  const handleAVSettings = () => {
    setOpenAVsettings(!openAVsettings);
  };
  const handleDropFile = () => {
    setOpenDropFile(!openDropFile);
  };
  const stream_session = useSelector((state) => state.stream_session);
  const messages = useSelector((state) => state.messages);

  React.useEffect(() => {
    if (messages.length > 0) {
      setNewMessages(messages.filter((obj) => obj.seen === false).length);
    }
  }, [messages]);

  return (
    <Card
      className="toolbar"
      sx={{
        padding: "14px 44px",
        flexDirection: "row",
        gap: isMobile ? "25px" : "2.4vw",
        // minWidth: '710px',
        width: "fit-content",
        bottom: "-17%",
        left: "0",
        right: "0",
        margin: "0 auto",
      }}
    >
      {stream_session?.current_participant?.status === "accepted" &&
        !stream_session?.current_participant?.is_host && (
          <Link to="/" style={{ display: "flex", alignItems: "center" }}>
            {/* LOGO */}
            <Avatar
              sx={{
                display: { xs: "none", md: "flex" },
                mr: 0.5,
                width: "107px",
                height: "29px",
              }}
              variant="square"
              src={Logo}
            />
          </Link>
        )}

      <IconButtonUI
        icon={
          !cameraEnabled ? (
            <NoPhotographyOutlinedIcon
              sx={{
                color: theme.palette.text.dark,
              }}
            />
          ) : (
            <CameraAltOutlinedIcon
              sx={{
                color: theme.palette.text.dark,
              }}
            />
          )
        }
        description={!cameraEnabled ? t("Camera Off") : t("Camera On")}
        onClick={handleCam}
      />
      <IconButtonUI
        icon={
          !micEnabled ? (
            <MicOffOutlinedIcon
              sx={{
                color: theme.palette.text.dark,
              }}
            />
          ) : (
            <MicNoneOutlinedIcon
              sx={{
                color: theme.palette.text.dark,
              }}
            />
          )
        }
        description={!micEnabled ? t("Mic Off") : t("Mic On")}
        onClick={handleMic}
      />

      {stream_session?.current_participant?.status === "accepted" && (
        <>
          <Divider
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.divider.light
                  : theme.palette.divider.dark,
              mb: isMobile && "-10px",
              mt: isMobile && "0",
            }}
            orientation="vertical"
            variant="middle"
            flexItem
          />

          {/* {stream_session?.current_participant?.status === "accepted" &&
            stream_session?.stream_session?.is_active &&
            stream_session?.current_participant?.is_host &&
            isMobile && (
              <>
                <IconButtonUI
                  icon={
                    <PersonSeatIcon style={{ fill: theme.palette.text.dark }} />
                  }
                  badge={awaiters?.length > 0 ? awaiters.length : null}
                  description={t("Waitings")}
                  onClick={handleOpenWaitings}
                />
                <Popover
                  id={awaitersId}
                  open={awaitersOpen}
                  anchorEl={anchorElAwaiters}
                  onClose={handleCloseAwaiters}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Awaiters
                    minHeight
                    minWidth
                    handleCloseIn={handleCloseAwaiters}
                  />
                </Popover>
              </>
            )} */}

          <IconButtonUI
            icon={
              <UploadFileOutlinedIcon
                sx={{
                  color: theme.palette.text.dark,
                }}
              />
            }
            description={t("Drop File")}
            onClick={handleDropFile}
          />

          <DropFile open={openDropFile} setOpen={setOpenDropFile} />

          {/* <IconButtonUI
            icon={
              <ScreenShareOutlinedIcon
                sx={{
                  color: theme.palette.text.dark,
                }}
              />
            }
            description={t("Share Screen")}
            onClick={handleShareScreen}
          /> */}

          <IconButtonUI
            icon={
              <ForumOutlinedIcon
                sx={{
                  color: theme.palette.text.dark,
                }}
              />
            }
            description={t("Chat")}
            badge={newMessages > 0 ? newMessages : null}
            onClick={
              stream_session?.stream_session?.is_active &&
              (!stream_session?.current_participant?.is_host || isMobile)
                ? handleClick
                : handleChat
            }
          />
        </>
      )}
      {stream_session?.current_participant?.status === "accepted" &&
        stream_session?.stream_session?.is_active &&
        (!stream_session?.current_participant?.is_host || isMobile) && (
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Chat
              minHeight
              minWidth
              handleClose={isMobile ? handleClose : null}
            />
          </Popover>
        )}
      {!isMobile &&
        stream_session?.current_participant?.status === "accepted" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              className="btn short"
              color="primary"
              variant="contained"
              onClick={() => {
                if (stream_session?.current_participant?.is_host) {
                  dispatch(endSession(stream_session));
                } else {
                  dispatch(removeAwaiters());
                  dispatch(SessionDelete());
                  window.location.reload();
                }
              }}
              sx={{
                boxShadow: "unset !important",
                borderRadius: "8px",
                height: "2.4vw !important",
                fontSizt: "1.6vw",
                position: "relative",
                top: "5px",
              }}
            >
              {stream_session?.current_participant?.is_host
                ? "Stop Session"
                : "Leave"}
            </Button>
          </div>
        )}

      {/* <Divider sx={{ color: theme.palette.mode === 'light' ? theme.palette.divider.light : theme.palette.divider.dark }} orientation="vertical" variant="middle" flexItem />

            <IconButtonUI
                icon={<TuneOutlinedIcon sx={{ color: theme.palette.mode === 'light' ? theme.palette.text.light : theme.palette.text.dark }} />}
                description={t("AV Settings")}
                onClick={handleAVSettings}
            />

            <AvSettings open={openAVsettings} setOpen={setOpenAVsettings} /> */}
    </Card>
  );
}
