import {
  API,
  API_CHAT_TOKEN_REFRESH_ROUTE,
  CORE_API_ANONYMOUS_WHX_MAIN_USER_LOGIN,
  CORE_API_ANONYMOUS_WHX_MAIN_USER_PASSWORD,
  CORE_API_CHAT_ROUTE,
  CORE_API_GET_MESSAGES_ROUTE,
  CORE_API_LOGIN_ROUTE,
  CORE_API_SEND_MESSAGE_ROUTE,
} from "../../utils/consts";
import { removeLoadings, setLoadings } from "../rootReducer";
import {
  ADD_MESSAGE_TO_MESSAGES,
  MARK_MESSAGE_AS_SEEN,
  MESSAGES,
  REMOVE_MESSAGE,
} from "../types";
import DateHelper from "../../utils/DateHelper";

const allMessages = (data) => {
  return {
    type: MESSAGES,
    payload: { data },
  };
};

const addMessage = (data) => {
  return {
    type: ADD_MESSAGE_TO_MESSAGES,
    payload: { data },
  };
};

export const markMessage = (data) => {
  return {
    type: MARK_MESSAGE_AS_SEEN,
    payload: { data },
  };
};

export const removeMessage2 = (data) => {
  return {
    type: REMOVE_MESSAGE,
    payload: { data },
  };
};

const setMessagesToState = (data) => {
  return {
    type: MESSAGES,
    payload: { data },
  };
};

export const sendMessage = (messg, chatId) => async (dispatch) => {
  try {
    let token = localStorage.getItem("access_token");
    //const request
    await fetch(
      process.env.REACT_APP_BACKEND_URL +
        API +
        "chat/" +
        CORE_API_SEND_MESSAGE_ROUTE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        //credentials: 'include',
        body: JSON.stringify({
          message: messg,
          chatId: chatId,
        }),
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export function getToken() {
  const token = localStorage.getItem("core_api_token");
  const access_token = localStorage.getItem("access_token");
  if (!access_token) {
    return null;
  }
  if (!token) {
    return fetch(
      process.env.REACT_APP_BACKEND_URL + API_CHAT_TOKEN_REFRESH_ROUTE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const token = data.data.token;
        localStorage.setItem("core_api_token", token);
        return token;
      });
  }
  return Promise.resolve(token);
}

export function formatMessage(message) {
  return {
    id: parseInt(message.id),
    message: message.message,
    type: message.messageType,
    banned: message.banned === 0 ? false : true,
    data: {
      avatar: message.data.avatar ? message.data.avatar : "",
      name: message.data.name,
      userId: message.data.userId,
      time:
        DateHelper.UnixToLocal(message.createdAt).getHours() +
        ":" +
        (DateHelper.UnixToLocal(message.createdAt).getMinutes() < 10
          ? "0"
          : "") +
        DateHelper.UnixToLocal(message.createdAt).getMinutes(),
      role: message.data.role,
      is_pool: message.data.is_pool,
      pool_id: message.data.itemId,
      is_premium: message.data.is_premium,
      event: message.data.event ? message.data.event : "",
      event_type: message.data.event_type ? message.data.event_type : "",
      url: message.data.url ? message.data.url : "",
      actionType: message.data.actionType ? message.data.actionType : null,
    },
  };
}

export const getMessages = (chatId, token, limit) => async (dispatch) => {
  let retryCount = 0;

  function handleFetchError(error) {
    console.error("An error occurred while fetching messages:", error);
    dispatch(removeLoadings("messages"));
  }

  const fetchMessages = async (token) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_SOCKET_API_URL +
          CORE_API_CHAT_ROUTE +
          chatId +
          CORE_API_GET_MESSAGES_ROUTE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            limit: limit,
            onlyOwn: false,
          }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        console.error(responseData.message);
        return false;
      }

      return responseData.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  try {
    dispatch(setLoadings("messages"));

    if (!token) {
      token = getToken();
    }

    let messages = [];

    while (retryCount < 6) {
      try {
        const data = await fetchMessages(token);

        const messagesArray = data.reverse();

        messagesArray.forEach((message) => {
          messages.push(formatMessage(message));
        });

        dispatch(allMessages(index(messages)));
        dispatch(removeLoadings("messages"));
        return;
      } catch (error) {
        retryCount++;

        if (retryCount >= 6) {
          handleFetchError(error);
          return;
        }
      }
    }
  } catch (error) {
    handleFetchError(error);
  }
};

function index(messages) {
  if (!messages) return;
  let newMessages = [];
  // eslint-disable-next-line array-callback-return
  messages.map((message, index) => {
    if (message["isPrevious"] !== null) {
      let lastMessage = messages[index - 1];
      message.isPrevious =
        lastMessage && message.data.userId === lastMessage.data.userId
          ? true
          : false;
      if (message.isPrevious) {
        message.previousSameType =
          lastMessage && message.type === lastMessage.type ? true : false;
        if (message.previousSameType) {
          message.previousId = lastMessage.previousId
            ? lastMessage.previousId
            : lastMessage.id;
        }

        if (!lastMessage.previousSameType) {
          message.previousId = lastMessage.id;
        }
      }
    }
    newMessages.push(message);
  });
  return newMessages;
}

export const appendMessageToState = (messg) => async (dispatch) => {
  dispatch(addMessage(messg));
};

export const setMessages = (messages) => async (dispatch) => {
  dispatch(setMessagesToState(messages));
};
