import { Button, IconButton, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Webcam from "react-webcam";
import { useTranslation } from "react-i18next";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { isMobile } from "react-device-detect";
import placeholder from "../../assets/images/male.jpeg";

export default function WebCamera({ imageUrl, setImageUrl, handleClose }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isShowVideo, setIsShowVideo] = React.useState(true);
  const [previews, setPreviews] = React.useState([{ url: placeholder }]);

  const videoElement = React.useRef(null);
  const scrollContainer = React.useRef(null);

  const videoConstraints = {
    width: 520,
    height: 520,
    facingMode: "user",
  };

  const takeScreenShot = () => {
    setPreviews([
      ...previews,
      {
        url: videoElement.current.getScreenshot({ width: 1080, height: 1080 }),
      },
    ]);
    setTimeout(() => {
      scrollContainer.current.scrollTo({
        left: 10000,
        behavior: "smooth",
      });
    }, 1);
  };

  const deleteScreenShot = (i) => {
    if (imageUrl === previews[i].url) {
      setImageUrl(null);
    }
    setPreviews((current) =>
      current.filter((preview) => {
        return preview !== previews[i];
      })
    );
  };

  const startCam = () => {
    setIsShowVideo(true);
  };

  const stopCam = () => {
    let stream = videoElement.current.stream;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setIsShowVideo(false);
  };

  const handleSelect = (i) => {
    setImageUrl(previews[i].url);
    handleClose();
  };

  return (
    <Box
      className="flex column items-center justify-evenly h-full"
      sx={{ gap: "20px" }}
    >
      <Box className="camView">
        {isShowVideo && (
          <Webcam
            audio={false}
            ref={videoElement}
            videoConstraints={videoConstraints}
            className="rounded"
          />
        )}
      </Box>
      <Box
        className="flex center scroll-vertical-none scroll"
        ref={scrollContainer}
        sx={{
          overflowX: "scroll",
          height: "145px",
          width: "100%",
          gap: "15px",
          flexWrap: "nowrap",
          "::-webkit-scrollbar": {
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.paper.light
                : theme.palette.paper.dark,
          },
        }}
      >
        {previews?.length > 0 &&
          previews.map((preview, i) => {
            return (
              <Box
                key={"bx" + i}
                className="relative rounded-full hover cursor"
                sx={{
                  flex: "0 0 auto",
                  width: "130px",
                  bottom: "5px",
                  ":hover": {
                    ".MuiIconButton-root": {
                      opacity: "1",
                    },
                  },
                }}
              >
                <img
                  src={preview.url}
                  alt="preview"
                  className="rounded-full"
                  onClick={() => handleSelect(i)}
                  style={{
                    width: "130px",
                    height: "130px",
                  }}
                />
                <IconButton
                  color="primary"
                  onClick={() => deleteScreenShot(i)}
                  aria-label={t("Delete")}
                  title={t("Delete")}
                  sx={{
                    opacity: isMobile ? "1" : "0",
                    position: "absolute",
                    top: "0",
                    transition: "all 0.2s",
                    background:
                      theme.palette.mode === "light"
                        ? theme.palette.paper.light
                        : theme.palette.paper.dark,
                    ":hover": {
                      background:
                        theme.palette.mode === "light"
                          ? theme.palette.paper.light
                          : theme.palette.paper.dark,
                      filter: "brightness(1.15)",
                    },
                  }}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </Box>
            );
          })}
      </Box>
      <Button
        onClick={takeScreenShot}
        className="btn rounded-full"
        variant="contained"
        color="primary"
      >
        {t("Take a photo")}
      </Button>
    </Box>
  );
}
