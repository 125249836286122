// AxiosNavigation.js
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../redux/rootReducer";
import { SessionDelete, removeAwaiters } from "../redux/actions/sessionActions";
import { userDelete } from "../redux/actions/userAction";

export function useAxiosNavigation() {
  // Use useRef to prevent a re-render in the useEffect.
  // A ref, cannot be used as a useEffect dependency, hence,
  // your linters shouldn't complain about missing dependencies.
  const dispatchRef = useRef(useDispatch());
  const navRef = useRef(useNavigate());
  const session = useSelector((state) => state.session);
  const sessionRef = useRef(session);

  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  useEffect(() => {
    const intercetpor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.data?.type === "participant_banned") {
          dispatchRef.current(SessionDelete());
          dispatchRef.current(
            setMessage(error.response?.data?.errors[0]?.text, "error")
          );
          navRef.current("/");
        } else if (error.response?.data?.type === "session_not_exist") {
          dispatchRef.current(removeAwaiters());
          dispatchRef.current(SessionDelete());
          dispatchRef.current(
            setMessage(error.response.data.errors[0]?.text, "error")
          );
          if (
            sessionRef.current.user?.is_guest ||
            sessionRef.current.user?.user?.is_guest
          ) {
            dispatchRef.current(userDelete());
            navRef.current("/signup");
            return Promise.reject(error);
          }
          navRef.current("/");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(intercetpor);
    };
  }, []);
}

export default function AxiosNavigation() {
  useAxiosNavigation();
  return <></>;
}
