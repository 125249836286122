export const initialState = {
  session: [],
  loadings: [],
  error: {},
  errors: [],
  messages: [],
  message: {},
  modal: {},
  stream_session: {},
  participants: [],
  awaiters: [],
  tab: "",
  chatEnabled: false,
  cameraEnabled: true,
  micEnabled: true,
};
