export const translations = {
    en: {
        translation: {
            "You are about to enter": "You are about to enter"
        }
    },
    de: {
        translation: {
            "Promote": "促進",
        }
    },
    fr: {
        translation: {
            "Promote": "홍보하다",
        }
    },
    se: {
        translation: {
            "Promote": "推动",
        }
    },
    es: {
        translation: {
            "Promote": "推动",
        }
    },
    it: {
        translation: {
            "Promote": "推动",
        }
    },
    ru: {
        translation: {
            "Promote": "推动",
        }
    },
    fi: {
        translation: {
            "Promote": "推动",
        }
    }
};