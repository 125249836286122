import React, { createContext } from "react";
import { useSelector } from "react-redux";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

const StreamContext = createContext();

let streamsList;
let roomTimerId = -1;
let dominantSpeakerFinderId = null;
var publishStreamId;

const StreamContextProvider = ({ children }) => {
  const [initialized, setInitialized] = React.useState(false);

  const stream_session = useSelector((state) => state.stream_session);

  const micEnabled = useSelector((state) => state.micEnabled);
  const cameraEnabled = useSelector((state) => state.cameraEnabled);

  const [webRTCAdaptor, setWebRTCAdaptor] = React.useState(null);

  let roomID = stream_session?.stream_session?.hash;

  let streamID =
    stream_session?.stream_session?.hash +
    stream_session?.current_participant?.hash;

  let websocket_url = stream_session?.stream_session?.websocket_url
    ? stream_session.stream_session.websocket_url
    : process.env.REACT_APP_WEBRTC_SOCKET_URL;

  function createRemoteVideo(streamId) {
    var player = document.createElement("div");
    player.className = "player col-sm-3";
    player.id = "player" + streamId;
    player.innerHTML =
      '<video class="RemoteVideo" id="remoteVideo' +
      streamId +
      '"autoplay playsinline></video>';
    // player.style.cssText = "height:100%;width:100%;border-radius:10px";
    document.getElementById("players").appendChild(player);
  }

  function leaveRoom() {
    webRTCAdaptor.leaveFromRoom(streamID);

    for (var node in document.getElementById("players").childNodes) {
      if (node.tagName == "DIV" && node.id != "localVideo") {
        document.getElementById("players").removeChild(node);
      }
    }
  }

  function playVideo(obj) {
    var room = streamID;
    console.log(
      "new stream available with id: " + obj.streamId + "on the room:" + room
    );

    console.log(stream_session);
    var video = document.getElementById("remoteVideo" + obj.streamId);

    if (video == null) {
      createRemoteVideo(obj.streamId);
      video = document.getElementById("remoteVideo" + obj.streamId);
    }

    video.srcObject = obj.stream;

    webRTCAdaptor?.enableAudioLevel(obj.stream, obj.streamId);
  }

  function removeRemoteVideo(streamId) {
    var video = document.getElementById("remoteVideo" + streamId);
    console.log("removeRemoteVideo");
    console.log(video);
    if (video != null) {
      var player = document.getElementById("player" + streamId);
      video.srcObject = null;
      console.log(player);
      document.getElementById("players").removeChild(player);
    }
    webRTCAdaptor?.stop(streamId);
  }
  function checkVideoTrackStatus(streamsList) {
    streamsList.forEach(function (item) {
      var video = document.getElementById("remoteVideo" + item);
      if (video != null && !video.srcObject.active) {
        removeRemoteVideo(item);
        playVideo(item);
      }
    });
  }

  // function enableVirtualBackground(event) {
  //   var effectName;
  //   if (event.target.value == "blur") {
  //     effectName = VideoEffect.BLUR_BACKGROUND;
  //   } else if (event.target.value == "noeffect") {
  //     effectName = VideoEffect.NO_EFFECT;
  //   } else {
  //     effectName = VideoEffect.VIRTUAL_BACKGROUND;
  //     VideoEffect.setBackgroundImage(this);
  //   }
  //   VideoEffect.enableEffect(effectName)
  //     .then(() => {
  //       console.log("Effect: " + effectName + " is enabled");
  //     })
  //     .catch((err) => {
  //       console.error(
  //         "Effect: " + effectName + " is not enabled. Error is " + err
  //       );
  //     });
  // }

  function turnOffLocalCamera() {
    console.log("turn off?");
    webRTCAdaptor?.turnOffLocalCamera(publishStreamId);
  }

  function turnOnLocalCamera() {
    webRTCAdaptor?.turnOnLocalCamera(publishStreamId);
  }

  function muteLocalMic() {
    webRTCAdaptor?.muteLocalMic();
    webRTCAdaptor?.enableAudioLevelWhenMuted(publishStreamId);
  }

  function unmuteLocalMic() {
    webRTCAdaptor?.unmuteLocalMic();
    webRTCAdaptor?.disableAudioLevelWhenMuted(publishStreamId);
  }

  // React.useEffect(() => {
  //   if (webRTCAdaptor) {
  //     if (stream_session?.current_participant?.hash) {
  //     } else {
  //       console.log('stop')
  //       webRTCAdaptor.stop();
  //     }
  //     // removeRemoteVideo(stream_session?.current_participant?.hash);
  //     // clearInterval(roomTimerId);
  //   }
  // }, [stream_session]);

  React.useEffect(() => {
    var pc_config = {
      iceServers: [
        {
          urls: "stun:stun1.l.google.com:19302",
        },
      ],
    };

    var sdpConstraints = {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: true,
    };
    var mediaConstraints = {
      video: true,
      audio: true,
    };

    var Adaptor = new WebRTCAdaptor({
      websocket_url: websocket_url,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pc_config,
      sdp_constraints: sdpConstraints,
      localVideoId: "localVideo", // <video id="id-of-video-element" autoplay muted></video>
      bandwidth: "unlimited", // default is 900 kbps, string can be 'unlimited'
      dataChannelEnabled: true, // enable or disable data channel
      callback: function (info, obj) {
        if (info == "initialized") {
          setInitialized(true);
          console.log("initialized");
        } else if (info == "joinedTheRoom") {
          //play_started
          console.log("joinedTheRoom");
          if (stream_session?.current_participant?.status === "accepted")
            Adaptor.publish(streamID);
          publishStreamId = streamID;
          if (
            obj.streams != null &&
            stream_session?.current_participant?.status === "accepted"
          ) {
            obj.streams.forEach(function (item) {
              Adaptor.play(item);
            });
            streamsList = obj.streams;
          }
          if (stream_session?.current_participant?.status === "accepted")
            roomTimerId = setInterval(() => {
              Adaptor.getRoomInfo(
                stream_session?.stream_session?.hash,
                streamID
              );
            }, 5000);
        } else if (info == "newStreamAvailable") {
          playVideo(obj);
          // if (dominantSpeakerFinderId == null) {
          //   dominantSpeakerFinderId = setInterval(() => {
          //     Adaptor?.getSoundLevelList(streamsList);
          //   }, 200);
          // }
        } else if (info == "play_started") {
          //play_started
        } else if (info == "play_finished") {
          // play finishedthe stream
          removeRemoteVideo(obj.streamId);
        } else if (info == "leavedFromRoom") {
          var room = obj.ATTR_ROOM_NAME;
          console.debug("leaved from the room:" + room);
          if (roomTimerId != null) {
            clearInterval(roomTimerId);
            clearInterval(dominantSpeakerFinderId);
          }
          dominantSpeakerFinderId = null;

          if (streamsList != null) {
            streamsList.forEach(function (item) {
              removeRemoteVideo(item);
            });
          }
          // we need to reset streams list
          streamsList = new Array();
        } else if (info == "roomInformation") {
          //Checks if any new stream has added, if yes, plays.
          for (let str of obj.streams) {
            if (!streamsList.includes(str)) {
              Adaptor.play(str);
            }
          }
          // Checks if any stream has been removed, if yes, removes the view and stops webrtc connection.
          for (let str of streamsList) {
            if (!obj.streams.includes(str)) {
              removeRemoteVideo(str);
            }
          }
          //Lastly updates the current streamlist with the fetched one.
          streamsList = obj.streams;

          //Check video tracks active/inactive status
          checkVideoTrackStatus(streamsList);
        }
      }, // check info callbacks bellow
      callbackError: function (error) {
        //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        console.log("error callback: " + JSON.stringify(error));
      },
    });
    setWebRTCAdaptor(Adaptor);

    return () => {
      Adaptor.closeWebSocket(streamID);
      Adaptor = null;
      clearInterval(roomTimerId);
      setWebRTCAdaptor(null);
    };
  }, [stream_session]);

  React.useEffect(() => {
    if (webRTCAdaptor) {
      if (micEnabled) {
        unmuteLocalMic();
      } else {
        muteLocalMic();
      }
    }
  }, [micEnabled, webRTCAdaptor]);

  React.useEffect(() => {
    if (webRTCAdaptor) {
      if (cameraEnabled) {
        turnOnLocalCamera();
      } else {
        turnOffLocalCamera();
      }
    }
  }, [cameraEnabled, webRTCAdaptor]);

  React.useEffect(() => {
    setTimeout(() => {
      if (initialized && streamID) {
        webRTCAdaptor.play(streamID);
      }
    }, 1);
  }, [initialized]);

  React.useEffect(() => {
    setTimeout(() => {
      if (streamID && webRTCAdaptor && initialized) {
        webRTCAdaptor.joinRoom(roomID, streamID);
      }
      setTimeout(() => {
        if (cameraEnabled) {
          turnOnLocalCamera();
        } else {
          turnOffLocalCamera();
        }
        if (micEnabled) {
          unmuteLocalMic();
        } else {
          muteLocalMic();
        }
      }, 50);
    }, 450);
  }, [stream_session, webRTCAdaptor, initialized]);

  return (
    <StreamContext.Provider
      value={{
        webRTCAdaptor,
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

export { StreamContextProvider, StreamContext };
