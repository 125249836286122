import React from "react";
import Card from "../Card/Card";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useTranslation } from "react-i18next";
import { Alert, Box, Fade, useTheme } from "@mui/material";
import InputField from "./InputField";
import Message from "../Message/Message";
import { useSelector } from "react-redux";
import { CloseRounded } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

export default function Chat({ minHeight, minWidth, handleClose }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const messages = useSelector((state) => state.messages);
  const [chatIsTimedOut, setChatIsTimedOut] = React.useState(false);
  return (
    <Card
      title={t("Chat")}
      icon={<ForumOutlinedIcon color="primary" />}
      // altIcon={<SettingsOutlinedIcon color="primary" />}
      altIconOnClick={isMobile ? handleClose : null}
      altIcon={isMobile ? <CloseRounded /> : null}
      sx={{
        height: "100%",
        minHeight: minHeight && "617px",
        minWidth: minWidth && { xs: "92vw", sm: "451px" },
      }}
    >
      <Box
        className="flex column relative"
        sx={{
          background:
            theme.palette.mode === "light"
              ? theme.palette.divider.light
              : theme.palette.divider.dark,
          padding: "10px 8px",
          gap: "11px",
          height: "100%",
          minHeight: minHeight && "518px",
          maxHeight: { xs: "77vh" },
          overflowY: { xs: "scroll" },
        }}
      >
        {messages?.length > 0 &&
          messages.map((message, i) => {
            return <Message message={message} key={"m" + i} />;
          })}
      </Box>
      {chatIsTimedOut && (
        <Fade in={true}>
          <Box
            sx={{
              position: "absolute",
              bottom: "63px",
              left: "5px",
              right: "5px",
            }}
          >
            <Alert severity="warning">
              You are sending messages too quickly.
            </Alert>
          </Box>
        </Fade>
      )}
      <InputField
        chatIsTimedOut={chatIsTimedOut}
        setChatIsTimedOut={setChatIsTimedOut}
      />
    </Card>
  );
}
